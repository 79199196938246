import React from "react";

interface ContextState {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: ContextState = {
  isOpen: false,
  setIsOpen: () => null,
};

export const CommandPaletteContext =
  React.createContext<ContextState>(initialState);

type CommandPaletteContextProviderProps = {
  children: React.ReactNode;
};

const CommandPaletteContextProvider = ({
  children,
}: CommandPaletteContextProviderProps) => {
  const [isOpen, setIsOpen] = React.useState(initialState.isOpen);

  return (
    <CommandPaletteContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </CommandPaletteContext.Provider>
  );
};

const useCommandPaletteIsOpen = (): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>
] => {
  const context = React.useContext(CommandPaletteContext);
  if (context === undefined) {
    throw new Error(
      "useCommandPaletteIsOpen must be used within a CommandPaletteProvider"
    );
  }
  return [context.isOpen, context.setIsOpen];
};

export { CommandPaletteContextProvider, useCommandPaletteIsOpen };
