import * as React from "react";

export interface PostalCodeFieldProps {
  postalCode?: number;
  onChange: (postalCode: number) => void;
}

const PostalCodeField = ({ postalCode, onChange }: PostalCodeFieldProps) => {
  return (
    <div className="space-y-1">
      <label
        htmlFor="postal-code"
        className="block text-sm font-medium text-gray-700"
      >
        Postal Code
      </label>
      <input
        id="postal-code"
        name="postal-code"
        type="number"
        autoComplete="postal-code"
        min={1}
        max={9999}
        value={postalCode}
        onChange={(e) => onChange(e.target.valueAsNumber)}
        required
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      />
    </div>
  );
};

export default PostalCodeField;
