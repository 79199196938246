import * as React from "react";
import { ApplicationType } from "../../core/enums";
import { ApplicationTypeSettingModel } from "../../core/models/setting";
import SwitchField from "../fields/SwitchField";
import TextareaField from "../fields/TextareaField";

export interface ApplicationSettingsFormProps {
  label: string;
  settings: ApplicationTypeSettingModel;
  applicationType: ApplicationType;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onFieldChanged: (fieldKey: string, fieldValue: any) => void;
}

const ApplicationSettingsForm = (props: ApplicationSettingsFormProps) => {
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isFormValid = event.currentTarget.checkValidity();
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="grid grid-cols-2 gap-6">
      <div className="col-span-2">
        <SwitchField
          label="Disable application type?"
          enabled={props.settings.disabled}
          onChange={(v) =>
            props.onFieldChanged(
              `applications.${props.applicationType}.disabled`,
              v
            )
          }
        />
      </div>
      <div className="col-span-2">
        <TextareaField
          label="Disabled message"
          name={props.settings.disabledMessage}
          required={false}
          onChange={(v) =>
            props.onFieldChanged(
              `applications.${props.applicationType}.disabledMessage`,
              v
            )
          }
        />
      </div>
      <div className="col-span-2 flex gap-8">
        <button
          type={props.type ?? "submit"}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {props.label}
        </button>
      </div>
    </form>
  );
};

export default ApplicationSettingsForm;
