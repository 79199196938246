import { Tab } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { FirebaseError } from "firebase/app";
import { DocumentData, DocumentReference } from "firebase/firestore";
import { navigate } from "gatsby";
import * as React from "react";
import { Id, toast, TypeOptions } from "react-toastify";
import BusinessLoanDetails from "../../../components/details/BusinessLoanDetails";
import CheckField from "../../../components/fields/CheckField";
import BusinessLoanDetailsForm from "../../../components/forms/BusinessLoanDetailsForm";
import BusinessLoanTypeForm from "../../../components/forms/BusinessLoanTypeForm";
import CreateLoanForm from "../../../components/forms/CreateLoanForm";
import UploadCard from "../../../components/shared/cards/UploadCard";
import {
  useAuthUser,
  useAuthUserDoc,
  useFirebaseService,
} from "../../../core/contexts/firebase";
import { useBusinessLoans } from "../../../core/contexts/firebase/business-loans";
import {
  ApplicationStatus,
  BusinessLoanType,
  UploadErrorCode,
} from "../../../core/enums";
import useBusinessLoanFormReducer, {
  BusinessLoanFormActionType,
} from "../../../core/hooks/useBusinessLoanFormReducer";
import useBusinessLoanSubmissionReducer, {
  BusinessLoanSubmissionActionType,
} from "../../../core/hooks/useBusinessLoanSubmissionReducer";
import useUploadFormReducer, {
  UploadFormActionType,
} from "../../../core/hooks/useUploadReducer";
import { BusinessLoanModel } from "../../../core/models/business-loan";
import { StatusModel } from "../../../core/models/status";
import { handleUpload, UploadError } from "../../../core/utils/uploader";

const steps = [
  { id: 1, name: "Loan type" },
  { id: 2, name: "Loan amount" },
  { id: 3, name: "Loan details" },
  { id: 4, name: "Upload documents" },
  { id: 5, name: "Review" },
];

const BusinessLoanApplicationPage = () => {
  const user = useAuthUser();
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [i1, i2, i3, getNewBusinessLoan] = useBusinessLoans();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [reviewed, setReviewed] = React.useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] =
    React.useState("");

  // Initialise state management for forms
  const [submitter, submitterDispatch] = useBusinessLoanSubmissionReducer();
  const [businessLoan, businessLoanDispatch] = useBusinessLoanFormReducer();
  const [companyRegDoc, companyRegDocDispatch] = useUploadFormReducer();
  const [appointmentLetterOrSla, appointmentLetterOrSlaDispatch] =
    useUploadFormReducer();
  const [
    appointmentLetterOrPurchaseOrder,
    appointmentLetterOrPurchaseOrderDispatch,
  ] = useUploadFormReducer();
  const [billOfQuantities, billOfQuantitiesDispatch] = useUploadFormReducer();
  const [projectImplementationPlan, projectImplementationPlanDispatch] =
    useUploadFormReducer();
  const [purchaseOrder, purchaseOrderDispatch] = useUploadFormReducer();
  const [supplierQuoteOrInvoice, supplierQuoteOrInvoiceDispatch] =
    useUploadFormReducer();
  const [invoice, invoiceDispatch] = useUploadFormReducer();
  const [paymentCertificate, paymentCertificateDispatch] =
    useUploadFormReducer();

  // Derived change state
  const hasBusinessLoanChanged = businessLoan.hasChanged;
  const hasFormChanged = hasBusinessLoanChanged;
  const isProjectFinance =
    businessLoan.currentData.type === BusinessLoanType.project_finance;
  const isPurchaseOrder =
    businessLoan.currentData.type === BusinessLoanType.purchase_order;
  const isInvoiceDiscounting =
    businessLoan.currentData.type === BusinessLoanType.invoice_discounting;

  // we need to keep a reference of the document reference to prevent the creation of
  // multiple documents. This is needed because the onSaveBusinessLoan function
  // can be called multiple times and the document reference is created in that function.
  const docRef = React.useRef<DocumentReference<DocumentData> | null>(null);

  React.useEffect(() => {
    if (user && firebaseService && docRef.current == null) {
      docRef.current = firebaseService.businessLoanDocRef(user.uid);
    }
  }, [user, firebaseService, docRef.current]);

  // we need to reset the percantages in various cases such as when a user enters or leaves the page
  // and also when an error occurs during file uploads.
  const resetPercents = () =>
    submitterDispatch({
      type: BusinessLoanSubmissionActionType.RESET_ALL_PERCENTS,
    });
  React.useEffect(() => {
    resetPercents();
    return resetPercents;
  }, []);

  // Handle field changes through state management mechanism
  const onSubmitterFieldChanged = (fieldKey: string, fieldValue: any) =>
    submitterDispatch({
      type: BusinessLoanSubmissionActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onBusinessLoanFieldChanged = (fieldKey: string, fieldValue: any) =>
    businessLoanDispatch({
      type: BusinessLoanFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onCompanyRegDocFieldChanged = (fieldKey: string, fieldValue: any) =>
    companyRegDocDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onAppointmentLetterOrSlaFieldChanged = (
    fieldKey: string,
    fieldValue: any
  ) =>
    appointmentLetterOrSlaDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onAppointmentLetterOrPurchaseOrderFieldChanged = (
    fieldKey: string,
    fieldValue: any
  ) =>
    appointmentLetterOrPurchaseOrderDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onBillOfQuantitiesFieldChanged = (fieldKey: string, fieldValue: any) =>
    billOfQuantitiesDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onProjectImplementationPlanFieldChanged = (
    fieldKey: string,
    fieldValue: any
  ) =>
    projectImplementationPlanDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onPurchaseOrderFieldChanged = (fieldKey: string, fieldValue: any) =>
    purchaseOrderDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onSupplierQuoteOrInvoiceFieldChanged = (
    fieldKey: string,
    fieldValue: any
  ) =>
    supplierQuoteOrInvoiceDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onInvoiceFieldChanged = (fieldKey: string, fieldValue: any) =>
    invoiceDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onPaymentCertificateFieldChanged = (
    fieldKey: string,
    fieldValue: any
  ) =>
    paymentCertificateDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });

  // function helper for company registration document upload
  const handleUploadCRD = (loanId: string, uid: string) => {
    const { file } = companyRegDoc.currentData;
    const path = `/files/users/${uid}/business_loans/${loanId}/company_registration_document`;
    return handleUpload(
      path,
      "Company Registration Document",
      file!,
      firebaseService!,
      (percent) => onSubmitterFieldChanged("percent.companyRegDoc", percent),
      (url) => onSubmitterFieldChanged("download.companyRegDocUrl", url)
    );
  };

  // function helper for appointment letter or sla upload
  const handleUploadALSLA = (loanId: string, uid: string) => {
    const { file } = appointmentLetterOrSla.currentData;
    const path = `/files/users/${uid}/business_loans/${loanId}/appointment_letter_or_sla`;
    return handleUpload(
      path,
      "Appointment Letter or SLA",
      file!,
      firebaseService!,
      (percent) =>
        onSubmitterFieldChanged("percent.appointmentLetterOrSla", percent),
      (url) =>
        onSubmitterFieldChanged("download.appointmentLetterOrSlaUrl", url)
    );
  };

  // function helper for appointment letter or purchase order upload
  const handleUploadALPO = (loanId: string, uid: string) => {
    const { file } = appointmentLetterOrPurchaseOrder.currentData;
    const path = `/files/users/${uid}/business_loans/${loanId}/appointment_letter_or_purchase_order`;
    return handleUpload(
      path,
      "Appointment Letter or Purchase Order",
      file!,
      firebaseService!,
      (percent) =>
        onSubmitterFieldChanged(
          "percent.appointmentLetterOrPurchaseOrder",
          percent
        ),
      (url) =>
        onSubmitterFieldChanged(
          "download.appointmentLetterOrPurchaseOrderUrl",
          url
        )
    );
  };

  // function helper for bill of quantities upload
  const handleUploadBOQ = (loanId: string, uid: string) => {
    const { file } = billOfQuantities.currentData;
    const path = `/files/users/${uid}/business_loans/${loanId}/bill_of_quantities`;
    return handleUpload(
      path,
      "Bill of Quantities",
      file!,
      firebaseService!,
      (percent) => onSubmitterFieldChanged("percent.billOfQuantities", percent),
      (url) => onSubmitterFieldChanged("download.billOfQuantitiesUrl", url)
    );
  };

  // function helper for project implementation plan upload
  const handleUploadPIP = (loanId: string, uid: string) => {
    const { file } = projectImplementationPlan.currentData;
    const path = `/files/users/${uid}/business_loans/${loanId}/project_implementation_plan`;
    return handleUpload(
      path,
      "Project Implementation Plan",
      file!,
      firebaseService!,
      (percent) =>
        onSubmitterFieldChanged("percent.projectImplementationPlan", percent),
      (url) =>
        onSubmitterFieldChanged("download.projectImplementationPlanUrl", url)
    );
  };

  // function helper for purchase order upload
  const handleUploadPO = (loanId: string, uid: string) => {
    const { file } = purchaseOrder.currentData;
    const path = `/files/users/${uid}/business_loans/${loanId}/purchase_order`;
    return handleUpload(
      path,
      "Purchase Order",
      file!,
      firebaseService!,
      (percent) => onSubmitterFieldChanged("percent.purchaseOrder", percent),
      (url) => onSubmitterFieldChanged("download.purchaseOrderUrl", url)
    );
  };

  // function helper for supplier quote or invoice upload
  const handleUploadSQI = (loanId: string, uid: string) => {
    const { file } = supplierQuoteOrInvoice.currentData;
    const path = `/files/users/${uid}/business_loans/${loanId}/supplier_quote_or_invoice`;
    return handleUpload(
      path,
      "Supplier Quote or Invoice",
      file!,
      firebaseService!,
      (percent) =>
        onSubmitterFieldChanged("percent.supplierQuoteOrInvoice", percent),
      (url) =>
        onSubmitterFieldChanged("download.supplierQuoteOrInvoiceUrl", url)
    );
  };

  // function helper for invoice upload
  const handleUploadI = (loanId: string, uid: string) => {
    const { file } = invoice.currentData;
    const path = `/files/users/${uid}/business_loans/${loanId}/invoice`;
    return handleUpload(
      path,
      "Submitted Invoice",
      file!,
      firebaseService!,
      (percent) => onSubmitterFieldChanged("percent.invoice", percent),
      (url) => onSubmitterFieldChanged("download.invoiceUrl", url)
    );
  };

  // function helper for payment certificate upload
  const handleUploadPC = (loanId: string, uid: string) => {
    const { file } = paymentCertificate.currentData;
    const path = `/files/users/${uid}/business_loans/${loanId}/payment_certificate`;
    return handleUpload(
      path,
      "Payment Certificat",
      file!,
      firebaseService!,
      (percent) =>
        onSubmitterFieldChanged("percent.paymentCertificate", percent),
      (url) => onSubmitterFieldChanged("download.paymentCertificateUrl", url)
    );
  };

  const handleUploadError = (error: UploadError) => {
    let message =
      "Something went wrong while uploading your claim document, please try again later";
    if (error.code === UploadErrorCode.failed_to_upload) {
      message = `Unable to upload "${error.name}". Please try using a different file. If the problem still persists, please contact support.`;
    } else if (error.code === UploadErrorCode.failed_to_get_download_url) {
      message = `Unable to get download url for  "${error.name}". Please try again.`;
    }
    makeToast(message, toast.TYPE.ERROR, false);
    toastId.current = null;
  };

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  const onSaveBusinessLoan = async () => {
    const ref = docRef.current;
    if (userDoc && firebaseService && ref) {
      makeToast("Uploading documents.", toast.TYPE.INFO, false);
      onSubmitterFieldChanged("isSubmitting", true);
      // Save the user's business loan information in a new business loan document
      const { uid } = userDoc;
      try {
        // Upload the files
        let download = { ...submitter.download };
        const [setDownloadUrlCRD] = handleUploadCRD(ref.id, uid);
        const uploads = [setDownloadUrlCRD];
        if (isProjectFinance) {
          const [setDownloadUrlALSA] = handleUploadALSLA(ref.id, uid);
          const [setDownloadUrlBOQ] = handleUploadBOQ(ref.id, uid);
          const [setDownloadUrlPIP] = handleUploadPIP(ref.id, uid);
          uploads.push(
            setDownloadUrlALSA,
            setDownloadUrlBOQ,
            setDownloadUrlPIP
          );

          const downloadUrls = await Promise.all(uploads);
          download = {
            companyRegDocUrl: downloadUrls[0],
            appointmentLetterOrSlaUrl: downloadUrls[1],
            billOfQuantitiesUrl: downloadUrls[2],
            projectImplementationPlanUrl: downloadUrls[3],
          };
        } else if (isPurchaseOrder) {
          const [setDownloadUrlPO] = handleUploadPO(ref.id, uid);
          const [setDownloadUrlSQI] = handleUploadSQI(ref.id, uid);
          uploads.push(setDownloadUrlPO, setDownloadUrlSQI);

          const downloadUrls = await Promise.all(uploads);
          download = {
            companyRegDocUrl: downloadUrls[0],
            purchaseOrderUrl: downloadUrls[1],
            supplierQuoteOrInvoiceUrl: downloadUrls[2],
          };
        } else if (isInvoiceDiscounting) {
          const [setDownloadUrlI] = handleUploadI(ref.id, uid);
          const [setDownloadUrlPC] = handleUploadPC(ref.id, uid);
          const [setDownloadUrlALPO] = handleUploadALPO(ref.id, uid);
          uploads.push(setDownloadUrlI, setDownloadUrlPC, setDownloadUrlALPO);

          const downloadUrls = await Promise.all(uploads);
          download = {
            companyRegDocUrl: downloadUrls[0],
            invoiceUrl: downloadUrls[1],
            paymentCertificateUrl: downloadUrls[2],
            appointmentLetterOrPurchaseOrderUrl: downloadUrls[3],
          };
        }

        makeToast(
          "Submitting business loan application.",
          toast.TYPE.INFO,
          false
        );
        // Save the business loan document
        const user = {
          uid: userDoc.uid,
          name: userDoc.name,
          surname: userDoc.surname,
          email: userDoc.email,
          cell: userDoc.cell,
        };
        const businessLoanModel: BusinessLoanModel = {
          ...businessLoan.currentData,
          id: ref.id,
          download,
          status: ApplicationStatus.created,
          user,
          createdAt: "",
          updatedAt: "",
        };
        const status: StatusModel = {
          status: ApplicationStatus.created,
          user,
          id: "",
          applicationId: ref.id,
          createdAt: "",
          updatedAt: "",
        };
        await Promise.all([
          firebaseService.setBusinessLoanDoc(uid, businessLoanModel, ref),
          firebaseService.setBusinessLoanStatusDoc(uid, ref.id, status),
        ]);
        getNewBusinessLoan();
        makeToast(
          "Your business loan application has been submitted successfully.",
          toast.TYPE.SUCCESS
        );
        onSubmitterFieldChanged("isSubmitting", false);
        onSubmitterFieldChanged("isSubmitted", true);
        navigate("/dashboard/applications?tab=business");
      } catch (error) {
        onSubmitterFieldChanged("isSubmitting", false);
        resetPercents();
        let message =
          "Something went wrong while submitting your business loan application, please try again later";
        if (error instanceof UploadError) {
          handleUploadError(error);
        } else if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to submit. ${error.message}`;
          }
          makeToast(message, toast.TYPE.ERROR, false);
          toastId.current = null;
        }
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  const getStepperContainerClassNames = (stepIndex: number) => {
    let classNames =
      "flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0";
    if (stepIndex > selectedIndex) {
      // Incomplete
      classNames = `${classNames} border-gray-200 group`;
    } else if (stepIndex === selectedIndex) {
      // Current
      classNames = `${classNames} border-indigo-600 hover:border-gray-300`;
    } else if (stepIndex < selectedIndex) {
      // Complete
      classNames = `${classNames} border-indigo-600 group hover:border-indigo-800`;
    }
    return classNames;
  };

  const getStepperIdClassNames = (stepIndex: number) => {
    let classNames = "text-sm font-medium";
    if (stepIndex > selectedIndex) {
      // Incomplete
      classNames = `${classNames} text-gray-500 group-hover:text-gray-700`;
    } else if (stepIndex === selectedIndex) {
      // Current
      classNames = `${classNames} text-indigo-600`;
    } else if (stepIndex < selectedIndex) {
      // Complete
      classNames = `${classNames} text-indigo-600 group hover:text-indigo-800`;
    }
    return classNames;
  };

  const goToPreviousTab = () => setSelectedIndex(selectedIndex - 1);
  const goToNextTab = () => setSelectedIndex(selectedIndex + 1);

  const getUploadDescription = () => {
    let description = "";
    if (isProjectFinance) {
      description =
        "Please upload a copy of your company registration document, appointment letter or SLA, bill of quantities and project implementation plan.";
    } else if (isPurchaseOrder) {
      description =
        "Please upload a copy of your company registration document, purchase order and supplier quote or invoice.";
    } else if (isInvoiceDiscounting) {
      description =
        "Please upload a copy of your company registration document, submitted invoice, appointment letter or purchase order and payment certificate.";
    }

    return description;
  };

  return (
    <React.Fragment>
      <div className="mx-auto mt-16 px-4 sm:px-6 lg:max-w-6xl lg:px-8">
        <div className="flex items-center justify-between gap-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900 lg:text-2xl">
            New Business Loan
          </h3>
          <div className="flex gap-4">
            <div
              className={`text-sm transition-opacity duration-300 ${
                selectedIndex != 0 ? "opacity-1" : "opacity-0"
              }`}
            >
              <button
                onClick={goToPreviousTab}
                disabled={selectedIndex === 0}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              >
                &larr;&nbsp;Previous Step
              </button>
            </div>
            <button
              type="button"
              onClick={() => {
                if (hasFormChanged) {
                  // TODO: Ask user if they are sure because there are unsaved changes
                  navigate(-1);
                } else {
                  navigate(-1);
                }
              }}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
            >
              <XCircleIcon className="-ml-1 mr-2 h-5 w-5 text-gray-600" />
              Cancel
            </button>
          </div>
        </div>
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="mt-12 space-y-4 rounded-md bg-white px-4 py-4 shadow-md md:flex md:space-y-0 md:space-x-8 md:px-6 lg:px-8">
            {steps.map((step) => (
              <Tab
                key={step.id}
                className={getStepperContainerClassNames(step.id - 1)}
              >
                <span className={getStepperIdClassNames(step.id - 1)}>
                  Step {step.id}
                </span>
                <span className="text-start text-sm font-medium">
                  {step.name}
                </span>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel tabIndex={0}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please enter the most appropriate business loan type for you.
              </p>
              <BusinessLoanTypeForm
                label="Next"
                businessLoan={businessLoan.currentData}
                onFieldChanged={onBusinessLoanFieldChanged}
                onButtonClick={(isValid) => isValid && goToNextTab()}
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={1}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                We provide tailor-made funding solutions ranging from R50,000 to
                R5,000,000 to SMEs who have a viable business and require
                finance for expansion or working capital. Please enter the most
                appropriate loan amount for you.
              </p>
              <CreateLoanForm
                label="Next"
                amount={businessLoan.currentData.amount}
                minLabel="R 50,000"
                maxLabel="R 5,000,000"
                minimumAmount={50000}
                maximumAmount={5000000}
                onAmountChanged={(amount) =>
                  onBusinessLoanFieldChanged("amount", amount)
                }
                onButtonClick={(isValid) =>
                  isValid
                    ? goToNextTab()
                    : toast.warn(
                        "The loan amount is invalid, please make sure its between R 50,000.00 and R 5,000,000.00 and try again."
                      )
                }
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={2}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please enter your loan details.
              </p>
              <BusinessLoanDetailsForm
                label="Next"
                businessLoan={businessLoan.currentData}
                phoneNumberErrorMessage={phoneNumberErrorMessage}
                onFieldChanged={onBusinessLoanFieldChanged}
                onPhoneNumberErrorMessage={setPhoneNumberErrorMessage}
                onButtonClick={(isValid) => isValid && goToNextTab()}
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={3}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                {getUploadDescription()}
              </p>
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <div className="col-span-1">
                  <UploadCard
                    label="Company Registration Document"
                    description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                    file={companyRegDoc.currentData.file}
                    onChange={(f) => onCompanyRegDocFieldChanged("file", f)}
                  />
                </div>
                {isProjectFinance && (
                  <div className="col-span-1">
                    <UploadCard
                      label="Appointment Letter or SLA"
                      description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                      file={appointmentLetterOrSla.currentData.file}
                      onChange={(f) =>
                        onAppointmentLetterOrSlaFieldChanged("file", f)
                      }
                    />
                  </div>
                )}
                {isProjectFinance && (
                  <div className="col-span-1">
                    <UploadCard
                      label="Bill of Quantities"
                      description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                      file={billOfQuantities.currentData.file}
                      onChange={(f) =>
                        onBillOfQuantitiesFieldChanged("file", f)
                      }
                    />
                  </div>
                )}
                {isProjectFinance && (
                  <div className="col-span-1">
                    <UploadCard
                      label="Project Implementation Plan"
                      description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                      file={projectImplementationPlan.currentData.file}
                      onChange={(f) =>
                        onProjectImplementationPlanFieldChanged("file", f)
                      }
                    />
                  </div>
                )}
                {isPurchaseOrder && (
                  <div className="col-span-1">
                    <UploadCard
                      label="Purchase Order"
                      description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                      file={purchaseOrder.currentData.file}
                      onChange={(f) => onPurchaseOrderFieldChanged("file", f)}
                    />
                  </div>
                )}
                {isPurchaseOrder && (
                  <div className="col-span-1">
                    <UploadCard
                      label="Supplier Quote or Invoice"
                      description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                      file={supplierQuoteOrInvoice.currentData.file}
                      onChange={(f) =>
                        onSupplierQuoteOrInvoiceFieldChanged("file", f)
                      }
                    />
                  </div>
                )}
                {isInvoiceDiscounting && (
                  <div className="col-span-1">
                    <UploadCard
                      label="Submitted Invoice"
                      description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                      file={invoice.currentData.file}
                      onChange={(f) => onInvoiceFieldChanged("file", f)}
                    />
                  </div>
                )}
                {isInvoiceDiscounting && (
                  <div className="col-span-1">
                    <UploadCard
                      label="Appointment Letter or Purchase Order"
                      description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                      file={appointmentLetterOrPurchaseOrder.currentData.file}
                      onChange={(f) =>
                        onAppointmentLetterOrPurchaseOrderFieldChanged(
                          "file",
                          f
                        )
                      }
                    />
                  </div>
                )}
                {isInvoiceDiscounting && (
                  <div className="col-span-1">
                    <UploadCard
                      label="Payment Certificate"
                      description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                      file={paymentCertificate.currentData.file}
                      onChange={(f) =>
                        onPaymentCertificateFieldChanged("file", f)
                      }
                    />
                  </div>
                )}
                <button
                  type={"button"}
                  onClick={() => {
                    let isUploadsValid = false;
                    const isCompanyRegDocValid =
                      companyRegDoc.currentData.file != null;
                    const isAppointmentLetterOrSlaValid =
                      appointmentLetterOrSla.currentData.file != null;
                    const isAppointmentLetterOrPurchaseOrderValid =
                      appointmentLetterOrPurchaseOrder.currentData.file != null;
                    const isBillOfQuantitiesValid =
                      billOfQuantities.currentData.file != null;
                    const isProjectImplementationPlanValid =
                      projectImplementationPlan.currentData.file != null;
                    const isPurchaseOrderValid =
                      purchaseOrder.currentData.file != null;
                    const isSupplierQuoteOrInvoiceValid =
                      supplierQuoteOrInvoice.currentData.file != null;
                    const isInvoiceValid = invoice.currentData.file != null;
                    const isPaymentCertificateValid =
                      paymentCertificate.currentData.file != null;

                    if (isProjectFinance) {
                      isUploadsValid =
                        isCompanyRegDocValid &&
                        isAppointmentLetterOrSlaValid &&
                        isBillOfQuantitiesValid &&
                        isProjectImplementationPlanValid;
                    } else if (isPurchaseOrder) {
                      isUploadsValid =
                        isCompanyRegDocValid &&
                        isPurchaseOrderValid &&
                        isSupplierQuoteOrInvoiceValid;
                    } else if (isInvoiceDiscounting) {
                      isUploadsValid =
                        isCompanyRegDocValid &&
                        isAppointmentLetterOrPurchaseOrderValid &&
                        isInvoiceValid &&
                        isPaymentCertificateValid;
                    }

                    if (isUploadsValid) {
                      goToNextTab();
                    } else {
                      toast.warn(
                        "Please upload each document before you continue."
                      );
                    }
                  }}
                  className="col-span-1 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:col-span-2 lg:col-span-3"
                >
                  Next
                </button>
              </div>
            </Tab.Panel>
            <Tab.Panel tabIndex={4}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Review your business loan's type, amount, details, and documents
                to upload before submitting your business loan application.
              </p>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Loan details
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <BusinessLoanDetails businessLoan={businessLoan.currentData} />
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Upload documents
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                  <UploadCard
                    label="Company Registration Document"
                    description={`${submitter.percent.companyRegDoc}% Uploaded`}
                    file={companyRegDoc.currentData.file!}
                  />
                  {isProjectFinance && (
                    <UploadCard
                      label="Appointment Letter or SLA"
                      description={`${submitter.percent.appointmentLetterOrSla}% Uploaded`}
                      file={appointmentLetterOrSla.currentData.file!}
                    />
                  )}
                  {isProjectFinance && (
                    <UploadCard
                      label="Bill of Quantities"
                      description={`${submitter.percent.billOfQuantities}% Uploaded`}
                      file={billOfQuantities.currentData.file!}
                    />
                  )}
                  {isProjectFinance && (
                    <UploadCard
                      label="Project Implementation Plan"
                      description={`${submitter.percent.projectImplementationPlan}% Uploaded`}
                      file={projectImplementationPlan.currentData.file!}
                    />
                  )}
                  {isPurchaseOrder && (
                    <UploadCard
                      label="Purchase Order"
                      description={`${submitter.percent.purchaseOrder}% Uploaded`}
                      file={purchaseOrder.currentData.file!}
                    />
                  )}
                  {isPurchaseOrder && (
                    <UploadCard
                      label="Supplier Quote or Invoice"
                      description={`${submitter.percent.supplierQuoteOrInvoice}% Uploaded`}
                      file={supplierQuoteOrInvoice.currentData.file!}
                    />
                  )}
                  {isInvoiceDiscounting && (
                    <UploadCard
                      label="Appointment Letter or Purchase Order"
                      description={`${submitter.percent.appointmentLetterOrPurchaseOrder}% Uploaded`}
                      file={appointmentLetterOrPurchaseOrder.currentData.file!}
                    />
                  )}
                  {isInvoiceDiscounting && (
                    <UploadCard
                      label="Submitted Invoice"
                      description={`${submitter.percent.invoice}% Uploaded`}
                      file={invoice.currentData.file!}
                    />
                  )}
                  {isInvoiceDiscounting && (
                    <UploadCard
                      label="Payment Certificate"
                      description={`${submitter.percent.paymentCertificate}% Uploaded`}
                      file={paymentCertificate.currentData.file!}
                    />
                  )}
                </div>
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Review & Submit
              </p>
              <p className="col-span-2 mt-4">
                <CheckField
                  id="business-loan-application-user-reviewed"
                  checked={reviewed}
                  description={
                    'By clicking "Submit Application" you agree that the business loan\'s type, amount, details, and documents to upload are correct and current.'
                  }
                  onChange={setReviewed}
                />
              </p>
              <button
                type={"button"}
                disabled={submitter.isSubmitting}
                onClick={() => {
                  if (reviewed) {
                    // Submit document
                    onSaveBusinessLoan();
                  } else {
                    toast.warn(
                      "Please ensure that you have reviewed the application and checked the relevant checkbox"
                    );
                  }
                }}
                className="col-span-1 mt-12 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:col-span-2 lg:col-span-3"
              >
                Submit Application
              </button>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </React.Fragment>
  );
};

export default BusinessLoanApplicationPage;
