import algoliasearch, { SearchClient } from "algoliasearch";
import React from "react";
import { BusinessLoanModel } from "../../models/business-loan";
import { ClaimModel } from "../../models/claim";
import { InsuranceModel } from "../../models/insurance";
import { LoanModel } from "../../models/loan";
import { PaginationMetadata } from "../../models/pagination-metadata";
import { UserModel } from "../../models/user";
import appConfig from "../../utils/config";

export interface SearchResults {
  users: UserModel[];
  usersMetadata: PaginationMetadata;
  personalLoans: LoanModel[];
  personalLoansMetadata: PaginationMetadata;
  businessLoans: BusinessLoanModel[];
  businessLoansMetadata: PaginationMetadata;
  claims: ClaimModel[];
  claimsMetadata: PaginationMetadata;
  insurances: InsuranceModel[];
  insurancesMetadata: PaginationMetadata;
}

type ContextState = {
  quickSearchResults: SearchResults;
  advancedSearchResults: SearchResults;
  quickSearch: (query: string, filters?: string) => Promise<void>;
  advancedSearch: (query: string, filters?: string) => Promise<void>;
};

const initialPaginationMetadata: PaginationMetadata = {
  page: 1,
  perPage: 50,
  total: 0,
  totalPages: 1,
};

const initialState: ContextState = {
  quickSearchResults: {
    users: [],
    personalLoans: [],
    businessLoans: [],
    claims: [],
    insurances: [],
    usersMetadata: initialPaginationMetadata,
    personalLoansMetadata: initialPaginationMetadata,
    businessLoansMetadata: initialPaginationMetadata,
    claimsMetadata: initialPaginationMetadata,
    insurancesMetadata: initialPaginationMetadata,
  },
  advancedSearchResults: {
    users: [],
    personalLoans: [],
    businessLoans: [],
    claims: [],
    insurances: [],
    usersMetadata: initialPaginationMetadata,
    personalLoansMetadata: initialPaginationMetadata,
    businessLoansMetadata: initialPaginationMetadata,
    claimsMetadata: initialPaginationMetadata,
    insurancesMetadata: initialPaginationMetadata,
  },
  quickSearch: () => Promise.resolve(),
  advancedSearch: () => Promise.resolve(),
};

export const SearchContext = React.createContext(initialState);

export type SearchContextProviderProps = {
  children: React.ReactNode;
};

export const SearchContextProvider = ({
  children,
}: SearchContextProviderProps) => {
  const clientRef = React.useRef<SearchClient>();
  const [users, setUsers] = React.useState<UserModel[]>([]);
  const [personalLoans, setPersonalLoans] = React.useState<LoanModel[]>([]);
  const [businessLoans, setBusinessLoans] = React.useState<BusinessLoanModel[]>(
    []
  );
  const [claims, setClaims] = React.useState<ClaimModel[]>([]);
  const [insurances, setInsurances] = React.useState<InsuranceModel[]>([]);
  const [usersAS, setUsersAS] = React.useState<UserModel[]>([]);
  const [personalLoansAS, setPersonalLoansAS] = React.useState<LoanModel[]>([]);
  const [businessLoansAS, setBusinessLoansAS] = React.useState<
    BusinessLoanModel[]
  >([]);
  const [claimsAS, setClaimsAS] = React.useState<ClaimModel[]>([]);
  const [insurancesAS, setInsurancesAS] = React.useState<InsuranceModel[]>([]);

  // Pagination Metadata states
  const [usersMetadata, setUsersMetadata] = React.useState<PaginationMetadata>(
    initialPaginationMetadata
  );
  const [usersMetadataAS, setUsersMetadataAS] =
    React.useState<PaginationMetadata>(initialPaginationMetadata);
  const [personalLoansMetadata, setPersonalLoansMetadata] =
    React.useState<PaginationMetadata>(initialPaginationMetadata);
  const [personalLoansMetadataAS, setPersonalLoansMetadataAS] =
    React.useState<PaginationMetadata>(initialPaginationMetadata);
  const [businessLoansMetadata, setBusinessLoansMetadata] =
    React.useState<PaginationMetadata>(initialPaginationMetadata);
  const [businessLoansMetadataAS, setBusinessLoansMetadataAS] =
    React.useState<PaginationMetadata>(initialPaginationMetadata);
  const [claimsMetadata, setClaimsMetadata] =
    React.useState<PaginationMetadata>(initialPaginationMetadata);
  const [claimsMetadataAS, setClaimsMetadataAS] =
    React.useState<PaginationMetadata>(initialPaginationMetadata);
  const [insurancesMetadata, setInsurancesMetadata] =
    React.useState<PaginationMetadata>(initialPaginationMetadata);
  const [insurancesMetadataAS, setInsurancesMetadataAS] =
    React.useState<PaginationMetadata>(initialPaginationMetadata);

  React.useEffect(() => {
    const { appId, searchKey } = appConfig.algolia;
    if (appId && searchKey) {
      const client = algoliasearch(appId, searchKey);
      clientRef.current = client;
    }
  }, []);

  const buildOptions = (filters?: any) => {
    let options = {};
    if (filters) {
      options = { ...options, ...filters };
    }
    return options;
  };

  const buildQueries = (query: string, filters?: any) => {
    const withoutFilters = { ...filters };
    delete withoutFilters.filters;
    const options = buildOptions(withoutFilters);
    const optionsWithFilters = buildOptions(filters);
    return [
      {
        indexName: "users",
        query,
        params: options,
      },
      {
        indexName: "personal_loans",
        query,
        params: optionsWithFilters,
      },
      {
        indexName: "business_loans",
        query,
        params: optionsWithFilters,
      },
      {
        indexName: "claims",
        query,
        params: optionsWithFilters,
      },
      {
        indexName: "insurances",
        query,
        params: optionsWithFilters,
      },
    ];
  };

  const quickSearch = async (query: string, filters?: string) => {
    const client = clientRef.current;
    if (client) {
      // Get search responses
      const { results } = await client.multipleQueries(
        buildQueries(query, filters)
      );

      const [
        usersResponses,
        personalLoansResponses,
        businessLoansResponses,
        claimsResponses,
        insurancesResponses,
      ] = results;

      if (usersResponses.hits) {
        setUsers(usersResponses.hits.map((hit) => hit as any as UserModel));
      }
      if (personalLoansResponses.hits) {
        setPersonalLoans(
          personalLoansResponses.hits.map((hit) => hit as any as LoanModel)
        );
      }
      if (businessLoansResponses.hits) {
        setBusinessLoans(
          businessLoansResponses.hits.map(
            (hit) => hit as any as BusinessLoanModel
          )
        );
      }
      if (claimsResponses.hits) {
        setClaims(claimsResponses.hits.map((hit) => hit as any as ClaimModel));
      }
      if (insurancesResponses.hits) {
        setInsurances(
          insurancesResponses.hits.map((hit) => hit as any as InsuranceModel)
        );
      }
    }
  };

  const advancedSearch = async (query: string, filters?: any) => {
    const client = clientRef.current;
    if (client) {
      // Get search responses
      const { results } = await client.multipleQueries(
        buildQueries(query, filters)
      );

      const [
        usersResponses,
        personalLoansResponses,
        businessLoansResponses,
        claimsResponses,
        insurancesResponses,
      ] = results;

      if (usersResponses.hits) {
        const { hits, nbHits, nbPages, hitsPerPage, page } = usersResponses;
        const oneBasedPage = page + 1;
        setUsersAS(hits.map((hit) => hit as any as UserModel));
        setUsersMetadataAS({
          page: oneBasedPage,
          prevPage: oneBasedPage > 1 ? oneBasedPage - 1 : null,
          nextPage: oneBasedPage < nbPages ? oneBasedPage + 1 : null,
          total: nbHits <= 1000 ? nbHits : 1000,
          perPage: hitsPerPage,
          totalPages: nbPages,
        });
      }
      if (personalLoansResponses.hits) {
        const { hits, nbHits, nbPages, hitsPerPage, page } =
          personalLoansResponses;
        const oneBasedPage = page + 1;
        setPersonalLoansAS(hits.map((hit) => hit as any as LoanModel));
        setPersonalLoansMetadataAS({
          page: oneBasedPage,
          prevPage: oneBasedPage > 1 ? oneBasedPage - 1 : null,
          nextPage: oneBasedPage < nbPages ? oneBasedPage + 1 : null,
          total: nbHits <= 1000 ? nbHits : 1000,
          perPage: hitsPerPage,
          totalPages: nbPages,
        });
      }
      if (businessLoansResponses.hits) {
        const { hits, nbHits, nbPages, hitsPerPage, page } =
          businessLoansResponses;
        const oneBasedPage = page + 1;
        setBusinessLoansAS(hits.map((hit) => hit as any as BusinessLoanModel));
        setBusinessLoansMetadataAS({
          page: oneBasedPage,
          prevPage: oneBasedPage > 1 ? oneBasedPage - 1 : null,
          nextPage: oneBasedPage < nbPages ? oneBasedPage + 1 : null,
          total: nbHits <= 1000 ? nbHits : 1000,
          perPage: hitsPerPage,
          totalPages: nbPages,
        });
      }
      if (claimsResponses.hits) {
        const { hits, nbHits, nbPages, hitsPerPage, page } = claimsResponses;
        const oneBasedPage = page + 1;
        setClaimsAS(hits.map((hit) => hit as any as ClaimModel));
        setClaimsMetadataAS({
          page: oneBasedPage,
          prevPage: oneBasedPage > 1 ? oneBasedPage - 1 : null,
          nextPage: oneBasedPage < nbPages ? oneBasedPage + 1 : null,
          total: nbHits <= 1000 ? nbHits : 1000,
          perPage: hitsPerPage,
          totalPages: nbPages,
        });
      }
      if (insurancesResponses.hits) {
        const { hits, nbHits, nbPages, hitsPerPage, page } =
          insurancesResponses;
        const oneBasedPage = page + 1;
        setInsurancesAS(hits.map((hit) => hit as any as InsuranceModel));
        setInsurancesMetadataAS({
          page: oneBasedPage,
          prevPage: oneBasedPage > 1 ? oneBasedPage - 1 : null,
          nextPage: oneBasedPage < nbPages ? oneBasedPage + 1 : null,
          total: nbHits <= 1000 ? nbHits : 1000,
          perPage: hitsPerPage,
          totalPages: nbPages,
        });
      }
    }
  };

  return (
    <SearchContext.Provider
      value={{
        quickSearchResults: {
          users,
          personalLoans,
          businessLoans,
          claims,
          insurances,
          usersMetadata,
          personalLoansMetadata,
          businessLoansMetadata,
          claimsMetadata,
          insurancesMetadata,
        },
        advancedSearchResults: {
          users: usersAS,
          personalLoans: personalLoansAS,
          businessLoans: businessLoansAS,
          claims: claimsAS,
          insurances: insurancesAS,
          usersMetadata: usersMetadataAS,
          personalLoansMetadata: personalLoansMetadataAS,
          businessLoansMetadata: businessLoansMetadataAS,
          claimsMetadata: claimsMetadataAS,
          insurancesMetadata: insurancesMetadataAS,
        },
        quickSearch,
        advancedSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useQuickSearch = (): [
  SearchResults,
  (query: string, filters?: string) => Promise<void>
] => {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error(
      "useQuickSearch must be used within a SearchContextProvider"
    );
  }
  return [context.quickSearchResults, context.quickSearch];
};

export const useAdvancedSearch = (): [
  SearchResults,
  (query: string, filters?: any) => Promise<void>
] => {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error(
      "useAdvancedSearch must be used within a SearchContextProvider"
    );
  }
  return [context.advancedSearchResults, context.advancedSearch];
};
