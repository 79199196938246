import {
  ArrowDownCircleIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { toast } from "react-toastify";

export interface UploadCardProps {
  label: string;
  description?: string;
  file?: File;
  downloadUrl?: string;
  maxSize?: number;
  onChange?: (file: File) => void;
}

const linkClassNames =
  "text-md font-medium leading-4 text-gray-500 underline decoration-indigo-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2";

const UploadCard: React.FC<UploadCardProps> = (props) => {
  const { file, downloadUrl } = props;
  const fileExists = !!file;
  const downloadUrlExists = !!downloadUrl;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const getFileOrDownloadUrl = () => {
    if (fileExists) {
      return file.name;
    } else if (downloadUrlExists) {
      return (
        <a
          download
          href={downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={linkClassNames}
        >
          Download Document
        </a>
      );
    } else {
      return "No file or download url";
    }
  };

  return (
    <div className="h-full bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 text-gray-900">{props.label}</h3>
        {props.description && (
          <p className="mt-2 text-sm text-gray-500">{props.description}</p>
        )}
        <div className="mt-6">
          <div className="rounded-md bg-gray-200 px-4 py-4 sm:flex sm:items-start sm:justify-between">
            <div className="flex items-start">
              {fileExists ? (
                <ClipboardDocumentCheckIcon className="h-5 w-5 flex-none text-gray-500" />
              ) : downloadUrlExists ? (
                <ArrowDownCircleIcon className="h-5 w-5 flex-none text-gray-500" />
              ) : (
                <ClipboardDocumentIcon className="h-5 w-5 flex-none text-gray-500" />
              )}
              <div className="ml-4">
                <p className="text-ellipsis break-all text-sm font-medium text-gray-900">
                  {getFileOrDownloadUrl()}
                </p>
              </div>
            </div>
            <input
              ref={inputRef}
              type="file"
              className="hidden"
              multiple={false}
              accept="image/png,image/jpeg,application/pdf"
              onChange={(e) => {
                const { files } = e.target;

                if (files && files.length > 0) {
                  const size = props.maxSize ?? 5242880;
                  const file = files[0];
                  if (file.size > size) {
                    toast.warn("Unable to upload. File is too big.");
                  } else if (props.onChange) {
                    props.onChange(files[0]);
                  }
                }
              }}
            />
            {props.onChange && (
              <div className="mt-8 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                <button
                  type="button"
                  onClick={() => inputRef.current?.click()}
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                >
                  {fileExists ? "Change" : "Upload"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadCard;
