import {
  collection,
  collectionGroup,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import * as React from "react";
import { useAuthIsConnected, useAuthUser, useFirebaseService } from "..";
import { StatusModel } from "../../../models/status";
import { PaginationMetadata } from "../../../models/pagination-metadata";

type ContextState = {
  claimClaimStatuses: StatusModel[];
  paginationMetadataForClaims: PaginationMetadata;
  getNextClaimStatuses: (
    id: string,
    userOnly: boolean,
    refresh: boolean
  ) => Promise<void>;
  getNewClaimStatus: () => Promise<void>;
};

const initialState: ContextState = {
  claimClaimStatuses: [],
  paginationMetadataForClaims: {
    hitsPerPage: 25,
    hasNext: true,
  },
  getNextClaimStatuses: () => Promise.resolve(),
  getNewClaimStatus: () => Promise.resolve(),
};

export const ClaimStatusesContext =
  React.createContext<ContextState>(initialState);

export type ClaimStatusesContextProviderProps = {
  children: React.ReactNode;
};

export const ClaimStatusesContextProvider = ({
  children,
}: ClaimStatusesContextProviderProps) => {
  const user = useAuthUser();
  const isConnected = useAuthIsConnected();
  const firebase = useFirebaseService();
  const [lastVisible, setLastVisible] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [claimId, setClaimId] = React.useState("");
  const [claimClaimStatuses, setClaimStatuses] = React.useState<StatusModel[]>(
    initialState.claimClaimStatuses
  );
  const [paginationMetadataForClaims, setPaginationMetadata] =
    React.useState<PaginationMetadata>(
      initialState.paginationMetadataForClaims
    );

  const getNextClaimStatuses = async (
    id: string,
    userOnly: boolean,
    refresh: boolean
  ): Promise<void> => {
    console.log("getNextClaimStatuses invoked");
    const { hitsPerPage: pageLimit, hasNext } = paginationMetadataForClaims;
    if (!refresh && !hasNext) return;
    console.log("getNextClaimStatuses can continue");
    if (isConnected && user && firebase) {
      console.log("getNextClaimStatuses has been validated");
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      const wrapWithQuery = () =>
        (ref = query(ref, orderBy("createdAt", "desc"), limit(pageLimit)));
      if (userOnly) {
        ref = collection(
          firebase.db,
          "users",
          user.uid,
          "claims",
          id,
          "statuses"
        );
        wrapWithQuery();
        if (!refresh && lastVisible) ref = query(ref, startAfter(lastVisible));
      } else {
        ref = collectionGroup(firebase.db, "statuses");
        wrapWithQuery();
        ref = query(ref, where("applicationId", "==", id));
        if (!refresh && lastVisible) ref = query(ref, startAfter(lastVisible));
      }
      const data = await getDocs(ref);

      const nextBatch = data.docs.map((doc) => doc.data() as StatusModel);
      const nextBatchLast = nextBatch[nextBatch.length - 1];
      const last = claimClaimStatuses[claimClaimStatuses.length - 1];
      if (refresh) {
        setClaimId(id);
        setClaimStatuses([...nextBatch]);
        setLastVisible(data.docs[data.docs.length - 1]);
        setPaginationMetadata({
          ...paginationMetadataForClaims,
          hasNext: data.size === pageLimit,
        });
      } else if (nextBatchLast !== last) {
        setClaimId(id);
        setClaimStatuses([...claimClaimStatuses, ...nextBatch]);
        setLastVisible(data.docs[data.docs.length - 1]);
        setPaginationMetadata({
          ...paginationMetadataForClaims,
          hasNext: data.size === pageLimit,
        });
      }
    } else {
      return Promise.reject("firebase service is undefined");
    }
  };

  const getNewClaimStatus = async () => {
    if (isConnected && firebase && user) {
      const ref = collection(
        firebase.db,
        "users",
        user.uid,
        "claims",
        claimId,
        "statuses"
      );
      const q = query(ref, orderBy("createdAt", "desc"), limit(1));
      const data = await getDocs(q);
      if (data.size === 1) {
        const oldStatus = claimClaimStatuses[0];
        const newStatus = data.docs[0].data() as StatusModel;
        if (newStatus.id !== oldStatus?.id) {
          setClaimStatuses([newStatus, ...claimClaimStatuses]);
        }
      }
    }
  };

  return (
    <ClaimStatusesContext.Provider
      value={{
        claimClaimStatuses,
        paginationMetadataForClaims,
        getNextClaimStatuses,
        getNewClaimStatus,
      }}
    >
      {children}
    </ClaimStatusesContext.Provider>
  );
};

export const useClaimStatuses = (): [
  StatusModel[],
  PaginationMetadata,
  (id: string, userOnly: boolean, refresh: boolean) => Promise<void>,
  () => Promise<void>
] => {
  const context = React.useContext(ClaimStatusesContext);
  if (context === undefined) {
    throw new Error(
      "useClaimStatuses must be used within a ClaimStatusesProvider"
    );
  }
  return [
    context.claimClaimStatuses,
    context.paginationMetadataForClaims,
    context.getNextClaimStatuses,
    context.getNewClaimStatus,
  ];
};
