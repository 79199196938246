import {
  collection,
  collectionGroup,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import * as React from "react";
import { useAuthIsConnected, useAuthUser, useFirebaseService } from ".";
import { InsuranceModel } from "../../models/insurance";
import { PaginationMetadata } from "../../models/pagination-metadata";

type ContextState = {
  insurances: InsuranceModel[];
  allInsurances: InsuranceModel[];
  detailInsurance?: InsuranceModel;
  paginationMetadata: PaginationMetadata;
  paginationMetadataForAll: PaginationMetadata;
  getNextInsurances: (userOnly: boolean) => Promise<void>;
  getNewInsurance: () => Promise<void>;
  getInsuranceById: (id: string, userOnly: boolean) => Promise<void>;
};

const initialState: ContextState = {
  insurances: [],
  allInsurances: [],
  paginationMetadata: {
    hitsPerPage: 25,
    hasNext: true,
  },
  paginationMetadataForAll: {
    hitsPerPage: 25,
    hasNext: true,
  },
  getNextInsurances: () => Promise.resolve(),
  getNewInsurance: () => Promise.resolve(),
  getInsuranceById: (id: string, userOnly: boolean) => Promise.resolve(),
};

export const InsurancesContext =
  React.createContext<ContextState>(initialState);

type InsurancesContextProviderProps = {
  children: React.ReactNode;
};

const InsurancesContextProvider = ({
  children,
}: InsurancesContextProviderProps) => {
  const user = useAuthUser();
  const isConnected = useAuthIsConnected();
  const firebase = useFirebaseService();
  const [lastVisible, setLastVisible] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [lastVisibleForAll, setLastVisibleForAll] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [insurances, setInsurances] = React.useState<InsuranceModel[]>(
    initialState.insurances
  );
  const [allInsurances, setAllInsurances] = React.useState<InsuranceModel[]>(
    initialState.allInsurances
  );
  const [paginationMetadata, setPaginationMetadata] =
    React.useState<PaginationMetadata>(initialState.paginationMetadata);
  const [paginationMetadataForAll, setPaginationMetadataForAll] =
    React.useState<PaginationMetadata>(initialState.paginationMetadataForAll);
  const [detailInsurance, setDetailInsurance] = React.useState<
    InsuranceModel | undefined
  >(initialState.detailInsurance);

  // This effect updates the other cached lists of insurances with the latest
  // of the detail insurance document.
  React.useEffect(() => {
    if (detailInsurance != undefined) {
      const userIns = insurances.find(
        (insurance) => insurance.id === detailInsurance.id
      );
      const allIns = allInsurances.find(
        (insurance) => insurance.id === detailInsurance.id
      );
      if (userIns) {
        const i = insurances.indexOf(userIns);
        const update = Array.from(insurances);
        update[i] = detailInsurance;
        setInsurances(update);
        setLastVisible(undefined);
      }
      if (allIns) {
        const i = allInsurances.indexOf(allIns);
        const update = Array.from(allInsurances);
        update[i] = detailInsurance;
        setAllInsurances(update);
        setLastVisibleForAll(undefined);
      }
    }
  }, [detailInsurance]);

  const getNextInsurances = async (userOnly: boolean): Promise<void> => {
    const { hitsPerPage: pageLimit, hasNext } = userOnly
      ? paginationMetadata
      : paginationMetadataForAll;
    if (isConnected && user && firebase) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      const wrapWithQuery = () =>
        (ref = query(ref, orderBy("createdAt", "desc"), limit(pageLimit)));
      if (userOnly) {
        ref = collection(firebase.db, "users", user.uid, "insurances");
        wrapWithQuery();
        if (
          lastVisible &&
          lastVisible.id == insurances[insurances.length - 1].id
        )
          ref = query(ref, startAfter(lastVisible));
      } else {
        ref = collectionGroup(firebase.db, "insurances");
        wrapWithQuery();
        if (
          lastVisibleForAll &&
          lastVisibleForAll.id == allInsurances[allInsurances.length - 1].id
        )
          ref = query(ref, startAfter(lastVisibleForAll));
      }
      const data = await getDocs(ref);

      const nextBatch = data.docs.map((doc) => doc.data() as InsuranceModel);
      const nextBatchLast = nextBatch[nextBatch.length - 1];
      if (userOnly) {
        const last = insurances[insurances.length - 1];
        if (nextBatchLast?.id !== last?.id) {
          setInsurances([...insurances, ...nextBatch]);
          setLastVisible(data.docs[data.docs.length - 1]);
          setPaginationMetadata({
            ...paginationMetadata,
            hasNext: data.size === pageLimit,
          });
        }
      } else {
        const last = allInsurances[allInsurances.length - 1];
        if (nextBatchLast?.id !== last?.id) {
          setAllInsurances([...allInsurances, ...nextBatch]);
          setLastVisibleForAll(data.docs[data.docs.length - 1]);
          setPaginationMetadataForAll({
            ...paginationMetadataForAll,
            hasNext: data.size === pageLimit,
          });
        }
      }
    } else {
      return Promise.reject("firebase service is undefined");
    }
  };

  const getInsuranceById = async (
    id: string,
    userOnly: boolean
  ): Promise<void> => {
    const c = insurances.find((insurance) => insurance.id === id);
    if (c) {
      setDetailInsurance(c);
    } else if (isConnected && firebase && user) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      if (userOnly) {
        const insuranceDoc = await firebase.businessLoanDoc(user.uid, id);
        setDetailInsurance(insuranceDoc.data() as InsuranceModel);
      } else {
        ref = collectionGroup(firebase.db, "insurances");
        ref = query(ref, where("id", "==", id));
        const { docs } = await getDocs(ref);
        if (docs.length === 1) {
          setDetailInsurance(docs[0].data() as InsuranceModel);
        }
      }
    }
  };

  const getNewInsurance = async () => {
    if (isConnected && firebase && user) {
      const ref = collection(firebase.db, "users", user.uid, "insurances");
      const q = query(ref, orderBy("createdAt", "desc"), limit(1));
      const data = await getDocs(q);
      if (data.size === 1) {
        const oldInsurance = insurances[0];
        const newInsurance = data.docs[0].data() as InsuranceModel;
        if (newInsurance.id !== oldInsurance?.id) {
          setInsurances([newInsurance, ...insurances]);
        }
      }
    }
  };

  return (
    <InsurancesContext.Provider
      value={{
        insurances,
        allInsurances,
        detailInsurance,
        paginationMetadata,
        paginationMetadataForAll,
        getNextInsurances,
        getNewInsurance,
        getInsuranceById,
      }}
    >
      {children}
    </InsurancesContext.Provider>
  );
};

const useInsurances = (): [
  InsuranceModel[],
  PaginationMetadata,
  (userOnly: boolean) => Promise<void>,
  () => Promise<void>
] => {
  const context = React.useContext(InsurancesContext);
  if (context === undefined) {
    throw new Error("useInsurances must be used within a InsurancesProvider");
  }
  return [
    context.insurances,
    context.paginationMetadata,
    context.getNextInsurances,
    context.getNewInsurance,
  ];
};

const useAllInsurances = (): [
  InsuranceModel[],
  PaginationMetadata,
  (userOnly: boolean) => Promise<void>
] => {
  const context = React.useContext(InsurancesContext);
  if (context === undefined) {
    throw new Error(
      "useAllInsurances must be used within a InsurancesProvider"
    );
  }
  return [
    context.allInsurances,
    context.paginationMetadataForAll,
    context.getNextInsurances,
  ];
};

const useInsuranceById = (): [
  InsuranceModel | undefined,
  (id: string, userOnly: boolean) => Promise<void>
] => {
  const context = React.useContext(InsurancesContext);
  if (context === undefined) {
    throw new Error(
      "useInsuranceById must be used within a InsurancesProvider"
    );
  }
  return [context.detailInsurance, context.getInsuranceById];
};

export {
  InsurancesContextProvider,
  useInsurances,
  useAllInsurances,
  useInsuranceById,
};
