import React, { Reducer } from "react";
import { AddressModel } from "../models/address";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum AddressFormActionType {
  INITIALISE,
  CHANGE_FIELD,
}

export interface AddressFormAction {
  type: AddressFormActionType;
  initialData?: AddressModel;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface AddressFormState {
  currentData: AddressModel;
  initialData: AddressModel;
  hasChanged: boolean;
}

const initialData: AddressModel = {
  line1: "",
  line2: "",
  line3: "",
  postalCode: 0,
  formattedPostalCode: "0000",
};

const initialState: AddressFormState = {
  initialData,
  currentData: initialData,
  hasChanged: false,
};

const reducer = (state = initialState, action: AddressFormAction) => {
  const { type, payload, initialData } = action;
  switch (type) {
    case AddressFormActionType.INITIALISE:
      if (initialData) {
        return {
          initialData,
          currentData: initialData,
          hasChanged: false,
        };
      } else {
        throw Error(
          `initialState is invalid for action type: ${type.toString()}`
        );
      }
    case AddressFormActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField(
          { ...state.currentData },
          fieldKey,
          fieldValue
        );
        return {
          ...state,
          currentData,
          hasChanged: haveFieldsChanged(state.initialData, currentData),
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

// Returns true if the current data fields are not equal to the initial data fields.
const haveFieldsChanged = (
  initialData: AddressModel,
  currentData: AddressModel
) => {
  const hasLine1Changed = initialData.line1 !== currentData.line1;
  const hasLine2Changed = initialData.line2 !== currentData.line2;
  const hasLine3Changed = initialData.line3 !== currentData.line3;
  const hasPostalCodeChanged =
    initialData.postalCode !== currentData.postalCode;
  return (
    hasLine1Changed ||
    hasLine2Changed ||
    hasLine3Changed ||
    hasPostalCodeChanged
  );
};

const useAddressFormReducer = (): [
  AddressFormState,
  React.Dispatch<AddressFormAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useAddressFormReducer;
