import * as React from "react";
import { useAuthIsConnected, useFirebaseService } from ".";
import {
  ApplicationTypeSettingModel,
  SettingModel,
} from "../../models/setting";

type ContextState = {
  settings: SettingModel;
  isLoading: boolean;
  isLoaded: boolean;
};

const initialStateForApplicationTypeSetting: ApplicationTypeSettingModel = {
  disabled: false,
  disabledMessage: "",
};

const initialState: ContextState = {
  settings: {
    id: "",
    applications: {
      personalLoans: initialStateForApplicationTypeSetting,
      businessLoans: initialStateForApplicationTypeSetting,
      claims: initialStateForApplicationTypeSetting,
      insurances: initialStateForApplicationTypeSetting,
    },
    createdAt: "",
    updatedAt: "",
  },
  isLoading: false,
  isLoaded: false,
};

export const SettingsContext = React.createContext<ContextState>(initialState);

type SettingsContextProviderProps = {
  children: React.ReactNode;
};

const SettingsContextProvider = ({
  children,
}: SettingsContextProviderProps) => {
  const isConnected = useAuthIsConnected();
  const firebase = useFirebaseService();

  const [settings, setSettings] = React.useState<SettingModel>(
    initialState.settings
  );
  const [isLoaded, setIsLoaded] = React.useState<boolean>(
    initialState.isLoaded
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(
    initialState.isLoading
  );

  const getSettings = async () => {
    try {
      if (isConnected && firebase) {
        setIsLoading(true);
        const settingDoc = await firebase.settingDoc("settings");
        if (settingDoc.exists()) {
          setIsLoaded(true);
          setSettings(settingDoc.data() as SettingModel);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  };

  React.useEffect(() => {
    getSettings();
  }, [isConnected, firebase]);

  return (
    <SettingsContext.Provider value={{ settings, isLoaded, isLoading }}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettings = (): [SettingModel, boolean, boolean] => {
  const context = React.useContext(SettingsContext);
  if (context === undefined) {
    throw new Error(
      "useSettings must be used within a SettingsContextProvider"
    );
  }
  return [context.settings, context.isLoaded, context.isLoading];
};

export { SettingsContextProvider, useSettings };
