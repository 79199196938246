import {
  collection,
  collectionGroup,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import * as React from "react";
import { useAuthIsConnected, useAuthUser, useFirebaseService } from ".";
import { BusinessLoanModel } from "../../models/business-loan";
import { PaginationMetadata } from "../../models/pagination-metadata";

type ContextState = {
  businessLoans: BusinessLoanModel[];
  allBusinessLoans: BusinessLoanModel[];
  detailBusinessLoan?: BusinessLoanModel;
  paginationMetadata: PaginationMetadata;
  paginationMetadataForAll: PaginationMetadata;
  getNextBusinessLoans: (userOnly: boolean) => Promise<void>;
  getNewBusinessLoan: () => Promise<void>;
  getBusinessLoanById: (id: string, userOnly: boolean) => Promise<void>;
};

const initialState: ContextState = {
  businessLoans: [],
  allBusinessLoans: [],
  paginationMetadata: {
    hitsPerPage: 25,
    hasNext: true,
  },
  paginationMetadataForAll: {
    hitsPerPage: 25,
    hasNext: true,
  },
  getNextBusinessLoans: (userOnly: boolean) => Promise.resolve(),
  getNewBusinessLoan: () => Promise.resolve(),
  getBusinessLoanById: (id: string, userOnly: boolean) => Promise.resolve(),
};

export const BusinessLoansContext =
  React.createContext<ContextState>(initialState);

type BusinessLoansContextProviderProps = {
  children: React.ReactNode;
};

const BusinessLoansContextProvider = ({
  children,
}: BusinessLoansContextProviderProps) => {
  const user = useAuthUser();
  const isConnected = useAuthIsConnected();
  const firebase = useFirebaseService();
  const [lastVisible, setLastVisible] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [lastVisibleForAll, setLastVisibleForAll] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [businessLoans, setBusinessLoans] = React.useState<BusinessLoanModel[]>(
    initialState.businessLoans
  );
  const [allBusinessLoans, setAllBusinessLoans] = React.useState<
    BusinessLoanModel[]
  >(initialState.allBusinessLoans);
  const [paginationMetadata, setPaginationMetadata] =
    React.useState<PaginationMetadata>(initialState.paginationMetadata);
  const [paginationMetadataForAll, setPaginationMetadataForAll] =
    React.useState<PaginationMetadata>(initialState.paginationMetadataForAll);
  const [detailBusinessLoan, setDetailBusinessLoan] = React.useState<
    BusinessLoanModel | undefined
  >(initialState.detailBusinessLoan);

  // This effect updates the other cached lists of insurances with the latest
  // of the detail insurance document.
  React.useEffect(() => {
    if (detailBusinessLoan != undefined) {
      const userIns = businessLoans.find(
        (insurance) => insurance.id === detailBusinessLoan.id
      );
      const allIns = allBusinessLoans.find(
        (insurance) => insurance.id === detailBusinessLoan.id
      );
      if (userIns) {
        const i = businessLoans.indexOf(userIns);
        const update = Array.from(businessLoans);
        update[i] = detailBusinessLoan;
        setBusinessLoans(update);
        setLastVisible(undefined);
      }
      if (allIns) {
        const i = allBusinessLoans.indexOf(allIns);
        const update = Array.from(allBusinessLoans);
        update[i] = detailBusinessLoan;
        setAllBusinessLoans(update);
        setLastVisibleForAll(undefined);
      }
    }
  }, [detailBusinessLoan]);

  const getNextBusinessLoans = async (userOnly: boolean): Promise<void> => {
    const { hitsPerPage: pageLimit, hasNext } = userOnly
      ? paginationMetadata
      : paginationMetadataForAll;
    if (isConnected && user && firebase) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      const wrapWithQuery = () =>
        (ref = query(ref, orderBy("createdAt", "desc"), limit(pageLimit)));
      if (userOnly) {
        ref = collection(firebase.db, "users", user.uid, "business_loans");
        wrapWithQuery();
        if (lastVisible) ref = query(ref, startAfter(lastVisible));
      } else {
        ref = collectionGroup(firebase.db, "business_loans");
        wrapWithQuery();
        if (lastVisibleForAll) ref = query(ref, startAfter(lastVisibleForAll));
      }
      const data = await getDocs(ref);

      const nextBatch = data.docs.map((doc) => doc.data() as BusinessLoanModel);
      const nextBatchLast = nextBatch[nextBatch.length - 1];
      if (userOnly) {
        const last = businessLoans[businessLoans.length - 1];
        if (nextBatchLast?.id !== last?.id) {
          setBusinessLoans([...businessLoans, ...nextBatch]);
          setLastVisible(data.docs[data.docs.length - 1]);
          setPaginationMetadata({
            ...paginationMetadata,
            hasNext: data.size === pageLimit,
          });
        }
      } else {
        const last = allBusinessLoans[allBusinessLoans.length - 1];
        if (nextBatchLast?.id !== last?.id) {
          setAllBusinessLoans([...allBusinessLoans, ...nextBatch]);
          setLastVisibleForAll(data.docs[data.docs.length - 1]);
          setPaginationMetadataForAll({
            ...paginationMetadataForAll,
            hasNext: data.size === pageLimit,
          });
        }
      }
    } else {
      return Promise.reject("firebase service is undefined");
    }
  };

  const getBusinessLoanById = async (
    id: string,
    userOnly: boolean
  ): Promise<void> => {
    const c = businessLoans.find((loan) => loan.id === id);
    if (c) {
      setDetailBusinessLoan(c);
    } else if (isConnected && firebase && user) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      if (userOnly) {
        const businessLoanDoc = await firebase.businessLoanDoc(user.uid, id);
        setDetailBusinessLoan(businessLoanDoc.data() as BusinessLoanModel);
      } else {
        ref = collectionGroup(firebase.db, "business_loans");
        ref = query(ref, where("id", "==", id));
        const { docs } = await getDocs(ref);
        if (docs.length === 1) {
          setDetailBusinessLoan(docs[0].data() as BusinessLoanModel);
        }
      }
    }
  };

  const getNewBusinessLoan = async () => {
    if (isConnected && firebase && user) {
      const ref = collection(firebase.db, "users", user.uid, "business_loans");
      const q = query(ref, orderBy("createdAt", "desc"), limit(1));
      const data = await getDocs(q);
      if (data.size === 1) {
        const oldBusinessLoan = businessLoans[0];
        const newBusinessLoan = data.docs[0].data() as BusinessLoanModel;
        if (newBusinessLoan.id !== oldBusinessLoan?.id) {
          setBusinessLoans([newBusinessLoan, ...businessLoans]);
        }
      }
    }
  };

  return (
    <BusinessLoansContext.Provider
      value={{
        businessLoans,
        allBusinessLoans,
        detailBusinessLoan,
        paginationMetadata,
        paginationMetadataForAll,
        getNextBusinessLoans,
        getNewBusinessLoan,
        getBusinessLoanById,
      }}
    >
      {children}
    </BusinessLoansContext.Provider>
  );
};

const useBusinessLoans = (): [
  BusinessLoanModel[],
  PaginationMetadata,
  (userOnly: boolean) => Promise<void>,
  () => Promise<void>
] => {
  const context = React.useContext(BusinessLoansContext);
  if (context === undefined) {
    throw new Error(
      "useBusinessLoans must be used within a BusinessLoansProvider"
    );
  }
  return [
    context.businessLoans,
    context.paginationMetadata,
    context.getNextBusinessLoans,
    context.getNewBusinessLoan,
  ];
};

const useAllBusinessLoans = (): [
  BusinessLoanModel[],
  PaginationMetadata,
  (userOnly: boolean) => Promise<void>
] => {
  const context = React.useContext(BusinessLoansContext);
  if (context === undefined) {
    throw new Error(
      "useAllBusinessLoans must be used within a BusinessLoansProvider"
    );
  }
  return [
    context.allBusinessLoans,
    context.paginationMetadataForAll,
    context.getNextBusinessLoans,
  ];
};

const useBusinessLoanById = (): [
  BusinessLoanModel | undefined,
  (id: string, userOnly: boolean) => Promise<void>
] => {
  const context = React.useContext(BusinessLoansContext);
  if (context === undefined) {
    throw new Error(
      "useBusinessLoanById must be used within a BusinessLoansProvider"
    );
  }
  return [context.detailBusinessLoan, context.getBusinessLoanById];
};

export {
  BusinessLoansContextProvider,
  useBusinessLoans,
  useAllBusinessLoans,
  useBusinessLoanById,
};
