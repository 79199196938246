import React, { Reducer } from "react";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum ClaimSubmissionActionType {
  RESET_ALL_PERCENTS,
  CHANGE_FIELD,
}

export interface ClaimSubmissionAction {
  type: ClaimSubmissionActionType;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface ClaimSubmissionState {
  isSubmitting: boolean;
  isSubmitted: boolean;
  percent: {
    claimForm: number;
  };
  download: {
    claimFormUrl: string;
  };
}

const initialState: ClaimSubmissionState = {
  isSubmitting: false,
  isSubmitted: false,
  percent: {
    claimForm: 0,
  },
  download: {
    claimFormUrl: "",
  },
};

const reducer = (state = initialState, action: ClaimSubmissionAction) => {
  const { type, payload } = action;
  switch (type) {
    case ClaimSubmissionActionType.RESET_ALL_PERCENTS:
      return {
        ...state,
        percent: {
          claimForm: 0,
        },
      };
    case ClaimSubmissionActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField({ ...state }, fieldKey, fieldValue);
        return {
          ...state,
          currentData,
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

const useClaimSubmissionReducer = (): [
  ClaimSubmissionState,
  React.Dispatch<ClaimSubmissionAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useClaimSubmissionReducer;
