import React from "react";

export interface EmptyStateProps {
  title: string;
  description: string;
  actions: React.ReactNode;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const { title, description, actions } = props;

  return (
    <div className="text-center">
      <props.icon />
      <h3 className="mt-2 text-sm font-medium text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
      <div className="mt-6">{actions}</div>
    </div>
  );
};

export default EmptyState;
