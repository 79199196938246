import React from "react";
import ApplicationStatusBadge from "../../components/shared/ApplicationStatusBadge";
import { ApplicationStatus } from "../enums";
import { BaseUser } from "../models/user";
import { formatApplicationStatus } from "../utils/format";

export const buildUserFullName = (
  isYou: boolean,
  you: string,
  user?: BaseUser
) => {
  if (isYou) {
    return you;
  } else {
    return user ? `${user.name} ${user.surname}` : "Unknown User";
  }
};

export const buildStatusLabel = (status: ApplicationStatus) => (
  <div className="flex items-center gap-2">
    <div className="h-4 w-4 overflow-hidden rounded-full shadow-sm">
      <ApplicationStatusBadge status={status}>
        <div className="h-4 w-4"></div>
      </ApplicationStatusBadge>
    </div>
    <span>{formatApplicationStatus(status)}</span>
  </div>
);
