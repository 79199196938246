import { FolderOpenIcon } from "@heroicons/react/24/solid";
import React from "react";
import { BusinessLoanModel } from "../../core/models/business-loan";
import EmptyState from "../shared/EmptyState";
import LoadingState from "../shared/LoadingState";
import Spinner from "../shared/Spinner";
import BusinessLoanListItem from "./BusinessLoanListItem";
import LoadMoreListItem from "./LoadMoreListItem";

export interface BusinessLoanListProps {
  businessLoans: BusinessLoanModel[];
  isLoading: boolean;
  hasNext: boolean;
  onLoadMoreClick: () => void;
  emptyStateActions?: React.ReactNode;
  nonEmptyStateActions?: React.ReactNode;
  isAdmin?: boolean;
}

const BusinessLoanList: React.FC<BusinessLoanListProps> = (props) => {
  const { businessLoans, isLoading, hasNext, onLoadMoreClick } = props;
  const businessLoanExist = businessLoans.length > 0;

  return (
    <div className="grid grid-cols-1 gap-4">
      {businessLoanExist && (
        <React.Fragment>
          {props.nonEmptyStateActions && (
            <div className="mb-4">{props.nonEmptyStateActions}</div>
          )}
          {businessLoans.map((businessLoan) => {
            return (
              <div className="rounded-md border-2 transition-colors hover:bg-indigo-100">
                <BusinessLoanListItem
                  to={`/dashboard/applications/business-loans/${businessLoan.id}`}
                  businessLoan={businessLoan}
                />
              </div>
            );
          })}
          {!isLoading && (
            <div className="mt-4">
              <LoadMoreListItem
                hasNext={hasNext}
                onLoadMoreClick={onLoadMoreClick}
                loadMoreLabel="Load Business Loan Applications"
                noMoreLabel="There are no more business loan applications to load"
              />
            </div>
          )}
        </React.Fragment>
      )}

      {!businessLoanExist && !isLoading && (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <EmptyState
            title="No Business Loan Applications"
            description="Once created, you will see business loan applications show up here."
            icon={(props) => (
              <FolderOpenIcon
                className="mx-auto my-8 h-12 w-12 text-gray-400"
                {...props}
              />
            )}
            actions={props.emptyStateActions}
          />
        </div>
      )}

      {isLoading && (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading Business Loan Applications"
            description="Loading business loan applications, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      )}
    </div>
  );
};

export default BusinessLoanList;
