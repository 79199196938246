import React from "react";
import ElevatedButton from "../shared/buttons/ElevatedButton";

export interface LoadMoreListItemProps {
  hasNext: boolean;
  loadMoreLabel: string;
  noMoreLabel: string;
  onLoadMoreClick: () => void;
}

const LoadMoreListItem: React.FC<LoadMoreListItemProps> = (props) => {
  const { loadMoreLabel, noMoreLabel, hasNext, onLoadMoreClick } = props;

  if (hasNext) {
    return (
      <ElevatedButton onClick={onLoadMoreClick} isFullWidth isOutline>
        {loadMoreLabel}
      </ElevatedButton>
    );
  } else {
    return (
      <div className="flex flex-col justify-center">
        <p className="mx-auto font-medium text-gray-400">{noMoreLabel}</p>
      </div>
    );
  }
};

export default LoadMoreListItem;
