import * as React from "react";
import { toast } from "react-toastify";
import { EmployerDetailsModel } from "../../core/models/employer-details";
import AddressLineField from "../fields/AddressLineField";
import EmploymentStatusField from "../fields/EmploymentStatusField";
import NameField from "../fields/NameField";
import PhoneNumberField from "../fields/PhoneNumberField";
import PostalCodeField from "../fields/PostalCode";
import SalaryDateField from "../fields/SalaryDateField";

export interface CreateEmployerFormProps {
  label: string;
  employerDetails: EmployerDetailsModel;
  phoneNumberErrorMessage: string;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onPhoneNumberErrorMessage: (errorMessage: string) => void;
  onFieldChanged: (fieldKey: string, fieldValue: any) => void;
}

const CreateEmployerForm = (props: CreateEmployerFormProps) => {
  const { employerDetails } = props;

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isFormValid = event.currentTarget.checkValidity();
    if (props.phoneNumberErrorMessage) {
      isFormValid = false;
      toast.error(props.phoneNumberErrorMessage, {
        toastId: props.phoneNumberErrorMessage,
      });
    }
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="space-y-8 divide-y divide-gray-200">
      <div className="grid grid-cols-2 gap-6">
        <p className="col-span-2 text-xl font-medium">Employer Information</p>
        <div className="col-span-2 lg:col-span-1">
          <NameField
            name={employerDetails.name}
            placeholder="Company Name (Pty) Ltd"
            onChange={(v) => props.onFieldChanged("name", v)}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <PhoneNumberField
            phoneNumber={employerDetails.cell}
            onChange={(v, e) => {
              props.onFieldChanged("cell", v);
              props.onPhoneNumberErrorMessage(e);
            }}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <SalaryDateField
            salaryDate={employerDetails.salaryDate}
            onChange={(v) => props.onFieldChanged("salaryDate", v)}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <EmploymentStatusField
            employmentStatus={employerDetails.employmentStatus}
            onChange={(v) => props.onFieldChanged("employmentStatus", v)}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 pt-8">
        <p className="col-span-2 text-xl font-medium">Employer Address</p>
        <div className="col-span-2">
          <AddressLineField
            lineNumber={1}
            line={employerDetails.address.line1}
            onChange={(v) => props.onFieldChanged("address.line1", v)}
          />
        </div>
        <div className="col-span-2">
          <AddressLineField
            lineNumber={2}
            line={employerDetails.address.line2}
            onChange={(v) => props.onFieldChanged("address.line2", v)}
          />
        </div>
        <div className="col-span-2">
          <AddressLineField
            lineNumber={3}
            line={employerDetails.address.line3 ?? ""}
            onChange={(v) => props.onFieldChanged("address.line3", v)}
          />
        </div>
        <div className="col-span-2">
          <PostalCodeField
            postalCode={employerDetails.address.postalCode}
            onChange={(v) => props.onFieldChanged("address.postalCode", v)}
          />
        </div>
      </div>

      <div className="col-span-2 flex gap-8">
        <button
          type={props.type ?? "submit"}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {props.label}
        </button>
      </div>
    </form>
  );
};

export default CreateEmployerForm;
