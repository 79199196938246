export enum BankAccountType {
  cheque_account = "cheque_account",
  saving = "saving",
  unknown = "unknown",
}

export interface BankingDetailsModel {
  name: string;
  accountNumber: string;
  accountType: BankAccountType;
  branchCode: string;
}
