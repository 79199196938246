import * as React from "react";
import { HeadFC, Link, navigate } from "gatsby";
import { CustomHead } from "../../../components/shell/CustomHead";
import {
  EnvelopeIcon,
  PencilIcon,
  PhoneIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { classNames } from "../../../core/ui/classNames";
import {
  useAuthIsConnected,
  useAuthUser,
  useAuthUserDoc,
  useFirebaseService,
} from "../../../core/contexts/firebase";
import UserAvatar from "../../../components/shared/UserAvatar";
import { Tab } from "@headlessui/react";
import EmptyState from "../../../components/shared/EmptyState";
import { FolderOpenIcon } from "@heroicons/react/24/outline";
import AddressDetails from "../../../components/details/AddressDetails";
import EmployerDetails from "../../../components/details/EmployerDetails";
import ProfileDetails from "../../../components/details/ProfileDetails";
import BankingDetails from "../../../components/details/BankingDetails";
import AffordabilityDetails from "../../../components/details/AffordabilityDetails";

const tabs = [
  {
    name: "Profile",
    editTo: "/dashboard/profile/edit",
    current: true,
  },
  {
    name: "Address",
    editTo: "/dashboard/profile/address/edit",
    current: false,
  },
  {
    name: "Employer",
    editTo: "/dashboard/profile/employer/edit",
    current: false,
  },
  {
    name: "Banking",
    editTo: "/dashboard/profile/banking/edit",
    current: false,
  },
  {
    name: "Affordability",
    editTo: "/dashboard/profile/affordability/edit",
    current: false,
  },
];
const profile = {
  coverImageUrl:
    "https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
};

const UserProfilePage = () => {
  const user = useAuthUser();
  const isConnected = useAuthIsConnected();
  const userDoc = useAuthUserDoc();
  const userExists = user != null;
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  if (!isConnected || !userExists) return null;

  React.useEffect(() => {
    const location = window?.location;
    if (location) {
      const params = new URLSearchParams(location.search);
      const tabStr = params.get("tab");
      switch (tabStr) {
        case "address":
          setSelectedIndex(1);
          break;
        case "employer":
          setSelectedIndex(2);
          break;
        case "banking":
          setSelectedIndex(3);
          break;
        case "affordability":
          setSelectedIndex(4);
          break;

        case "profile":
        default:
          setSelectedIndex(0);
          break;
      }
    }
  }, [window?.location.search]);

  const showEditButton = () => {
    switch (selectedIndex) {
      case 0:
        return true;
      case 1:
        return userDoc?.defaultAddress !== null;
      case 2:
        return userDoc?.defaultEmployerDetails !== null;
      case 3:
        return userDoc?.defaultBankDetails !== null;
      case 4:
        return userDoc?.defaultAffordability !== null;

      default:
        return false;
    }
  };

  return (
    <React.Fragment>
      <article>
        {/* Profile header */}
        <div>
          <div>
            <img
              className="h-32 w-full object-cover lg:h-48"
              src={profile.coverImageUrl}
              alt=""
            />
          </div>
          <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
            <div className="-mt-12 pb-4 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
              <div className="flex pt-6">
                <UserAvatar size="large" />
              </div>
              <div className="mt-8 min-h-[80px] sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                <div className="mt-8 min-w-0 flex-1 sm:hidden 2xl:block">
                  <h1 className="truncate text-2xl font-bold text-gray-900">
                    {user.displayName}
                  </h1>
                </div>
                {showEditButton() && (
                  <div className="justify-stretch mt-8 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                    <Link
                      to={tabs[selectedIndex].editTo}
                      className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                    >
                      <PencilIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Edit {tabs[selectedIndex].name}</span>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
              <h1 className="truncate pb-4 text-2xl font-bold text-gray-900">
                {user.displayName}
              </h1>
            </div>
          </div>

          {/* Tabs */}
          <div className="mt-2 2xl:mt-5">
            <div className="border-b border-gray-200">
              <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                <Tab.Group selectedIndex={selectedIndex}>
                  <div className="overflow-hidden overflow-x-auto rounded-md bg-white shadow-md">
                    <Tab.List className="-mb-px flex flex-col justify-center gap-4 py-4 px-4 md:flex-row lg:justify-start">
                      {tabs.map((tab, index) => (
                        <Tab
                          key={tab.name}
                          onClick={() =>
                            navigate(`?tab=${tab.name.toLowerCase()}`)
                          }
                        >
                          {({ selected }: any) => (
                            <span
                              className={classNames(
                                selected
                                  ? "border-indigo-500 text-gray-900"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "text-medium whitespace-nowrap border-b-2 py-2 px-1 font-medium"
                              )}
                            >
                              {tab.name}
                            </span>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels className="py-12">
                    <Tab.Panel tabIndex={0}>
                      {/* Profile details */}
                      <div className="mx-auto mt-6 max-w-5xl">
                        {userDoc && <ProfileDetails user={userDoc} />}
                      </div>
                    </Tab.Panel>
                    <Tab.Panel tabIndex={1}>
                      {/* Address details */}
                      <div className="mx-auto mt-6 max-w-5xl">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          {userDoc?.defaultAddress ? (
                            <AddressDetails address={userDoc.defaultAddress} />
                          ) : (
                            <div className="col-span-2 rounded-lg border-2 border-dashed border-gray-300 pb-8">
                              <EmptyState
                                icon={(props) => (
                                  <FolderOpenIcon
                                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                                    {...props}
                                  />
                                )}
                                actions={
                                  <Link
                                    to={`${tabs[1].editTo}?new=true`}
                                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    <PlusIcon
                                      className="-ml-1 mr-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                    New Address Details
                                  </Link>
                                }
                                title="No Address Details"
                                description="Add address details to enable auto-population of forms for faster applications."
                              />
                            </div>
                          )}
                        </dl>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel tabIndex={2}>
                      {/* Employee details */}
                      <div className="mx-auto mt-6 max-w-5xl">
                        <dl>
                          {userDoc?.defaultEmployerDetails ? (
                            <EmployerDetails
                              employerDetails={userDoc.defaultEmployerDetails}
                            />
                          ) : (
                            <div className="col-span-2 rounded-lg border-2 border-dashed border-gray-300 pb-8">
                              <EmptyState
                                icon={(props) => (
                                  <FolderOpenIcon
                                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                                    {...props}
                                  />
                                )}
                                actions={
                                  <Link
                                    to={`${tabs[2].editTo}?new=true`}
                                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    <PlusIcon
                                      className="-ml-1 mr-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                    New Employer Details
                                  </Link>
                                }
                                title="No Employer Details"
                                description="Add employer details to enable auto-population of forms for faster applications."
                              />
                            </div>
                          )}
                        </dl>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel tabIndex={3}>
                      {/* Banking details */}
                      <div className="mx-auto mt-6 max-w-5xl">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          {userDoc?.defaultBankDetails ? (
                            <BankingDetails
                              bankingDetails={userDoc.defaultBankDetails}
                            />
                          ) : (
                            <div className="col-span-2 rounded-lg border-2 border-dashed border-gray-300 pb-8">
                              <EmptyState
                                icon={(props) => (
                                  <FolderOpenIcon
                                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                                    {...props}
                                  />
                                )}
                                actions={
                                  <Link
                                    to={`${tabs[3].editTo}?new=true`}
                                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    <PlusIcon
                                      className="-ml-1 mr-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                    New Banking Details
                                  </Link>
                                }
                                title="No Banking Details"
                                description="Add banking details to enable auto-population of forms for faster applications."
                              />
                            </div>
                          )}
                        </dl>
                      </div>
                    </Tab.Panel>
                    <Tab.Panel tabIndex={4}>
                      {/* Affordability */}
                      <div className="mx-auto mt-6 max-w-5xl">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                          {userDoc?.defaultAffordability ? (
                            <AffordabilityDetails
                              affordability={userDoc.defaultAffordability}
                            />
                          ) : (
                            <div className="col-span-2 rounded-lg border-2 border-dashed border-gray-300 pb-8">
                              <EmptyState
                                icon={(props) => (
                                  <FolderOpenIcon
                                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                                    {...props}
                                  />
                                )}
                                actions={
                                  <Link
                                    to={`${tabs[4].editTo}?new=true`}
                                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    <PlusIcon
                                      className="-ml-1 mr-2 h-5 w-5"
                                      aria-hidden="true"
                                    />
                                    New Affordability
                                  </Link>
                                }
                                title="No Affordability"
                                description="Add affordability details to enable auto-population of forms for faster applications."
                              />
                            </div>
                          )}
                        </dl>
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
};

export default UserProfilePage;

export const Head: HeadFC = () => <CustomHead title="User Profile" />;
