import { formatValue } from "react-currency-input-field";
import {
  ApplicationStatus,
  BusinessLoanType,
  ClaimType,
  InsuranceCover,
  SearchCategory,
} from "../enums";

function getMinDiff(startDate: any, endDate: any) {
  const msInMinute = 60 * 1000;

  return Math.round(Math.abs(endDate - startDate) / msInMinute);
}

export const formatTime = (date: Date): string => {
  const now = new Date();
  const isSameDate = date.getDate() === now.getDate();
  const isSameMonth = date.getMonth() === now.getMonth();
  const isSameYear = date.getFullYear() === now.getFullYear();
  if (isSameDate && isSameMonth && isSameYear) {
    const hourDiff = now.getHours() - date.getHours();
    const minutesDiff = getMinDiff(now, date);
    if (hourDiff <= 1 && minutesDiff <= 60) {
      if (minutesDiff < 1) {
        return "1m ago";
      }
      return `${minutesDiff}m ago`;
    } else {
      return `${hourDiff}h ago`;
    }
  } else {
    return date.toLocaleString(undefined, { timeStyle: "short" });
  }
};

export const formatDate = (date: Date): string => {
  const now = new Date();
  const isSameDate = date.getDate() === now.getDate();
  const isYesterdayDate = date.getDate() + 1 === now.getDate();
  const isSameMonth = date.getMonth() === now.getMonth();
  const isSameYear = date.getFullYear() === now.getFullYear();
  if (isSameDate && isSameMonth && isSameYear) {
    return "Today";
  } else if (isYesterdayDate) {
    return "Yesterday";
  } else {
    return date.toLocaleString(undefined, { dateStyle: "long" });
  }
};

export const formatAlgoliaTime = (date?: any): string => {
  const d = date ? new Date(date) : null;
  if (d) return formatTime(d);
  return "No Time";
};

export const formatAlgoliaDate = (date?: any): string => {
  const d = date ? new Date(date) : null;
  if (d) return formatDate(d);
  return "No Date";
};

export const formatPostalCode = (postalCode: number) => {
  const postalCodeStr = postalCode.toString();
  if (postalCodeStr.length === 1) {
    return `000${postalCode}`;
  } else if (postalCodeStr.length === 2) {
    return `00${postalCode}`;
  } else if (postalCodeStr.length === 3) {
    return `0${postalCode}`;
  } else if (postalCodeStr.length === 4) {
    return `${postalCode}`;
  }
};

export const formatAmount = (amount: string): string => {
  return formatValue({
    value: amount.toString() ?? "0",
    intlConfig: { locale: "en-ZA", currency: "ZAR" },
  });
};

export const initFormatAmount = (amount: number): string => {
  const amountStr = amount.toString();
  const { length } = amountStr;
  let formattedValue = "";
  if (length === 1) {
    formattedValue = `0.0${amountStr[0]}`;
  } else if (length === 2) {
    formattedValue = `0.${amountStr.slice(length - 2)}`;
  } else if (length >= 3) {
    formattedValue = `${amountStr.slice(0, length - 2)}.${amountStr.slice(
      length - 2
    )}`;
  }
  return formattedValue;
};

export const formatApplicationStatus = (status: ApplicationStatus): string => {
  switch (status) {
    case ApplicationStatus.all:
      return "All";
    case ApplicationStatus.created:
      return "Created";
    case ApplicationStatus.received:
      return "Received";
    case ApplicationStatus.approved:
      return "Approved";
    case ApplicationStatus.missing:
      return "Missing";
    case ApplicationStatus.processing:
      return "Processing";
    case ApplicationStatus.declined:
      return "Declined";
    case ApplicationStatus.archived:
      return "Archived";

    default:
      throw Error(
        `Unable to format. The application status (${status}) is not supported.`
      );
  }
};

export const formatSearchCategory = (category: SearchCategory): string => {
  switch (category) {
    case SearchCategory.applicant:
      return "Applicant";
    case SearchCategory.business:
      return "Business";
    case SearchCategory.claim:
      return "Claim";
    case SearchCategory.insurance:
      return "Insurance";
    case SearchCategory.personal:
      return "Personal";

    default:
      throw Error(
        `Unable to format. The search category (${category}) is not supported.`
      );
  }
};

export const formatBusinessLoanType = (type: BusinessLoanType): string => {
  switch (type) {
    case BusinessLoanType.invoice_discounting:
      return "Invoice Discounting";
    case BusinessLoanType.project_finance:
      return "Project Finance";
    case BusinessLoanType.purchase_order:
      return "Purchase Order";

    default:
      throw Error(
        `Unable to format. The busines loan type (${type}) is not supported.`
      );
  }
};

export const formatInsuranceCover = (cover: InsuranceCover): string => {
  switch (cover) {
    case InsuranceCover.funeral:
      return "Funeral Insurance";
    case InsuranceCover.life:
      return "Life Insurance";

    default:
      throw Error(
        `Unable to format. The insurance cover (${cover}) is not supported.`
      );
  }
};

export const formatClaimType = (cover: ClaimType): string => {
  switch (cover) {
    case ClaimType.funeral:
      return "Funeral Claim";
    case ClaimType.death:
      return "Death Claim";

    default:
      throw Error(
        `Unable to format. The insurance cover (${cover}) is not supported.`
      );
  }
};
