import React from "react";
import { useLocation } from "@reach/router";

export const useInitialAmountParam = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const amount = params.get("initialAmount");

  return amount;
};
