import React from "react";
import { useLocation } from "@reach/router";
import { ApplicationStatus, SearchCategory } from "../../core/enums";
import { navigate } from "gatsby";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import SimpleDropdown from "../../components/shared/dropdowns/SimpleDropdown";
import {
  formatApplicationStatus,
  formatSearchCategory,
} from "../../core/utils/format";
import UserListItem from "../../components/lists/UserListItem";
import Spinner from "../../components/shared/Spinner";
import BusinessLoanListItem from "../../components/lists/BusinessLoanListItem";
import ClaimListItem from "../../components/lists/ClaimListItem";
import InsuranceListItem from "../../components/lists/InsuranceListItem";
import LoanListItem from "../../components/lists/LoanListItem";
import { useAdvancedSearch } from "../../core/contexts/search";
import { buildStatusLabel } from "../../core/ui/builds";
import Pagination from "../../components/shared/Pagination";

type ApplicationStatusType =
  | "received"
  | "processing"
  | "missing"
  | "approved"
  | "declined";

type SearchCategoryType =
  | "applicant"
  | "business"
  | "personal"
  | "claim"
  | "insurance";

const SearchIndexPage = () => {
  const location = useLocation();
  const [results, search] = useAdvancedSearch();
  const [query, setQuery] = React.useState("");
  const [status, setStatus] = React.useState(ApplicationStatus.all);
  const [category, setCategory] = React.useState(SearchCategory.applicant);
  const [page, setPage] = React.useState(1);
  const [isFetching, setIsFetching] = React.useState(false);

  const onFetchQueryResults = async (q: string, p: number) => {
    setIsFetching(true);
    const statusFilter =
      status != ApplicationStatus.all ? `status:${status}` : "";
    let options = {
      filters: `${statusFilter}`,
      page: p - 1,
      hitsPerPage: results.personalLoansMetadata.perPage,
    };
    await search(q, options);
    setIsFetching(false);
  };

  React.useEffect(() => {
    const search = new URLSearchParams(location.search);

    // Update query from query
    const q = search.get("q");
    if (q) {
      setQuery(q);
    } else {
      setQuery("");
    }

    // Update status from query
    const s = search.get("status") as ApplicationStatusType;
    const sEnum = ApplicationStatus[s];
    if (s) {
      setStatus(sEnum);
    } else {
      setStatus(ApplicationStatus.all);
    }

    // Update category from query
    const c = search.get("category") as SearchCategoryType;
    if (c) {
      setCategory(SearchCategory[c]);
    } else {
      setCategory(SearchCategory.applicant);
    }

    // Update page from query
    const p = search.get("page");
    let pNum = p ? parseInt(p) : null;
    pNum = pNum && pNum !== NaN ? pNum : 1;
    setPage(pNum);

    onFetchQueryResults(q ?? query, pNum);
  }, [location]);

  const buildQuery = (
    q?: string,
    c?: SearchCategory,
    s?: ApplicationStatus,
    p?: number
  ) =>
    `?q=${q ?? query}&category=${c ?? category}&status=${s ?? status}&page=${
      p ?? page
    }`;

  const statuses = [
    {
      label: buildStatusLabel(ApplicationStatus.all),
      onClick: () =>
        navigate(buildQuery(query, category, ApplicationStatus.all, 1)),
    },
    {
      label: buildStatusLabel(ApplicationStatus.created),
      onClick: () =>
        navigate(buildQuery(query, category, ApplicationStatus.created, 1)),
    },
    {
      label: buildStatusLabel(ApplicationStatus.received),
      onClick: () =>
        navigate(buildQuery(query, category, ApplicationStatus.received, 1)),
    },
    {
      label: buildStatusLabel(ApplicationStatus.missing),
      onClick: () =>
        navigate(buildQuery(query, category, ApplicationStatus.missing, 1)),
    },
    {
      label: buildStatusLabel(ApplicationStatus.processing),
      onClick: () =>
        navigate(buildQuery(query, category, ApplicationStatus.processing, 1)),
    },
    {
      label: buildStatusLabel(ApplicationStatus.approved),
      onClick: () =>
        navigate(buildQuery(query, category, ApplicationStatus.approved, 1)),
    },
    {
      label: buildStatusLabel(ApplicationStatus.declined),
      onClick: () =>
        navigate(buildQuery(query, category, ApplicationStatus.declined, 1)),
    },
    {
      label: buildStatusLabel(ApplicationStatus.archived),
      onClick: () =>
        navigate(buildQuery(query, category, ApplicationStatus.archived, 1)),
    },
  ];

  const categories = [
    {
      label: "Applicant",
      onClick: () =>
        navigate(buildQuery(query, SearchCategory.applicant, status, 1)),
    },
    {
      label: "Personal",
      onClick: () =>
        navigate(buildQuery(query, SearchCategory.personal, status, 1)),
    },
    {
      label: "Business",
      onClick: () =>
        navigate(buildQuery(query, SearchCategory.business, status, 1)),
    },
    {
      label: "Claim",
      onClick: () =>
        navigate(buildQuery(query, SearchCategory.claim, status, 1)),
    },
    {
      label: "Insurance",
      onClick: () =>
        navigate(buildQuery(query, SearchCategory.insurance, status, 1)),
    },
  ];

  return (
    <div className="mt-16 px-4 md:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
      <div className="flex flex-col items-center justify-center">
        <p className="mb-4 text-2xl font-bold md:text-3xl lg:text-4xl">
          Advanced Search
        </p>
        <p className="mb-4 max-w-3xl text-center text-base font-medium text-gray-500 md:text-xl">
          Advanced Search allows you to quickly find the results you want, all
          from one location, using simple filters.
        </p>
      </div>
      <div className="mt-4 flex flex-col justify-center gap-y-6">
        <div className="flex items-center justify-between rounded-md border-2 py-4 px-4">
          <p className="">Filters</p>
          <div className="flex items-center gap-4">
            {category !== SearchCategory.applicant && (
              <SimpleDropdown
                label={
                  <div className="overflow-hidden rounded-full shadow-sm">
                    {buildStatusLabel(status)}
                  </div>
                }
                items={statuses}
              />
            )}

            <SimpleDropdown
              label={formatSearchCategory(category)}
              items={categories}
            />
          </div>
        </div>
        <form
          onClick={(e) => {
            e.preventDefault();
            // Run query
            navigate(buildQuery());
            onFetchQueryResults(query, page);
          }}
          className="flex items-center gap-4"
        >
          <input
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            className="h-12 w-full rounded-md border-2 bg-white px-2 text-base text-gray-800 placeholder-gray-400 focus:ring-0"
            placeholder="Search..."
          />

          <button
            type="submit"
            className="inline-flex h-12 w-12 items-center justify-center rounded-full border border-indigo-200 bg-white px-4 text-black shadow-md shadow-sm hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-indigo-800 focus:ring-offset-2"
          >
            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </form>
      </div>

      <div className="mt-8 flex flex-col gap-4">
        {category === SearchCategory.applicant && (
          <React.Fragment>
            {isFetching ? (
              <div className="flex flex-col items-center justify-center gap-y-2 p-4">
                <Spinner textColor="text-indigo-800" />
                <p>Searching...</p>
              </div>
            ) : (
              <React.Fragment>
                {results.users.length > 0 ? (
                  <div className="grid gap-y-8">
                    <div className="mb-8">
                      <Pagination
                        paginationMetadata={results.usersMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                    {results.users.map((user) => (
                      <div
                        key={user.uid}
                        className="rounded-md border-2 transition-colors hover:bg-indigo-100"
                      >
                        <UserListItem
                          to={`/dashboard/applications/applicants/${user.uid}`}
                          user={user}
                          isSearchResult
                        />
                      </div>
                    ))}
                    <div className="mt-8">
                      <Pagination
                        paginationMetadata={results.usersMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="py-2 text-sm text-gray-400">No results</p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {category === SearchCategory.business && (
          <React.Fragment>
            {isFetching ? (
              <div className="flex flex-col items-center justify-center gap-y-2 p-4">
                <Spinner textColor="text-indigo-800" />
                <p>Searching...</p>
              </div>
            ) : (
              <React.Fragment>
                {results.businessLoans.length > 0 ? (
                  <div className="grid gap-y-8">
                    <div className="mb-8">
                      <Pagination
                        paginationMetadata={results.businessLoansMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                    {results.businessLoans.map((businessLoan) => (
                      <div
                        key={businessLoan.id}
                        className="rounded-md border-2 transition-colors hover:bg-indigo-100"
                      >
                        <BusinessLoanListItem
                          to={`/dashboard/applications/business-loans/${businessLoan.id}`}
                          businessLoan={businessLoan}
                          isSearchResult
                        />
                      </div>
                    ))}
                    <div className="mt-8">
                      <Pagination
                        paginationMetadata={results.businessLoansMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="py-2 text-sm text-gray-400">No results</p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {category === SearchCategory.claim && (
          <React.Fragment>
            {isFetching ? (
              <div className="flex flex-col items-center justify-center gap-y-2 p-4">
                <Spinner textColor="text-indigo-800" />
                <p>Searching...</p>
              </div>
            ) : (
              <React.Fragment>
                {results.claims.length > 0 ? (
                  <div className="grid gap-y-8">
                    <div className="mb-8">
                      <Pagination
                        paginationMetadata={results.claimsMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                    {results.claims.map((claim) => (
                      <div
                        key={claim.id}
                        className="rounded-md border-2 transition-colors hover:bg-indigo-100"
                      >
                        <ClaimListItem
                          to={`/dashboard/applications/claims/${claim.id}`}
                          claim={claim}
                          isSearchResult
                        />
                      </div>
                    ))}
                    <div className="mt-8">
                      <Pagination
                        paginationMetadata={results.claimsMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="py-2 text-sm text-gray-400">No results</p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {category === SearchCategory.insurance && (
          <React.Fragment>
            {isFetching ? (
              <div className="flex flex-col items-center justify-center gap-y-2 p-4">
                <Spinner textColor="text-indigo-800" />
                <p>Searching...</p>
              </div>
            ) : (
              <React.Fragment>
                {results.insurances.length > 0 ? (
                  <div className="grid gap-y-8">
                    <div className="mb-8">
                      <Pagination
                        paginationMetadata={results.insurancesMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                    {results.insurances.map((insurance) => (
                      <div
                        key={insurance.id}
                        className="rounded-md border-2 transition-colors hover:bg-indigo-100"
                      >
                        <InsuranceListItem
                          to={`/dashboard/applications/insurances/${insurance.id}`}
                          insurance={insurance}
                          isSearchResult
                        />
                      </div>
                    ))}
                    <div className="mt-8">
                      <Pagination
                        paginationMetadata={results.insurancesMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="py-2 text-sm text-gray-400">No results</p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {category === SearchCategory.personal && (
          <React.Fragment>
            {isFetching ? (
              <div className="flex flex-col items-center justify-center gap-y-2 p-4">
                <Spinner textColor="text-indigo-800" />
                <p>Searching...</p>
              </div>
            ) : (
              <React.Fragment>
                {results.personalLoans.length > 0 ? (
                  <div className="grid gap-y-8">
                    <div className="mb-8">
                      <Pagination
                        paginationMetadata={results.personalLoansMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                    {results.personalLoans.map((personalLoan) => (
                      <div
                        key={personalLoan.id}
                        className="rounded-md border-2 transition-colors hover:bg-indigo-100"
                      >
                        <LoanListItem
                          to={`/dashboard/applications/personal-loans/${personalLoan.id}`}
                          loan={personalLoan}
                          isSearchResult
                        />
                      </div>
                    ))}
                    <div className="mt-8">
                      <Pagination
                        paginationMetadata={results.personalLoansMetadata}
                        category={category}
                        query={query}
                        status={status}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="py-2 text-sm text-gray-400">No results</p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default SearchIndexPage;
