import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "gatsby";
import React from "react";
import { ApplicationStatus, SearchCategory } from "../../core/enums";
import { PaginationMetadata } from "../../core/models/pagination-metadata";
import LinkButton from "./buttons/LinkButton";

export interface PaginationProps {
  paginationMetadata: PaginationMetadata;
  query: string;
  status: ApplicationStatus;
  category: SearchCategory;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { page, perPage, total, totalPages, nextPage, prevPage } =
    props.paginationMetadata;
  let pages = [];
  for (let index = 0; index < totalPages; index++) {
    pages[index] = {
      page: index + 1,
    };
  }

  let firstPart = [];
  let secondPart = [];
  if (pages.length >= 6) {
    firstPart.push(...pages.slice(0, 3));
    secondPart.push(...pages.slice(pages.length - 3));
  } else {
    firstPart.push(...pages);
  }

  const currMin = page > 1 ? (page - 1) * perPage : 1;
  const currMax = page * perPage < total ? page * perPage : total;

  return (
    <div className="flex items-center justify-between py-3">
      <div className="flex flex-1 justify-between sm:hidden">
        <LinkButton isOutline to={``}>
          Previous
        </LinkButton>
        <LinkButton isOutline to={``}>
          Next
        </LinkButton>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{currMin}</span> to{" "}
            <span className="font-medium">{currMax}</span> of{" "}
            <span className="font-medium">{total}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            {prevPage && (
              <Link
                to={`?q=${props.query}&status=${props.status}&category=${props.category}&page=${prevPage}`}
                activeClassName="z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                className="relative z-10 inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-600 focus:z-20"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </Link>
            )}
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {firstPart.map((item) => {
              return (
                <Link
                  key={item.page}
                  to={`?q=${props.query}&status=${props.status}&category=${props.category}&page=${item.page}`}
                  activeClassName="z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                  className="relative z-10 inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-600 focus:z-20"
                >
                  {item.page}
                </Link>
              );
            })}
            {secondPart.length > 0 && (
              <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                ...
              </span>
            )}
            {secondPart.map((item) => {
              return (
                <Link
                  key={item.page}
                  to={`?q=${props.query}&status=${props.status}&category=${props.category}&page=${item.page}`}
                  activeClassName="z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                  className="relative z-10 inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-600 focus:z-20"
                >
                  {item.page}
                </Link>
              );
            })}

            {nextPage && (
              <Link
                to={`?q=${props.query}&status=${props.status}&category=${props.category}&page=${nextPage}`}
                activeClassName="z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                className="relative z-10 inline-flex items-center border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-600 focus:z-20"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </Link>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
