import * as React from "react";
import { navigate } from "gatsby";
import { classNames } from "../../../../core/ui/classNames";
import { Tab } from "@headlessui/react";
import DashboardHero from "../../../../components/shared/DashboardHero";
import { useSettings } from "../../../../core/contexts/firebase/settings";
import ApplicationSettingsDetailsTab from "./applications";
import EmptyState from "../../../../components/shared/EmptyState";
import { FolderOpenIcon } from "@heroicons/react/24/solid";
import ElevatedButton from "../../../../components/shared/buttons/ElevatedButton";
import { FirebaseError } from "firebase/app";
import { DocumentReference, DocumentData } from "firebase/firestore";
import { Id, toast, TypeOptions } from "react-toastify";
import useSettingsFormReducer, {
  SettingsFormActionType,
} from "../../../../core/hooks/useSettingsFormReducer";
import { useFirebaseService } from "../../../../core/contexts/firebase";
import { SettingModel } from "../../../../core/models/setting";

const tabs = [
  {
    name: "Applications",
  },
];

const AdminSettingsPage = () => {
  const firebaseService = useFirebaseService();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [settingsDoc, isLoaded, isLoading] = useSettings();
  const [settings, settingsDispatch] = useSettingsFormReducer();

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  React.useEffect(() => {
    if (settingsDoc && !isLoading) {
      settingsDispatch({
        type: SettingsFormActionType.INITIALISE,
        initialData: settingsDoc,
      });
    }
  }, [settingsDoc, isLoading]);

  React.useEffect(() => {
    const location = window?.location;
    if (location) {
      const params = new URLSearchParams(location.search);
      const tabStr = params.get("tab");
      switch (tabStr) {
        case "applications":
        default:
          setSelectedIndex(0);
          break;
      }
    }
  }, [window?.location.search]);

  // we need to keep a reference of the document reference to prevent the creation of
  // multiple documents. This is needed because the onSaveBusinessLoan function
  // can be called multiple times and the document reference is created in that function.
  const docRef = React.useRef<DocumentReference<DocumentData> | null>(null);

  const onCreateDefaultSettings = async () => {
    if (!docRef.current) {
      docRef.current = firebaseService?.settingDocRef("settings") ?? null;
    }
    const ref = docRef.current;
    if (firebaseService && ref) {
      makeToast("Creating default settings.", toast.TYPE.INFO, false);
      // Create the default settings information in a new settings document
      try {
        // Save the business loan document
        const settingModel: SettingModel = {
          ...settings.currentData,
          id: ref.id,
          createdAt: "",
          updatedAt: "",
        };
        await firebaseService.setSettingDoc(ref.id, settingModel),
          makeToast(
            "Defaults settings have been created successfully.",
            toast.TYPE.SUCCESS
          );

        settingsDispatch({
          type: SettingsFormActionType.INITIALISE,
          initialData: settingModel,
        });
      } catch (error) {
        let message =
          "Something went wrong while creating your default settings, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to submit. ${error.message}`;
          }
          makeToast(message, toast.TYPE.ERROR, false);
          toastId.current = null;
        }
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <React.Fragment>
      <article>
        {/* Settings header */}
        <div>
          <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl">
            <DashboardHero
              description="Maintain control of your Dashboard by setting the correct preferences for the Dashboard and its users."
              pages={[]}
            />
          </div>

          {/* Tabs */}
          <div className="mt-2 2xl:mt-5">
            <div className="border-b border-gray-200">
              <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                {isLoaded ? (
                  <Tab.Group selectedIndex={selectedIndex}>
                    <div className="overflow-hidden overflow-x-auto rounded-md bg-white shadow-md">
                      <Tab.List className="-mb-px flex flex-col justify-center gap-4 py-4 px-4 md:flex-row lg:justify-start">
                        {tabs.map((tab, index) => (
                          <Tab
                            key={tab.name}
                            onClick={() =>
                              navigate(`?tab=${tab.name.toLowerCase()}`)
                            }
                          >
                            {({ selected }: any) => (
                              <span
                                className={classNames(
                                  selected
                                    ? "border-indigo-500 text-gray-900"
                                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                  "text-medium whitespace-nowrap border-b-2 py-2 px-1 font-medium"
                                )}
                              >
                                {tab.name}
                              </span>
                            )}
                          </Tab>
                        ))}
                      </Tab.List>
                    </div>
                    <Tab.Panels className="py-12">
                      <Tab.Panel tabIndex={0}>
                        <ApplicationSettingsDetailsTab docRef={docRef} />
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                ) : (
                  <div className="my-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
                    <EmptyState
                      icon={(props) => (
                        <FolderOpenIcon
                          className="mx-auto my-8 h-12 w-12 text-gray-400"
                          {...props}
                        />
                      )}
                      actions={
                        <ElevatedButton onClick={onCreateDefaultSettings}>
                          Create Default Settings
                        </ElevatedButton>
                      }
                      title="No Settings"
                      description="Oops! The Dashboard has either lost it's settings or they have been removed. This should not happen. Please contact support or create default settings."
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
};

export default AdminSettingsPage;
