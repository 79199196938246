import * as React from "react";

export interface TextareaFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange: (name: string) => void;
}

const TextareaField = ({
  name,
  placeholder,
  label,
  required = true,
  onChange,
}: TextareaFieldProps) => {
  const id = label ? label.toLowerCase().replaceAll(" ", "-") : "name";
  return (
    <div className="space-y-1">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label ?? "Name"}
      </label>
      <textarea
        id={id}
        name={id}
        value={name}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      />
    </div>
  );
};

export default TextareaField;
