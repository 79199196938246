import React from "react";
import { AffordabilityModel } from "../../core/models/affordability";
import { formatAmount } from "../../core/utils/format";

export interface AffordabilityDetailsProps {
  affordability: AffordabilityModel;
}

const AffordabilityDetails: React.FC<AffordabilityDetailsProps> = ({
  affordability,
}) => {
  return (
    <div className="space-y-8 divide-y divide-gray-200">
      <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <React.Fragment>
          <p className="col-span-1 text-xl text-gray-500 sm:col-span-2">
            Monthly incomes
          </p>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Primary Income
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.primaryIncome)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Other Income</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.otherIncome)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Bonus Income</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.bonusIncome)}
            </dd>
          </div>
        </React.Fragment>
      </div>
      <div className="grid grid-cols-1 gap-x-4 gap-y-8 pt-8 sm:grid-cols-2">
        <React.Fragment>
          <p className="col-span-1 text-xl text-gray-500 sm:col-span-2">
            Monthly expenses
          </p>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Accomodation</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.accomodation)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Education</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.education)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Food</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.food)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Transport</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.transport)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Insurance</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.insurance)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Maintenance</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.maintenance)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Medical</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.medical)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Unemployment Insurance Fund (UIF)
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.uif)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Loan Repayment
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.loanRepayment)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Credit Card Repayment
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.creditCardRepayment)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Income Tax</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.incomeTax)}
            </dd>
          </div>
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Water and Electricity
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatAmount(affordability.waterAndElectricity)}
            </dd>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default AffordabilityDetails;
