import { Tab } from "@headlessui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { FirebaseError } from "firebase/app";
import { DocumentData, DocumentReference } from "firebase/firestore";
import { navigate } from "gatsby";
import * as React from "react";
import { Id, toast, TypeOptions } from "react-toastify";
import InsuranceDetails from "../../../components/details/InsuranceDetails";
import CheckField from "../../../components/fields/CheckField";
import InsuranceForm from "../../../components/forms/InsuranceForm";
import {
  useAuthUser,
  useAuthUserDoc,
  useFirebaseService,
} from "../../../core/contexts/firebase";
import { useInsurances } from "../../../core/contexts/firebase/insurances";
import { ApplicationStatus } from "../../../core/enums";
import useInsuranceFormReducer, {
  InsuranceFormActionType,
} from "../../../core/hooks/useInsuranceFormReducer";
import useInsuranceSubmissionReducer, {
  InsuranceSubmissionActionType,
} from "../../../core/hooks/useInsuranceSubmissionReducer";
import { InsuranceModel } from "../../../core/models/insurance";
import { StatusModel } from "../../../core/models/status";

const steps = [
  { id: 1, name: "Insurance cover" },
  { id: 2, name: "Review" },
];

const InsuranceApplicationPage = () => {
  const user = useAuthUser();
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [i1, i2, i3, getNewInsurance] = useInsurances();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [reviewed, setReviewed] = React.useState(false);

  // Initialise state management for forms
  const [submitter, submitterDispatch] = useInsuranceSubmissionReducer();
  const [insurance, insuranceDispatch] = useInsuranceFormReducer();

  // Derived change state
  const hasInsuranceChanged = insurance.hasChanged;
  const hasFormChanged = hasInsuranceChanged;

  // we need to keep a reference of the document reference to prevent the creation of
  // multiple documents. This is needed because the onSaveBusinessLoan function
  // can be called multiple times and the document reference is created in that function.
  const docRef = React.useRef<DocumentReference<DocumentData> | null>(null);

  React.useEffect(() => {
    if (user && firebaseService && docRef.current == null) {
      docRef.current = firebaseService.insuranceDocRef(user.uid);
    }
  }, [user, firebaseService, docRef.current]);

  // Handle field changes through state management mechanism
  const onSubmitterFieldChanged = (fieldKey: string, fieldValue: any) =>
    submitterDispatch({
      type: InsuranceSubmissionActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onInsuranceFieldChanged = (fieldKey: string, fieldValue: any) =>
    insuranceDispatch({
      type: InsuranceFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  const onSaveInsurance = async () => {
    const ref = docRef.current;
    if (userDoc && firebaseService && ref) {
      onSubmitterFieldChanged("isSubmitting", true);
      // Save the user's insurance information in a new insurance document
      const { uid } = userDoc;
      try {
        makeToast("Submitting insurance application.", toast.TYPE.INFO, false);
        // Save the insurance document
        const user = {
          uid: userDoc.uid,
          name: userDoc.name,
          surname: userDoc.surname,
          email: userDoc.email,
          cell: userDoc.cell,
        };
        const insuranceModel: InsuranceModel = {
          ...insurance.currentData,
          id: ref.id,
          status: ApplicationStatus.created,
          user,
          createdAt: "",
          updatedAt: "",
        };
        const status: StatusModel = {
          status: ApplicationStatus.created,
          user,
          id: "",
          applicationId: ref.id,
          createdAt: "",
          updatedAt: "",
        };
        await Promise.all([
          firebaseService.setInsuranceDoc(uid, insuranceModel, ref),
          firebaseService.setInsuranceStatusDoc(uid, ref.id, status),
        ]);
        getNewInsurance();
        makeToast(
          "Your insurance application has been submitted successfully.",
          toast.TYPE.SUCCESS
        );
        onSubmitterFieldChanged("isSubmitting", false);
        onSubmitterFieldChanged("isSubmitted", true);
        navigate("/dashboard/applications?tab=insurances");
      } catch (error) {
        onSubmitterFieldChanged("isSubmitting", false);
        let message =
          "Something went wrong while submitting your insurance application, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to submit. ${error.message}`;
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  const getStepperContainerClassNames = (stepIndex: number) => {
    let classNames =
      "flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0";
    if (stepIndex > selectedIndex) {
      // Incomplete
      classNames = `${classNames} border-gray-200 group`;
    } else if (stepIndex === selectedIndex) {
      // Current
      classNames = `${classNames} border-indigo-600 hover:border-gray-300`;
    } else if (stepIndex < selectedIndex) {
      // Complete
      classNames = `${classNames} border-indigo-600 group hover:border-indigo-800`;
    }
    return classNames;
  };

  const getStepperIdClassNames = (stepIndex: number) => {
    let classNames = "text-sm font-medium";
    if (stepIndex > selectedIndex) {
      // Incomplete
      classNames = `${classNames} text-gray-500 group-hover:text-gray-700`;
    } else if (stepIndex === selectedIndex) {
      // Current
      classNames = `${classNames} text-indigo-600`;
    } else if (stepIndex < selectedIndex) {
      // Complete
      classNames = `${classNames} text-indigo-600 group hover:text-indigo-800`;
    }
    return classNames;
  };

  const goToPreviousTab = () => setSelectedIndex(selectedIndex - 1);
  const goToNextTab = () => setSelectedIndex(selectedIndex + 1);

  return (
    <React.Fragment>
      <div className="mx-auto mt-16 px-4 sm:px-6 lg:max-w-6xl lg:px-8">
        <div className="flex items-center justify-between gap-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900 lg:text-2xl">
            New Insurance
          </h3>
          <div className="flex gap-4">
            <div
              className={`text-sm transition-opacity duration-300 ${
                selectedIndex != 0 ? "opacity-1" : "opacity-0"
              }`}
            >
              <button
                onClick={goToPreviousTab}
                disabled={selectedIndex === 0}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              >
                &larr;&nbsp;Previous Step
              </button>
            </div>
            <button
              type="button"
              onClick={() => {
                if (hasFormChanged) {
                  // TODO: Ask user if they are sure because there are unsaved changes
                  navigate(-1);
                } else {
                  navigate(-1);
                }
              }}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
            >
              <XCircleIcon className="-ml-1 mr-2 h-5 w-5 text-gray-600" />
              Cancel
            </button>
          </div>
        </div>
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="mt-12 space-y-4 rounded-md bg-white px-4 py-4 shadow-md md:flex md:space-y-0 md:space-x-8 md:px-6 lg:px-8">
            {steps.map((step) => (
              <Tab
                key={step.id}
                className={getStepperContainerClassNames(step.id - 1)}
              >
                <span className={getStepperIdClassNames(step.id - 1)}>
                  Step {step.id}
                </span>
                <span className="text-start text-sm font-medium">
                  {step.name}
                </span>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel tabIndex={0}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please enter the most appropriate insurance cover for you.
              </p>
              <InsuranceForm
                label="Next"
                insurance={insurance.currentData}
                onFieldChanged={onInsuranceFieldChanged}
                onButtonClick={(isValid) => isValid && goToNextTab()}
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={1}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Review your insurance cover before submitting your insurance
                application.
              </p>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                1. Insurance cover
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <InsuranceDetails insurance={insurance.currentData} />
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                2. Review & Submit
              </p>
              <p className="col-span-2 mt-4">
                <CheckField
                  id="insurance-application-user-reviewed"
                  checked={reviewed}
                  description={
                    'By clicking "Submit Application" you agree that the insurance cover is correct.'
                  }
                  onChange={setReviewed}
                />
              </p>
              <button
                type={"button"}
                disabled={submitter.isSubmitting}
                onClick={() => {
                  if (reviewed) {
                    // Submit document
                    onSaveInsurance();
                  } else {
                    toast.warn(
                      "Please ensure that you have reviewed the application and checked the relevant checkbox"
                    );
                  }
                }}
                className="col-span-1 mt-12 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:col-span-2 lg:col-span-3"
              >
                Submit Application
              </button>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </React.Fragment>
  );
};

export default InsuranceApplicationPage;
