import { FirebaseError } from "firebase/app";
import {
  uploadBytesResumable,
  getDownloadURL,
  StorageReference,
  UploadTask,
} from "firebase/storage";
import { UploadErrorCode } from "../enums";
import Firebase from "../services/firebase";

// Custom error to aid the UI when surfacing upload errors to the user.
export class UploadError {
  message: string;
  name: string;
  code: UploadErrorCode;
  storageRef: StorageReference;
  serverResponse?: string;

  constructor(
    message: string,
    name: string,
    code: UploadErrorCode,
    storageRef: StorageReference,
    serverResponse?: string
  ) {
    this.message = message;
    this.name = name;
    this.code = code;
    this.storageRef = storageRef;
    this.serverResponse = serverResponse;
  }
}

export const handleUpload = (
  path: string,
  name: string,
  file: File,
  firebaseService: Firebase,
  onPercentChanged: (percent: number) => void,
  onDownloadUrlChanged: (url: string) => void
): [Promise<string>, UploadTask] => {
  const storageRef = firebaseService.storageRef(path);
  const uploadTask = uploadBytesResumable(storageRef, file!);

  let promise = new Promise<string>(function (resolve, reject) {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // update progress
        onPercentChanged(percent);
      },
      (err) => {
        const message = `failed to upload`;
        const uploadError = new UploadError(
          message,
          name,
          UploadErrorCode.failed_to_upload,
          storageRef,
          err.message
        );
        reject(uploadError);
      },
      async () => {
        // download url
        try {
          const url = await getDownloadURL(storageRef);
          onDownloadUrlChanged(url);
          resolve(url);
        } catch (error) {
          const message = `failed to get download url`;
          let serverResponse: string | undefined;
          if (error instanceof FirebaseError) {
            serverResponse = error.message;
          }
          const uploadError = new UploadError(
            message,
            name,
            UploadErrorCode.failed_to_get_download_url,
            storageRef,
            serverResponse
          );
          reject(uploadError);
        }
      }
    );
  });

  return [promise, uploadTask];
};
