import React, { Reducer } from "react";
import { ApplicationTypeSettingModel, SettingModel } from "../models/setting";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum SettingsFormActionType {
  INITIALISE,
  CHANGE_FIELD,
}

export interface SettingsFormAction {
  type: SettingsFormActionType;
  initialData?: SettingModel;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface SettingsFormState {
  currentData: SettingModel;
  initialData: SettingModel;
  hasChanged: boolean;
}

const initialStateForApplicationTypeSetting: ApplicationTypeSettingModel = {
  disabled: false,
  disabledMessage: "",
};

const initialData: SettingModel = {
  id: "",
  applications: {
    personalLoans: initialStateForApplicationTypeSetting,
    businessLoans: initialStateForApplicationTypeSetting,
    claims: initialStateForApplicationTypeSetting,
    insurances: initialStateForApplicationTypeSetting,
  },
  createdAt: "",
  updatedAt: "",
};

const initialState: SettingsFormState = {
  initialData,
  currentData: initialData,
  hasChanged: false,
};

const reducer = (state = initialState, action: SettingsFormAction) => {
  const { type, payload, initialData } = action;
  switch (type) {
    case SettingsFormActionType.INITIALISE:
      if (initialData) {
        return {
          initialData,
          currentData: initialData,
          hasChanged: false,
        };
      } else {
        throw Error(
          `initial data is invalid for action type: ${type.toString()}`
        );
      }
    case SettingsFormActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField(
          { ...state.currentData },
          fieldKey,
          fieldValue
        );
        return {
          ...state,
          currentData,
          hasChanged: haveFieldsChanged(state.initialData, currentData),
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

// Returns true if the current data fields are not equal to the initial data fields.
const haveFieldsChanged = (
  initialData: SettingModel,
  currentData: SettingModel
) => {
  const { personalLoans, businessLoans, claims, insurances } =
    initialData?.applications;
  const hasApplicationsPersonalLoansChanged =
    personalLoans.disabled !== currentData?.applications.personalLoans.disabled;
  const hasApplicationsBusinessLoansChanged =
    businessLoans.disabled !== currentData?.applications.businessLoans.disabled;
  const hasApplicationsClaimsChanged =
    claims.disabled !== currentData?.applications.claims.disabled;
  const hasApplicationsInsurancesChanged =
    insurances.disabled !== currentData?.applications.insurances.disabled;
  return (
    hasApplicationsPersonalLoansChanged ||
    hasApplicationsBusinessLoansChanged ||
    hasApplicationsClaimsChanged ||
    hasApplicationsInsurancesChanged
  );
};

const useSettingsFormReducer = (): [
  SettingsFormState,
  React.Dispatch<SettingsFormAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useSettingsFormReducer;
