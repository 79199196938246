import React from "react";
import { ApplicationStatus } from "../../core/enums";

export const getStatusColor = (status: ApplicationStatus): string => {
  switch (status) {
    case ApplicationStatus.all:
      return "bg-purple-200";
    case ApplicationStatus.created:
      return "bg-pink-200";
    case ApplicationStatus.received:
      return "bg-blue-200";
    case ApplicationStatus.approved:
      return "bg-green-200";
    case ApplicationStatus.missing:
      return "bg-orange-200";
    case ApplicationStatus.processing:
      return "bg-yellow-200";
    case ApplicationStatus.declined:
      return "bg-red-200";
    case ApplicationStatus.archived:
      return "bg-stone-200";

    default:
      throw Error(
        `Unable to get status color. The application status (${status}) is not supported.`
      );
  }
};

export interface ApplicationStatusProps {
  status: ApplicationStatus;
  children?: React.ReactNode;
}

const ApplicationStatusBadge: React.FC<ApplicationStatusProps> = (props) => {
  const { status } = props;
  return <div className={`${getStatusColor(status)}`}>{props.children}</div>;
};

export default ApplicationStatusBadge;
