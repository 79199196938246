import React, { Reducer } from "react";
import { AffordabilityModel } from "../models/affordability";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum AffordabilityFormActionType {
  INITIALISE,
  CHANGE_FIELD,
}

export interface AffordabilityFormAction {
  type: AffordabilityFormActionType;
  initialData?: AffordabilityModel;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface AffordabilityFormState {
  currentData: AffordabilityModel;
  initialData: AffordabilityModel;
  hasChanged: boolean;
}

const initialData: AffordabilityModel = {
  accomodation: "0",
  education: "0",
  food: "0",
  bonusIncome: "0",
  insurance: "0",
  medical: "0",
  uif: "0",
  creditCardRepayment: "0",
  incomeTax: "0",
  loanRepayment: "0",
  maintenance: "0",
  transport: "0",
  otherIncome: "0",
  primaryIncome: "0",
  waterAndElectricity: "0",
};

const initialState: AffordabilityFormState = {
  initialData,
  currentData: initialData,
  hasChanged: false,
};

const reducer = (state = initialState, action: AffordabilityFormAction) => {
  const { type, payload, initialData } = action;
  switch (type) {
    case AffordabilityFormActionType.INITIALISE:
      if (initialData) {
        return {
          initialData,
          currentData: initialData,
          hasChanged: false,
        };
      } else {
        throw Error(
          `initialState is invalid for action type: ${type.toString()}`
        );
      }
    case AffordabilityFormActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField(
          { ...state.currentData },
          fieldKey,
          fieldValue
        );
        return {
          ...state,
          currentData,
          hasChanged: haveFieldsChanged(state.initialData, currentData),
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

// Returns true if the current data fields are not equal to the initial data fields.
const haveFieldsChanged = (
  initialData: AffordabilityModel,
  currentData: AffordabilityModel
) => {
  const hasAccomodationChanged =
    initialData.accomodation !== currentData.accomodation;
  const hasEducationChanged = initialData.education !== currentData.education;
  const hasFoodChanged = initialData.food !== currentData.food;
  const hasBonusIncomeChanged =
    initialData.bonusIncome !== currentData.bonusIncome;
  const hasInsuranceChanged = initialData.insurance !== currentData.insurance;
  const hasMedicalChanged = initialData.medical !== currentData.medical;
  const hasUIFChanged = initialData.uif !== currentData.uif;
  const hasCreditCardRepaymentChanged =
    initialData.creditCardRepayment !== currentData.creditCardRepayment;
  const hasIncomeTaxChanged = initialData.incomeTax !== currentData.incomeTax;
  const hasLoanRepaymentChanged =
    initialData.loanRepayment !== currentData.loanRepayment;
  const hasMaintenanceChanged =
    initialData.maintenance !== currentData.maintenance;
  const hasTransportChanged = initialData.transport !== currentData.transport;
  const hasOtherIncomeChanged =
    initialData.otherIncome !== currentData.otherIncome;
  const hasPrimaryIncomeChanged =
    initialData.primaryIncome !== currentData.primaryIncome;
  const hasWaterAndElectricityChanged =
    initialData.waterAndElectricity !== currentData.waterAndElectricity;
  return (
    hasAccomodationChanged ||
    hasEducationChanged ||
    hasFoodChanged ||
    hasBonusIncomeChanged ||
    hasInsuranceChanged ||
    hasMedicalChanged ||
    hasUIFChanged ||
    hasCreditCardRepaymentChanged ||
    hasIncomeTaxChanged ||
    hasLoanRepaymentChanged ||
    hasMaintenanceChanged ||
    hasTransportChanged ||
    hasOtherIncomeChanged ||
    hasPrimaryIncomeChanged ||
    hasWaterAndElectricityChanged
  );
};

const useAffordabilityFormReducer = (): [
  AffordabilityFormState,
  React.Dispatch<AffordabilityFormAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useAffordabilityFormReducer;
