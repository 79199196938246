import React from "react";
import { ClaimModel } from "../../core/models/claim";
import { formatClaimType } from "../../core/utils/format";

export interface ClaimDetailsProps {
  claim: ClaimModel;
}

const ClaimDetails: React.FC<ClaimDetailsProps> = ({ claim }) => {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Claim type</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {formatClaimType(claim.type)}
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          ID of policy holder
        </dt>
        <dd className="mt-1 text-sm text-gray-900">{claim.idNumber}</dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Policy or card number
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          {claim.policyOrCardNumber ?? "Not specified"}
        </dd>
      </div>
    </dl>
  );
};

export default ClaimDetails;
