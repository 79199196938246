import React from "react";
import { useAuthUser } from "../../core/contexts/firebase";

export interface UserAvatarProps {
  size?: "xsmall" | "small" | "medium" | "large";
}

const UserAvatar: React.FC<UserAvatarProps> = ({ size = "small" }) => {
  const user = useAuthUser();
  const userExists = user != null;
  const photoURLExists = userExists && user.photoURL;

  let classNames: string = "";
  if (size === "medium") {
    classNames = "h-20 w-20 text-2xl";
  } else if (size === "large") {
    classNames = "h-24 w-24 text-2xl";
  } else if (size === "xsmall") {
    classNames = "h-8 w-8 text-sm";
  } else {
    classNames = "h-16 w-16 text-sm";
  }

  return userExists ? (
    photoURLExists ? (
      <img
        className={`${classNames} inline-block rounded-full`}
        src={user.photoURL}
        alt=""
      />
    ) : (
      <span
        className={`inline-flex ${classNames} items-center justify-center rounded-full bg-indigo-800 font-medium leading-none text-white`}
      >
        {user.displayName
          ?.split(" ")
          .reduce((p, c) => `${p.slice(0, 1)}${c.slice(0, 1)}`)
          .toUpperCase()}
      </span>
    )
  ) : (
    <span
      className={`inline-flex ${classNames} items-center justify-center rounded-full font-medium leading-none text-white`}
    >
      ?
    </span>
  );
};

export default UserAvatar;
