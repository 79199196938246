import {
  collection,
  collectionGroup,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import * as React from "react";
import { StatusModel } from "../../../models/status";
import { PaginationMetadata } from "../../../models/pagination-metadata";
import { useAuthUser, useAuthIsConnected, useFirebaseService } from "..";

type ContextState = {
  insuranceStatuses: StatusModel[];
  paginationMetadataForInsurances: PaginationMetadata;
  getNextInsuranceStatuses: (
    id: string,
    userOnly: boolean,
    refresh: boolean
  ) => Promise<void>;
  getNewInsuranceStatus: () => Promise<void>;
};

const initialState: ContextState = {
  insuranceStatuses: [],
  paginationMetadataForInsurances: {
    hitsPerPage: 25,
    hasNext: true,
  },
  getNextInsuranceStatuses: () => Promise.resolve(),
  getNewInsuranceStatus: () => Promise.resolve(),
};

export const InsuranceStatusesContext =
  React.createContext<ContextState>(initialState);

export type InsuranceStatusesContextProviderProps = {
  children: React.ReactNode;
};

export const InsuranceStatusesContextProvider = ({
  children,
}: InsuranceStatusesContextProviderProps) => {
  const user = useAuthUser();
  const isConnected = useAuthIsConnected();
  const firebase = useFirebaseService();
  const [lastVisible, setLastVisible] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [insuranceId, setInsuranceId] = React.useState("");
  const [insuranceStatuses, setInsuranceStatuses] = React.useState<
    StatusModel[]
  >(initialState.insuranceStatuses);
  const [paginationMetadataForInsurances, setPaginationMetadata] =
    React.useState<PaginationMetadata>(
      initialState.paginationMetadataForInsurances
    );

  const getNextInsuranceStatuses = async (
    id: string,
    userOnly: boolean,
    refresh: boolean
  ): Promise<void> => {
    const { hitsPerPage: pageLimit, hasNext } = paginationMetadataForInsurances;
    if (!refresh && !hasNext) return;
    if (isConnected && user && firebase) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      const wrapWithQuery = () =>
        (ref = query(ref, orderBy("createdAt", "desc"), limit(pageLimit)));
      if (userOnly) {
        ref = collection(
          firebase.db,
          "users",
          user.uid,
          "insurances",
          id,
          "statuses"
        );
        wrapWithQuery();
        if (!refresh && lastVisible) ref = query(ref, startAfter(lastVisible));
      } else {
        ref = collectionGroup(firebase.db, "statuses");
        wrapWithQuery();
        ref = query(ref, where("applicationId", "==", id));
        if (!refresh && lastVisible) ref = query(ref, startAfter(lastVisible));
      }
      const data = await getDocs(ref);

      const nextBatch = data.docs.map((doc) => doc.data() as StatusModel);
      const nextBatchLast = nextBatch[nextBatch.length - 1];
      const last = insuranceStatuses[insuranceStatuses.length - 1];
      if (refresh) {
        setInsuranceId(id);
        setInsuranceStatuses([...nextBatch]);
        setLastVisible(data.docs[data.docs.length - 1]);
        setPaginationMetadata({
          ...paginationMetadataForInsurances,
          hasNext: data.size === pageLimit,
        });
      } else if (nextBatchLast !== last) {
        setInsuranceId(id);
        setInsuranceStatuses([...insuranceStatuses, ...nextBatch]);
        setLastVisible(data.docs[data.docs.length - 1]);
        setPaginationMetadata({
          ...paginationMetadataForInsurances,
          hasNext: data.size === pageLimit,
        });
      }
    } else {
      return Promise.reject("firebase service is undefined");
    }
  };

  const getNewInsuranceStatus = async () => {
    if (isConnected && firebase && user) {
      const ref = collection(
        firebase.db,
        "users",
        user.uid,
        "insurances",
        insuranceId,
        "statuses"
      );
      const q = query(ref, orderBy("createdAt", "desc"), limit(1));
      const data = await getDocs(q);
      if (data.size === 1) {
        const oldStatus = insuranceStatuses[0];
        const newStatus = data.docs[0].data() as StatusModel;
        if (newStatus.id !== oldStatus?.id) {
          setInsuranceStatuses([newStatus, ...insuranceStatuses]);
        }
      }
    }
  };

  return (
    <InsuranceStatusesContext.Provider
      value={{
        insuranceStatuses,
        paginationMetadataForInsurances,
        getNextInsuranceStatuses,
        getNewInsuranceStatus,
      }}
    >
      {children}
    </InsuranceStatusesContext.Provider>
  );
};

export const useInsuranceStatuses = (): [
  StatusModel[],
  PaginationMetadata,
  (id: string, userOnly: boolean, refresh: boolean) => Promise<void>,
  () => Promise<void>
] => {
  const context = React.useContext(InsuranceStatusesContext);
  if (context === undefined) {
    throw new Error(
      "useInsuranceStatuses must be used within a InsuranceStatusesProvider"
    );
  }
  return [
    context.insuranceStatuses,
    context.paginationMetadataForInsurances,
    context.getNextInsuranceStatuses,
    context.getNewInsuranceStatus,
  ];
};
