import {
  collection,
  collectionGroup,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import * as React from "react";
import { useAuthIsConnected, useAuthUser, useFirebaseService } from ".";
import { ClaimModel } from "../../models/claim";
import { PaginationMetadata } from "../../models/pagination-metadata";

type ContextState = {
  claims: ClaimModel[];
  allClaims: ClaimModel[];
  detailClaim?: ClaimModel;
  paginationMetadata: PaginationMetadata;
  paginationMetadataForAll: PaginationMetadata;
  getNextClaims: (userOnly: boolean) => Promise<void>;
  getNewClaim: () => Promise<void>;
  getClaimById: (id: string, userOnly: boolean) => Promise<void>;
};

const initialState: ContextState = {
  claims: [],
  allClaims: [],
  paginationMetadata: {
    hitsPerPage: 25,
    hasNext: true,
  },
  paginationMetadataForAll: {
    hitsPerPage: 25,
    hasNext: true,
  },
  getNextClaims: () => Promise.resolve(),
  getNewClaim: () => Promise.resolve(),
  getClaimById: (id: string, userOnly: boolean) => Promise.resolve(),
};

export const ClaimsContext = React.createContext<ContextState>(initialState);

type ClaimsContextProviderProps = {
  children: React.ReactNode;
};

const ClaimsContextProvider = ({ children }: ClaimsContextProviderProps) => {
  const user = useAuthUser();
  const isConnected = useAuthIsConnected();
  const firebase = useFirebaseService();
  const [lastVisible, setLastVisible] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [lastVisibleForAll, setLastVisibleForAll] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [claims, setClaims] = React.useState<ClaimModel[]>(initialState.claims);
  const [allClaims, setAllClaims] = React.useState<ClaimModel[]>(
    initialState.allClaims
  );
  const [paginationMetadata, setPaginationMetadata] =
    React.useState<PaginationMetadata>(initialState.paginationMetadata);
  const [paginationMetadataForAll, setPaginationMetadataForAll] =
    React.useState<PaginationMetadata>(initialState.paginationMetadataForAll);
  const [detailClaim, setDetailClaim] = React.useState<ClaimModel | undefined>(
    initialState.detailClaim
  );

  // This effect updates the other cached lists of insurances with the latest
  // of the detail insurance document.
  React.useEffect(() => {
    if (detailClaim != undefined) {
      const userIns = claims.find(
        (insurance) => insurance.id === detailClaim.id
      );
      const allIns = allClaims.find(
        (insurance) => insurance.id === detailClaim.id
      );
      if (userIns) {
        const i = claims.indexOf(userIns);
        const update = Array.from(claims);
        update[i] = detailClaim;
        setClaims(update);
        setLastVisible(undefined);
      }
      if (allIns) {
        const i = allClaims.indexOf(allIns);
        const update = Array.from(allClaims);
        update[i] = detailClaim;
        setAllClaims(update);
        setLastVisibleForAll(undefined);
      }
    }
  }, [detailClaim]);

  const getNextClaims = async (userOnly: boolean): Promise<void> => {
    const { hitsPerPage: pageLimit, hasNext } = userOnly
      ? paginationMetadata
      : paginationMetadataForAll;
    if (isConnected && user && firebase) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      const wrapWithQuery = () =>
        (ref = query(ref, orderBy("createdAt", "desc"), limit(pageLimit)));
      if (userOnly) {
        ref = collection(firebase.db, "users", user.uid, "claims");
        wrapWithQuery();
        if (lastVisible) ref = query(ref, startAfter(lastVisible));
      } else {
        ref = collectionGroup(firebase.db, "claims");
        wrapWithQuery();
        if (lastVisibleForAll) ref = query(ref, startAfter(lastVisibleForAll));
      }
      const data = await getDocs(ref);

      const nextBatch = data.docs.map((doc) => doc.data() as ClaimModel);
      const nextBatchLast = nextBatch[nextBatch.length - 1];
      if (userOnly) {
        const last = claims[claims.length - 1];
        if (nextBatchLast?.id !== last?.id) {
          setClaims([...claims, ...nextBatch]);
          setLastVisible(data.docs[data.docs.length - 1]);
          setPaginationMetadata({
            ...paginationMetadata,
            hasNext: data.size === pageLimit,
          });
        }
      } else {
        const last = allClaims[allClaims.length - 1];
        if (nextBatchLast?.id !== last?.id) {
          setAllClaims([...allClaims, ...nextBatch]);
          setLastVisibleForAll(data.docs[data.docs.length - 1]);
          setPaginationMetadataForAll({
            ...paginationMetadataForAll,
            hasNext: data.size === pageLimit,
          });
        }
      }
    } else {
      return Promise.reject("firebase service is undefined");
    }
  };

  const getClaimById = async (id: string, userOnly: boolean): Promise<void> => {
    const c = claims.find((claim) => claim.id === id);
    if (c) {
      setDetailClaim(c);
    } else if (isConnected && firebase && user) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      if (userOnly) {
        const claimDoc = await firebase.businessLoanDoc(user.uid, id);
        setDetailClaim(claimDoc.data() as ClaimModel);
      } else {
        ref = collectionGroup(firebase.db, "claims");
        ref = query(ref, where("id", "==", id));
        const { docs } = await getDocs(ref);
        if (docs.length === 1) {
          setDetailClaim(docs[0].data() as ClaimModel);
        }
      }
    }
  };

  const getNewClaim = async () => {
    if (isConnected && firebase && user) {
      const ref = collection(firebase.db, "users", user.uid, "claims");
      const q = query(ref, orderBy("createdAt", "desc"), limit(1));
      const data = await getDocs(q);
      if (data.size === 1) {
        const oldClaim = claims[0];
        const newClaim = data.docs[0].data() as ClaimModel;
        if (newClaim.id !== oldClaim?.id) {
          setClaims([newClaim, ...claims]);
        }
      }
    }
  };

  return (
    <ClaimsContext.Provider
      value={{
        claims,
        allClaims,
        detailClaim,
        paginationMetadata,
        paginationMetadataForAll,
        getNextClaims,
        getNewClaim,
        getClaimById,
      }}
    >
      {children}
    </ClaimsContext.Provider>
  );
};

const useClaims = (): [
  ClaimModel[],
  PaginationMetadata,
  (userOnly: boolean) => Promise<void>,
  () => Promise<void>
] => {
  const context = React.useContext(ClaimsContext);
  if (context === undefined) {
    throw new Error("useClaims must be used within a ClaimsProvider");
  }
  return [
    context.claims,
    context.paginationMetadata,
    context.getNextClaims,
    context.getNewClaim,
  ];
};

const useAllClaims = (): [
  ClaimModel[],
  PaginationMetadata,
  (userOnly: boolean) => Promise<void>
] => {
  const context = React.useContext(ClaimsContext);
  if (context === undefined) {
    throw new Error("useAllClaims must be used within a ClaimsProvider");
  }
  return [
    context.allClaims,
    context.paginationMetadataForAll,
    context.getNextClaims,
  ];
};

const useClaimById = (): [
  ClaimModel | undefined,
  (id: string, userOnly: boolean) => Promise<void>
] => {
  const context = React.useContext(ClaimsContext);
  if (context === undefined) {
    throw new Error("useClaimById must be used within a ClaimsProvider");
  }
  return [context.detailClaim, context.getClaimById];
};

export { ClaimsContextProvider, useClaims, useAllClaims, useClaimById };
