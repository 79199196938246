import * as React from "react";
import { useLocation } from "@reach/router";
import InsuranceListItem from "../../../../components/lists/InsuranceListItem";
import useInsuranceFormReducer, {
  InsuranceFormActionType,
} from "../../../../core/hooks/useInsuranceFormReducer";
import { useInsuranceById } from "../../../../core/contexts/firebase/insurances";
import {
  useAuthUserDoc,
  useFirebaseService,
} from "../../../../core/contexts/firebase";
import LoadingState from "../../../../components/shared/LoadingState";
import Spinner from "../../../../components/shared/Spinner";
import { useInsuranceStatuses } from "../../../../core/contexts/firebase/statuses/insurances";
import { ApplicationStatus } from "../../../../core/enums";
import { FirebaseError } from "firebase/app";
import { Id, toast, TypeOptions } from "react-toastify";
import { StatusModel } from "../../../../core/models/status";
import ApplicationStatusPanel from "../status-panel";
import ApplicationStatusChangeDialog from "../../../../components/shared/dialogs/ApplicationStatusChangeDialog";
import { buildUserFullName } from "../../../../core/ui/builds";
import { useAdminMode } from "../../../../core/contexts/ui/admin-mode";

const UserInsuranceDetailsPage = () => {
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [isAdminMode] = useAdminMode();
  const location = useLocation();
  const parts = location.pathname.split("/");
  const [insuranceDoc, getInsuranceById] = useInsuranceById();
  const [isLoading, setIsLoading] = React.useState(false);
  const [statuses, sPagination, getNextInsuranceStatuses] =
    useInsuranceStatuses();
  const [selectedStatus, setSelectedStatus] = React.useState<ApplicationStatus>(
    ApplicationStatus.created
  );
  const [openTimeline, setOpenTimeline] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  React.useEffect(() => {
    if (!isLoading && userDoc) {
      setIsLoading(true);
      const id = parts[parts.length - 1];
      getInsuranceById(id, !isAdminMode)
        .then(() => getNextInsuranceStatuses(id, !isAdminMode, true))
        .then(() => setIsLoading(false));
    }
  }, [userDoc, isAdminMode]);

  // Banking details state
  const [insurance, insuranceDispatch] = useInsuranceFormReducer();

  // Pre-populate fields if default values from user document exist
  React.useEffect(() => {
    if (insuranceDoc) {
      insuranceDispatch({
        type: InsuranceFormActionType.INITIALISE,
        initialData: insuranceDoc,
      });
    }
  }, [insuranceDoc]);

  const onChangeStatus = async (status: ApplicationStatus) => {
    if (userDoc && firebaseService) {
      makeToast("Updating application's status.", toast.TYPE.INFO, false);
      // Save the user's default information in their user document
      const { uid, email, cell, name, surname } = userDoc;
      const insuranceUid = insurance.currentData.user.uid;
      const id = parts[parts.length - 1];
      try {
        const claimDocUpdate = {
          status,
          updatedAt: "",
        };
        const statusDocUpdate: StatusModel = {
          id: "",
          applicationId: id,
          user: { uid, email, cell, name, surname },
          status,
          createdAt: "",
          updatedAt: "",
        };
        await Promise.all([
          firebaseService.updateInsuranceDoc(
            insuranceUid,
            id,
            Object.assign(claimDocUpdate)
          ),
          firebaseService.setInsuranceStatusDoc(
            insuranceUid,
            id,
            statusDocUpdate
          ),
        ]);
        await getInsuranceById(id, !isAdminMode)
          .then(() => getNextInsuranceStatuses(id, !isAdminMode, true))
          .then(() => setIsLoading(false));
        makeToast("Status updated successfully.", toast.TYPE.SUCCESS);
      } catch (error) {
        let message =
          "Something went wrong while updating application's status, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to update defaults. ${error.message}`;
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl">
      {isLoading ? (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading Insurance Application"
            description="Loading insurance application, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      ) : (
        <React.Fragment>
          <p className="mt-12 mb-8 text-base text-gray-500">
            View{" "}
            {buildUserFullName(
              !isAdminMode,
              "your",
              insurance.currentData.user
            )}{" "}
            insurance's status and cover.
          </p>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            {insuranceDoc && (
              <InsuranceListItem
                insurance={insuranceDoc}
                onViewTimeline={() => setOpenTimeline(true)}
                onChangeStatus={
                  isAdminMode
                    ? (status) => {
                        setSelectedStatus(status);
                        setOpenDialog(true);
                      }
                    : undefined
                }
              />
            )}
          </p>
        </React.Fragment>
      )}
      <ApplicationStatusPanel
        statuses={statuses}
        isOpen={openTimeline}
        onClose={setOpenTimeline}
      />
      <ApplicationStatusChangeDialog
        status={selectedStatus}
        isOpen={openDialog}
        onClose={setOpenDialog}
        onChange={onChangeStatus}
      />
    </div>
  );
};

export default UserInsuranceDetailsPage;
