import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  buttonClassNames,
  buttonExtraClassNames,
} from "../../../core/ui/classNames";
import LinkButton from "../buttons/LinkButton";
import ElevatedButton from "../buttons/ElevatedButton";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export interface SimpleDropdownProps {
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  label: React.ReactNode;
  items: {
    label: React.ReactNode;
    to?: string;
    state?: any;
    onClick?: () => void;
  }[];
}

const SimpleDropdown = (props: SimpleDropdownProps) => {
  const { label, items } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={`${buttonClassNames} ${buttonExtraClassNames(true)}`}
        >
          {props.icon && <props.icon aria-hidden="true" />}
          {label}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map((item) => (
              <Menu.Item>
                {({ active }) => (
                  <div className={"block px-4 py-2"}>
                    {item.to && (
                      <LinkButton
                        to={item.to}
                        state={item.state}
                        isOutline={item.label !== label}
                        disabled={item.label === label}
                        isFullWidth
                      >
                        {item.label}
                      </LinkButton>
                    )}
                    {item.onClick && (
                      <ElevatedButton
                        onClick={item.onClick}
                        isOutline={item.label !== label}
                        disabled={item.label === label}
                        isFullWidth
                      >
                        {item.label}
                      </ElevatedButton>
                    )}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default SimpleDropdown;
