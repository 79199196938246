import { FirebaseError } from "firebase/app";
import { MultiFactorSession, RecaptchaVerifier } from "firebase/auth";
import { Link, navigate } from "gatsby";
import * as React from "react";
import { Id, toast, TypeOptions } from "react-toastify";
import ReauthenticationForm from "../../components/forms/ReauthenticationForm";
import VerifyPhoneNumberForm from "../../components/forms/VerifyPhoneNumberForm";
import Spinner from "../../components/shared/Spinner";
import Shell from "../../components/shell/Shell";
import {
  useAuthIsAuthenticating,
  useAuthIsConnected,
  useAuthIsConnecting,
  useAuthUser,
  useAuthUserDoc,
  useFirebaseService,
} from "../../core/contexts/firebase";
import { FirebaseAuthErrorCode } from "../../core/services/firebase";
import {} from "intl-tel-input";
import { useRedirectUrl } from "../../core/hooks/useRedirectUrl";
import { useShowReCaptcha } from "../../core/hooks/useShowRecaptcha";

const MFAPage = () => {
  const secondsToTime = (secs: number) => {
    const hours = Math.floor(secs / (60 * 60));

    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };

    return `${obj.m < 10 ? "0" + obj.m : obj.m}:${
      obj.s < 10 ? "0" + obj.s : obj.s
    }`;
  };

  const [recaptchaState, recaptchaDispatch] = useShowReCaptcha();
  const userDoc = useAuthUserDoc();
  const isAuthenticating = useAuthIsAuthenticating();
  const isConnecting = useAuthIsConnecting();
  const isConnected = useAuthIsConnected();
  const firebaseService = useFirebaseService();
  const [type, setType] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [mfaDisplayName, setMfaDisplayName] = React.useState("");
  const [counter, setCounter] = React.useState(0);
  const [canRedirect, setCanRedirect] = React.useState(false);
  const [verificationId, setVerificationId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [verificationCode, setVerificationCode] = React.useState("");

  const [redirectUrl, redirectSearch] = useRedirectUrl();
  const getRedirectUrl = () => {
    let url = "/dashboard";
    if (redirectUrl) {
      url = redirectUrl;
      if (redirectSearch) {
        url = `${url}${redirectSearch}`;
      }
    }
    return url;
  };

  // Setup MFA
  const mfsRef = React.useRef<MultiFactorSession | null>(null);

  React.useEffect(() => {
    setIsLoading(true);
    if (isConnected && firebaseService) {
      if (mfsRef.current == null) {
        updateMultiFactorSession().catch(() => setIsLoading(false));
      }
    }
  }, [firebaseService, mfsRef.current, isConnected]);

  React.useEffect(() => {
    setIsLoading(false);
    if (type === "" && recaptchaState.resolved) {
      initVerification();
    }
  }, [recaptchaState]);

  React.useEffect(() => {
    if (userDoc && userDoc.email && !email) {
      setEmail(userDoc.email);
    }
    if (userDoc && userDoc.cell && !phoneNumber) {
      setPhoneNumber(userDoc.cell.trim().replaceAll(" ", ""));
    }
  }, [userDoc]);

  // Third Attempts
  React.useEffect(() => {
    if (canRedirect && counter === 0) {
      navigate(getRedirectUrl());
    }
    const timer =
      counter > 0
        ? setInterval(() => setCounter(counter - 1), 1000)
        : undefined;
    return () => clearInterval(timer);
  }, [counter]);

  const getVerificationType = (type?: string | null) => {
    if (type === "phone_number") {
      return "Phone Number";
    } else {
      return "No Type Available";
    }
  };

  const onStartTimerClick = () => {
    setCounter(5);
  };

  const recentLoginRequiredMsg = "A recent authentication session is required.";

  const updateMultiFactorSession = async (): Promise<void> => {
    try {
      if (firebaseService) {
        const mfs = await firebaseService.doGetMultiFactorSession();
        mfsRef.current = mfs;
      }
      setReason("");
    } catch (error) {
      setReason("Unable to update multi-factor authentication session");
      console.error("unable to update multifactor session: " + error);
    }
  };

  const initVerification = async () => {
    if (location.search && userDoc && recaptchaState.ref && mfsRef.current) {
      const params = new URLSearchParams(location.search);
      const type = params.get("type");
      setType(getVerificationType(type));

      try {
        if (type === "phone_number") {
          setStatus("Sending verification code to your phone number");
          setVerificationId("");
          // Specify the phone number and pass the MFA session.
          const phoneInfoOptions = {
            phoneNumber,
            session: mfsRef.current,
          };
          const verificationId =
            await firebaseService?.doSendPhoneNumberVerification(
              phoneInfoOptions,
              recaptchaState.ref
            );
          if (verificationId) {
            setVerificationId(verificationId);
            setStatus(
              "Verification code sent successfully. Please enter the 6-digit code sent to your phone number."
            );
          } else {
            setStatus("Sending verification code to phone number failed");
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        let message =
          "Something went wrong while sending verification code to your phone number, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === "auth/requires-recent-login") {
            message = `Unable to send a verification code to your phone number. ${recentLoginRequiredMsg}`;
          }
          console.log(error.code);
          setReason(message);
          setStatus("Sending verification code to phone number failed");
        }
      }

      if (type === "enrolled_mfa") {
        setStatus("Enrollment to MFA with phone number completed successfully");
        setCanRedirect(true);
        onStartTimerClick();
      }
    }
  };

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);
  const onReauthenticateAccount = async (isValid: boolean) => {
    if (isValid && isConnected && firebaseService) {
      setIsLoading(true);
      try {
        const credential =
          await firebaseService.doReauthenticateWithEmailAndPassword(
            email,
            password
          );
        if (credential) {
          // An account was authenticated for the user
          const message = "Account was authenticated successfully.";
          makeToast(message, toast.TYPE.SUCCESS);
          await updateMultiFactorSession();
          await initVerification();
        } else {
          // An account was not created for the user
          const message =
            "Unable to authenticate the account, please try again";
          makeToast(message, toast.TYPE.ERROR, false);
        }
        setIsLoading(false);
        toastId.current = null;
      } catch (error) {
        setIsLoading(false);
        let message =
          "Something went wrong while authenticating your account, please try again later";
        if (error instanceof FirebaseError) {
          console.log(error.code);
          if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to authenticate. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to authenticate. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to authenticate. Account disabled. Please contact support.";
          } else if (error.code === "auth/invalid-phone-number") {
            message =
              "Unable to authenticate. Account phone number is invalid. Please contact support.";
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const onReauthenticateAccountWithGoogle = async () => {
    if (isConnected && firebaseService) {
      setIsLoading(true);
      try {
        const credential = await firebaseService.doReauthenticateWithGoogle();
        if (credential) {
          // An account was authenticated for the user
          const message = "Account was authenticated successfully.";
          makeToast(message, toast.TYPE.SUCCESS);
          await updateMultiFactorSession();
          await initVerification();
        } else {
          // An account was not created for the user
          const message =
            "Unable to authenticate the account, please try again";
          makeToast(message, toast.TYPE.ERROR, false);
        }
        setIsLoading(false);
        toastId.current = null;
      } catch (error) {
        console.log(JSON.stringify(error));
        setIsLoading(false);
        let message =
          "Something went wrong while authenticating your account, please try again later";
        if (error instanceof FirebaseError) {
          console.log(error.code);
          if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to authenticate. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to authenticate. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to authenticate. Account disabled. Please contact support.";
          } else if (error.code === "auth/invalid-phone-number") {
            message =
              "Unable to authenticate. Account phone number is invalid. Please contact support.";
          } else if (error.code === "auth/invalid-credential") {
            message =
              "Unable to authenticate. Invalid credentials. Please contact support.";
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const onVerifyCode = async (isValid: boolean) => {
    if (isValid && isConnected && firebaseService) {
      setIsLoading(true);
      try {
        const assertion = await firebaseService.doVerifyPhoneNumberCode(
          verificationId,
          verificationCode
        );
        await firebaseService.doEnrollUserToMFA(assertion, mfaDisplayName);
        const message = "Phone number verified successfully.";
        makeToast(message, toast.TYPE.SUCCESS);
        setIsLoading(false);
        toastId.current = null;
        navigate("/dashboard");
      } catch (error) {
        setIsLoading(false);
        let message =
          "Something went wrong while authenticating your account, please try again later";
        if (error instanceof FirebaseError) {
          console.log(error.code);
          if (error.code === FirebaseAuthErrorCode.WrongPassword) {
            message =
              "Unable to authenticate. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserNotFound) {
            message =
              "Unable to authenticate. Invalid email and password combination";
          } else if (error.code === FirebaseAuthErrorCode.UserDisabled) {
            message =
              "Unable to authenticate. Account disabled. Please contact support.";
          } else if (error.code === "auth/invalid-phone-number") {
            message =
              "Unable to authenticate. Account phone number is invalid. Please contact support.";
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const onResendCode = async () => {
    setIsLoading(true);
    await initVerification();
    setIsLoading(false);
  };

  const onEnableMFA = () => {};

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <div className="overflow-hidden">
      <div className="relative mx-auto max-w-7xl py-24 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-16 lg:gap-24">
          <div className="col-span-1">
            <p className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Multi-Factor Authentication (MFA) Page
            </p>
            <p className="mt-5 text-xl text-gray-500">
              This page performs all enrollments to multi-factor authentication
              automatically. {isLoading ?? "Please wait."}
            </p>
            {!(isLoading || isConnecting || isAuthenticating) ? (
              <div className="gap-4 rounded-md p-4 ring-indigo-800">
                <div className="col-span-2">
                  <div className="mt-8 flex gap-4">
                    <p className="w-48 flex-shrink">Enrollment Type:</p>
                    <p>-</p>
                    <p className="flex-1 text-gray-500">{type}</p>
                  </div>
                  <div className="mt-8 flex gap-4">
                    <p className="w-48 flex-shrink">Enrollment Status:</p>
                    <p>-</p>
                    <p className="flex-1 text-gray-500">{status}</p>
                  </div>
                  {reason && (
                    <div className="mt-8 flex gap-4">
                      <p className="w-48 flex-shrink">Enrollment Reason:</p>
                      <p>-</p>
                      <p className="flex-1 text-gray-500">{reason}</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="mt-4">
                <Spinner textColor="text-indigo-800" />
              </div>
            )}
          </div>
          {reason.includes(recentLoginRequiredMsg) && (
            <div className="col-span-2 md:col-span-1">
              <ReauthenticationForm
                label="Re-authenticate"
                email={email}
                onEmailChanged={setEmail}
                password={password}
                onPasswordChanged={setPassword}
                onButtonClick={onReauthenticateAccount}
                onReauthenticateWithGoogle={onReauthenticateAccountWithGoogle}
              />
            </div>
          )}
          {verificationId && (
            <div className="col-span-2 md:col-span-1">
              <VerifyPhoneNumberForm
                mfaDisplayName={mfaDisplayName}
                onMFADisplayNameChange={setMfaDisplayName}
                onCodeChange={setVerificationCode}
                onButtonClick={onVerifyCode}
                onResendCodeClick={onResendCode}
              />
            </div>
          )}
        </div>
        <div className="mt-12">
          {counter > 0 ? (
            <p>
              We will now redirect you in{" "}
              <span className="text-indigo-500">{secondsToTime(counter)}</span>
            </p>
          ) : (
            <p></p>
          )}
        </div>
        {canRedirect && (
          <div className="mt-24">
            <p>
              It's been a minute and you're still waiting? Manually{" "}
              <Link
                className="nline-flex items-center text-base font-medium leading-4 text-indigo-600 hover:text-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                to={getRedirectUrl()}
                replace
              >
                redirect to the next page
              </Link>
              .
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MFAPage;
