import React from "react";
import { CalendarIcon, ListBulletIcon } from "@heroicons/react/20/solid";
import { LoanModel } from "../../core/models/loan";
import { formatAmount, formatApplicationStatus } from "../../core/utils/format";
import { Link } from "gatsby";
import ApplicationUser from "../shared/ApplicationUser";
import ApplicationStatusBadge from "../shared/ApplicationStatusBadge";
import UserContactButtonGroup from "../shared/buttons/UserContactButtonGroup";
import FormattedDateTime from "../shared/FormattedDateTime";
import ApplicationStatusActions from "../shared/ApplicationStatusActions";
import ElevatedButton from "../shared/buttons/ElevatedButton";
import { ApplicationStatus } from "../../core/enums";
import { useAdminMode } from "../../core/contexts/ui/admin-mode";

export interface LoanListItemProps {
  to?: string;
  loan: LoanModel;
  onViewTimeline?: () => void;
  availableStatuses?: ApplicationStatus[];
  onChangeStatus?: (status: ApplicationStatus) => void;
  isSearchResult?: boolean;
}

const defaultApplicationStatuses = [
  ApplicationStatus.received,
  ApplicationStatus.processing,
  ApplicationStatus.approved,
  ApplicationStatus.declined,
  ApplicationStatus.missing,
  ApplicationStatus.archived,
];

const LoanListItem: React.FC<LoanListItemProps> = ({
  to,
  loan,
  availableStatuses = defaultApplicationStatuses,
  onViewTimeline,
  onChangeStatus,
  isSearchResult = false,
}) => {
  const [isAdminMode] = useAdminMode();

  const card = (
    <div>
      <div className="flex items-start justify-between">
        <div>
          <p className="truncate text-xl font-medium text-indigo-600">
            {formatAmount(loan.amount)}
          </p>
          <p className="mt-1 text-sm text-gray-400">
            Personal Loan Application
          </p>
        </div>
        <div className="ml-2 flex flex-shrink-0 overflow-hidden rounded-full">
          <ApplicationStatusBadge status={loan.status}>
            <p className="inline-flex px-4 py-1 text-xs font-semibold leading-5 text-black">
              Status: {formatApplicationStatus(loan.status)}
            </p>
          </ApplicationStatusBadge>
        </div>
      </div>
      <div className="mt-2 sm:flex sm:justify-between">
        <div></div>
        <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
          <CalendarIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <div>
            <ApplicationUser user={loan.user} /> applied{" "}
            <FormattedDateTime
              isSearchResult={isSearchResult}
              date={loan.createdAt}
            />
          </div>
        </div>
      </div>
      {isAdminMode && loan.user && loan.user.email && loan.user.cell && (
        <div className="mt-4 divide-y-2 divide-gray-200">
          <div className="pb-2"></div>
          <div className="pt-4">
            <UserContactButtonGroup baseUser={loan.user} />
          </div>
        </div>
      )}
      {onViewTimeline && (
        <div className="mt-4 divide-y-2 divide-gray-200">
          <div className="pb-2"></div>
          <div className="pt-4">
            <ElevatedButton onClick={onViewTimeline} isOutline>
              <div className="flex items-center gap-x-2">
                <ListBulletIcon className="h-4 w-4 text-indigo-800" />
                <span>View Timeline</span>
              </div>
            </ElevatedButton>
            {availableStatuses.length > 0 && onChangeStatus && (
              <ApplicationStatusActions
                availableStatuses={availableStatuses}
                onChangeStatus={onChangeStatus}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="overflow-hidden">
      <ul role="list" className="divide-y divide-gray-200">
        <li key={loan.id}>
          {to ? (
            <Link to={to} className="block px-4 py-4 sm:px-6">
              {card}
            </Link>
          ) : (
            card
          )}
        </li>
      </ul>
    </div>
  );
};

export default LoanListItem;
