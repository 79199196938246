import React from "react";
import { BusinessLoanModel } from "../../core/models/business-loan";
import { formatAmount, formatBusinessLoanType } from "../../core/utils/format";

export interface BusinessLoanDetailsProps {
  businessLoan: BusinessLoanModel;
}

const BusinessLoanDetails: React.FC<BusinessLoanDetailsProps> = ({
  businessLoan,
}) => {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Business loan type
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          {formatBusinessLoanType(businessLoan.type)}
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Loan amount</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {formatAmount(businessLoan.amount)}
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Company name</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {businessLoan.companyName}
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Company registration number
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          {businessLoan.companyRegNo}
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Email address</dt>
        <dd className="mt-1 text-sm text-gray-900 hover:text-indigo-600">
          <a
            href={`mailto:${businessLoan.email}`}
            target="_blank"
            rel="noreferrer noopener"
            className="inline-flex items-center text-sm font-medium leading-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {businessLoan.email}
          </a>
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Phone number</dt>
        <dd className="mt-1 text-sm text-gray-900 hover:text-indigo-600">
          <a
            href={`tel:${businessLoan.cell}`}
            target="_blank"
            rel="noreferrer noopener"
            className="inline-flex items-center font-medium leading-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {businessLoan.cell}
          </a>
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Name of applicant's client
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          {businessLoan.clientApplicantName}
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Location of project
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
          {businessLoan.projectLocation}
        </dd>
      </div>
    </dl>
  );
};

export default BusinessLoanDetails;
