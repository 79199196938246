import React from "react";
import { InsuranceModel } from "../../core/models/insurance";
import { formatAmount, formatInsuranceCover } from "../../core/utils/format";

export interface InsuranceDetailsProps {
  insurance: InsuranceModel;
}

const InsuranceDetails: React.FC<InsuranceDetailsProps> = ({ insurance }) => {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Insurance cover</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {formatInsuranceCover(insurance.cover)}
        </dd>
      </div>
    </dl>
  );
};

export default InsuranceDetails;
