import * as React from "react";
import { navigate } from "gatsby";
import { Tab } from "@headlessui/react";
import DashboardHero from "../../../../components/shared/DashboardHero";
import {
  useAuthUser,
  useAuthIsConnected,
} from "../../../../core/contexts/firebase";
import { useAllBusinessLoans } from "../../../../core/contexts/firebase/business-loans";
import { useAllClaims } from "../../../../core/contexts/firebase/claims";
import { useAllInsurances } from "../../../../core/contexts/firebase/insurances";
import { useAllPersonalLoans } from "../../../../core/contexts/firebase/personal-loans";
import { classNames } from "../../../../core/ui/classNames";
import InsuranceList from "../../../../components/lists/InsuranceList";
import ClaimList from "../../../../components/lists/ClaimList";
import BusinessLoanList from "../../../../components/lists/BusinessLoanList";
import PersonalLoanList from "../../../../components/lists/PersonalLoanList";

const tabs = [
  {
    name: "Personal",
  },
  {
    name: "Business",
  },
  {
    name: "Claims",
  },
  {
    name: "Insurances",
  },
];

const AllApplicationsPage = () => {
  const user = useAuthUser();
  const isConnected = useAuthIsConnected();
  const [personalLoans, plPagination, getNextPersonalLoans] =
    useAllPersonalLoans();
  const [businessLoans, blPagination, getNextBusinessLoans] =
    useAllBusinessLoans();
  const [insurances, insPagination, getNextInsurances] = useAllInsurances();
  const [claims, claPagination, getNextClaims] = useAllClaims();
  const userExists = user != null;
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isPersonalLoansLoading, setIsPersonalLoansLoading] =
    React.useState(false);
  const [isBusinessLoansLoading, setIsBusinessLoansLoading] =
    React.useState(false);
  const [isClaimsLoading, setIsClaimsLoading] = React.useState(false);
  const [isInsurancesLoading, setIsInsurancesLoading] = React.useState(false);

  const loanExist = personalLoans.length > 0;
  const businessLoanExist = businessLoans.length > 0;
  const insuranceExist = insurances.length > 0;
  const claimExist = claims.length > 0;

  const fetchNextPersonalLoans = () => {
    if (!isPersonalLoansLoading) {
      setIsPersonalLoansLoading(true);
      getNextPersonalLoans(false).then(() => setIsPersonalLoansLoading(false));
    }
  };

  const fetchNextBusinessLoans = () => {
    if (!isBusinessLoansLoading) {
      setIsBusinessLoansLoading(true);
      getNextBusinessLoans(false).then(() => setIsBusinessLoansLoading(false));
    }
  };

  const fetchNextClaims = () => {
    if (!isClaimsLoading) {
      setIsClaimsLoading(true);
      getNextClaims(false).then(() => setIsClaimsLoading(false));
    }
  };

  const fetchNextInsurances = () => {
    if (!isInsurancesLoading) {
      setIsInsurancesLoading(true);
      getNextInsurances(false).then(() => setIsInsurancesLoading(false));
    }
  };

  if (!isConnected || !userExists) return null;

  React.useEffect(() => {
    if (!loanExist) fetchNextPersonalLoans();
    if (!businessLoanExist) fetchNextBusinessLoans();
    if (!insuranceExist) fetchNextClaims();
    if (!claimExist) fetchNextInsurances();
  }, []);

  React.useEffect(() => {
    const location = window?.location;
    if (location) {
      const params = new URLSearchParams(location.search);
      const tabStr = params.get("tab");
      switch (tabStr) {
        case "business":
          setSelectedIndex(1);
          break;
        case "claims":
          setSelectedIndex(2);
          break;
        case "insurances":
          setSelectedIndex(3);
          break;
        case "personal":
        default:
          setSelectedIndex(0);
          break;
      }
    }
  }, [window?.location.search]);

  return (
    <React.Fragment>
      <article>
        {/* History header */}
        <div>
          <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl">
            <DashboardHero
              description="Keep up with your finances through a simple history view of all your loan, claim, and insurance applications."
              pages={[]}
            />
          </div>

          {/* Tabs */}
          <div className="mt-2 2xl:mt-5">
            <div className="border-b border-gray-200">
              <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                <Tab.Group selectedIndex={selectedIndex}>
                  <div className="overflow-hidden overflow-x-auto rounded-md bg-white shadow-md">
                    <Tab.List className="-mb-px flex flex-col justify-center gap-4 py-4 px-4 md:flex-row lg:justify-start">
                      {tabs.map((tab) => (
                        <Tab
                          key={tab.name}
                          onClick={() =>
                            navigate(`?tab=${tab.name.toLowerCase()}`)
                          }
                        >
                          {({ selected }: any) => (
                            <span
                              className={classNames(
                                selected
                                  ? "border-indigo-500 text-gray-900"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "text-medium whitespace-nowrap border-b-2 py-2 px-1 font-medium"
                              )}
                            >
                              {tab.name}
                            </span>
                          )}
                        </Tab>
                      ))}
                    </Tab.List>
                  </div>
                  <Tab.Panels className="py-12">
                    <Tab.Panel tabIndex={0}>
                      <PersonalLoanList
                        personalLoans={personalLoans}
                        isLoading={isPersonalLoansLoading}
                        hasNext={plPagination.hasNext}
                        onLoadMoreClick={fetchNextPersonalLoans}
                        isAdmin={true}
                      />
                    </Tab.Panel>
                    <Tab.Panel tabIndex={1}>
                      <BusinessLoanList
                        businessLoans={businessLoans}
                        isLoading={isBusinessLoansLoading}
                        hasNext={blPagination.hasNext}
                        onLoadMoreClick={fetchNextBusinessLoans}
                        isAdmin={true}
                      />
                    </Tab.Panel>
                    <Tab.Panel tabIndex={2}>
                      <ClaimList
                        claims={claims}
                        isLoading={isClaimsLoading}
                        hasNext={claPagination.hasNext}
                        onLoadMoreClick={fetchNextClaims}
                        isAdmin={true}
                      />
                    </Tab.Panel>
                    <Tab.Panel tabIndex={3}>
                      <InsuranceList
                        insurances={insurances}
                        isLoading={isInsurancesLoading}
                        hasNext={insPagination.hasNext}
                        onLoadMoreClick={fetchNextInsurances}
                        isAdmin={true}
                      />
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
};

export default AllApplicationsPage;
