import React from "react";

export interface WallProps {
  code: string;
  message: string;
  description: string;
  children: React.ReactNode;
}

const Wall: React.FC<WallProps> = ({
  code,
  message,
  description,
  children,
}) => {
  return (
    <>
      <div className="flex min-h-full flex-col pt-16 pb-12">
        <main className="mx-auto flex w-full max-w-6xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-indigo-600">{code}</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                {message}
              </h1>
              <p className="mt-2 text-base text-gray-500">{description}</p>
              <div className="mt-6">{children}</div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Wall;
