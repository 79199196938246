import * as React from "react";
import { Link } from "gatsby";
import {
  CheckCircleIcon,
  CheckBadgeIcon,
  UsersIcon,
  BuildingLibraryIcon,
  XCircleIcon,
  ShieldExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid";
import {
  useAuthIsConnected,
  useAuthUser,
  useAuthUserDoc,
  useFirebaseService,
} from "../../core/contexts/firebase";
import SimpleDropdown from "../../components/shared/dropdowns/SimpleDropdown";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../core/ui/classNames";
import UserAvatar from "../../components/shared/UserAvatar";
import LinkButton from "../../components/shared/buttons/LinkButton";
import { MultiFactorInfo } from "firebase/auth";
import appConfig from "../../core/utils/config";

const unverifiedBadge = {
  icon: XCircleIcon,
  name: "Account Not Verified",
  classNames: "text-rose-600",
};

const verifiedBadge = {
  icon: CheckCircleIcon,
  name: "Verified Account",
  classNames: "text-green-400",
};

const partiallyVerifiedBadge = {
  icon: InformationCircleIcon,
  name: "Partially Verified Account",
  classNames: "text-yellow-400",
};

const actions = [
  {
    icon: UsersIcon,
    name: "Start a new personal loan application",
    href: "/dashboard/new/personal-loan",
    description:
      "New clients can apply for up to R2,500 with 35 days to pay. Existing clients up to R5,000 with 35 days to pay.",
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
  {
    icon: BuildingLibraryIcon,
    name: "Start a new business loan application",
    href: "/dashboard/new/business-loan",
    description:
      "We provide tailor-made funding solutions ranging from R50,000 to R5,000,000 to SMEs who have a viable business and require finance for expansion or working capital.",
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    icon: CheckBadgeIcon,
    name: "Start a new claim application",
    href: "/dashboard/new/claim",
    description:
      "Easily make your claim application online by following our simple 4-step claims process.",
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    icon: ShieldExclamationIcon,
    name: "Start a new insurance application",
    href: "/dashboard/new/insurance",
    description:
      "Providing insurance solutions for personal and commercial markets. Comprehensive intermediary services are also offered to our clients for both long and short term insurance.",
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
  },
];

const getGreeting = () => {
  const now = new Date();
  const hours = now.getHours();
  if (hours < 12) {
    return "Good morning";
  } else if (hours >= 12 && hours < 18) {
    return "Good afternoon";
  } else {
    return "Good evening";
  }
};

const DashboardIndexPage = () => {
  const firebaseService = useFirebaseService();
  const user = useAuthUser();
  const userDoc = useAuthUserDoc();
  const isConnected = useAuthIsConnected();
  const [isLoading, setIsLoading] = React.useState(false);
  const [enrolledFactors, setEnrolledFactors] = React.useState<
    MultiFactorInfo[] | null
  >(null);
  const { enableTwoFactorAuth } = appConfig;
  const userExists = user != null;
  const userDocExists = userDoc != null;
  const emailVerified = user && user.emailVerified;
  const mfaEnabled = enrolledFactors && enrolledFactors.length > 0;

  let verificationBadge = unverifiedBadge;
  const verifications = [];
  if (emailVerified) {
    if (!enableTwoFactorAuth) {
      verificationBadge = verifiedBadge;
    } else {
      if (mfaEnabled) {
        verificationBadge = verifiedBadge;
      } else {
        verificationBadge = partiallyVerifiedBadge;
      }
    }
  }
  if (userExists && userDocExists) {
    if (!emailVerified) {
      verifications.push({
        label: "Verify Email",
        to: `/auth/verify?type=init_email&email=${userDoc.email}`,
      });
    } else if (enableTwoFactorAuth && !mfaEnabled) {
      verifications.push({
        label: "Enable MFA",
        to: `/dashboard/mfa?type=phone_number`,
        state: {
          phoneNumber: userDoc.cell.trim().replaceAll(" ", ""),
        },
      });
    }
  }

  React.useEffect(() => {
    if (
      isConnected &&
      firebaseService &&
      enableTwoFactorAuth &&
      enrolledFactors == null
    ) {
      firebaseService
        .doGetMultiFactorEnrolledFactors()
        .then((enrolledFactors) => setEnrolledFactors([...enrolledFactors]));
    }
  }, [isConnected, firebaseService, enrolledFactors]);

  return (
    <React.Fragment>
      {/* Page header */}
      <div>
        <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-indigo-500">
            <div className="min-w-0 flex-1">
              {/* Profile */}
              <div className="flex items-center">
                <div className="hidden sm:inline-block">
                  <UserAvatar />
                </div>
                <div>
                  <div className="flex items-center">
                    <div className="sm:hidden">
                      <UserAvatar />
                    </div>
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                      {getGreeting()}, {user?.displayName ?? "User"}
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                    <dt className="sr-only">Account status</dt>
                    <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                      <verificationBadge.icon
                        className={`mr-1.5 h-5 w-5 flex-shrink-0 ${verificationBadge.classNames}`}
                        aria-hidden="true"
                      />
                      {verificationBadge.name}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div className="mt-6 flex justify-between space-x-3 md:mt-0 md:ml-4">
              <LinkButton to="/dashboard/profile" isOutline>
                View Profile
              </LinkButton>
              {verifications.length > 0 && (
                <SimpleDropdown
                  icon={(props) => (
                    <ExclamationCircleIcon
                      className="-ml-1 mr-2 h-5 w-5 text-rose-600"
                      {...props}
                    />
                  )}
                  label="Verification Options"
                  items={verifications}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Actions panel */}
      <section
        className="mt-8 py-6 px-4 md:px-6 lg:mx-auto lg:max-w-6xl lg:px-8"
        aria-labelledby="quick-links-title"
      >
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          <h2 className="sr-only" id="quick-links-title">
            Quick links
          </h2>
          {actions.map((action, actionIdx) => (
            <div
              key={action.name}
              className={classNames(
                actionIdx === 0
                  ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                  : "",
                actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
                actionIdx === actions.length - 1
                  ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none"
                  : "",
                "group relative bg-white p-6 transition-colors focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500 hover:bg-indigo-100"
              )}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    "inline-flex rounded-lg p-3 ring-4 ring-white"
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-lg font-medium">
                  <Link to={action.href} className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.name}
                  </Link>
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  {action.description}
                </p>
              </div>
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </section>
    </React.Fragment>
  );
};

export default DashboardIndexPage;
