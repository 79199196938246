import * as React from "react";
import { useLocation } from "@reach/router";
import useAddressFormReducer, {
  AddressFormActionType,
} from "../../../../core/hooks/useAddressFormReducer";
import { useUserByUid } from "../../../../core/contexts/firebase/users";
import UserListItem from "../../../../components/lists/UserListItem";
import { FolderOpenIcon, PlusIcon } from "@heroicons/react/24/solid";
import AddressDetails from "../../../../components/details/AddressDetails";
import EmptyState from "../../../../components/shared/EmptyState";
import useEmployerDetailsFormReducer, {
  EmployerDetailsFormActionType,
} from "../../../../core/hooks/useEmployerDetailsFormReducer";
import useBankingDetailsFormReducer, {
  BankingDetailsFormActionType,
} from "../../../../core/hooks/useBankingDetailsFormReducer";
import useAffordabilityFormReducer, {
  AffordabilityFormActionType,
} from "../../../../core/hooks/useAffordabilityFormReducer";
import EmployerDetails from "../../../../components/details/EmployerDetails";
import BankingDetails from "../../../../components/details/BankingDetails";
import AffordabilityDetails from "../../../../components/details/AffordabilityDetails";
import LoadingState from "../../../../components/shared/LoadingState";
import Spinner from "../../../../components/shared/Spinner";

const AdminUserDetailsPage = () => {
  const location = useLocation();
  const parts = location.pathname.split("/");
  const [userDoc, getUserByUid] = useUserByUid();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const pageId = parts[parts.length - 1];
    if (!isLoading && userDoc?.uid !== pageId) {
      setIsLoading(true);
      getUserByUid(parts[parts.length - 1]).then(() => setIsLoading(false));
    }
  }, [parts, userDoc]);

  // Banking details state
  const [address, addressDispatch] = useAddressFormReducer();
  const [employerDetails, employerDetailsDispatch] =
    useEmployerDetailsFormReducer();
  const [bankingDetails, bankingDetailsDispatch] =
    useBankingDetailsFormReducer();
  const [affordability, affordabilityDispatch] = useAffordabilityFormReducer();

  // Pre-populate fields if default values from user document exist
  React.useEffect(() => {
    if (userDoc) {
      const {
        defaultAddress,
        defaultEmployerDetails,
        defaultBankDetails,
        defaultAffordability,
      } = userDoc;
      if (defaultAddress) {
        addressDispatch({
          type: AddressFormActionType.INITIALISE,
          initialData: defaultAddress,
        });
      }
      if (defaultEmployerDetails) {
        employerDetailsDispatch({
          type: EmployerDetailsFormActionType.INITIALISE,
          initialData: defaultEmployerDetails,
        });
      }
      if (defaultBankDetails) {
        bankingDetailsDispatch({
          type: BankingDetailsFormActionType.INITIALISE,
          initialData: defaultBankDetails,
        });
      }
      if (defaultAffordability) {
        affordabilityDispatch({
          type: AffordabilityFormActionType.INITIALISE,
          initialData: defaultAffordability,
        });
      }
    }
  }, [userDoc]);

  return (
    <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl">
      {isLoading ? (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading User"
            description="Loading user, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      ) : (
        <React.Fragment>
          <p className="mt-12 mb-8 text-base text-gray-500">
            View the applicant's details.
          </p>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            {userDoc && <UserListItem user={userDoc} />}
          </p>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Address details
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            {userDoc?.defaultAddress ? (
              <AddressDetails address={address.currentData} />
            ) : (
              <EmptyState
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
                title="No Address Details"
                description="This user has not set a default address on their profile."
              />
            )}
          </div>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Employer details
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            {userDoc?.defaultEmployerDetails ? (
              <EmployerDetails employerDetails={employerDetails.currentData} />
            ) : (
              <EmptyState
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
                title="No Employer Details"
                description="This user has not set a default employer on their profile."
              />
            )}
          </div>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Banking details
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            {userDoc?.defaultBankDetails ? (
              <BankingDetails bankingDetails={bankingDetails.currentData} />
            ) : (
              <EmptyState
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
                title="No Banking Details"
                description="This user has not set a default bank on their profile."
              />
            )}
          </div>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Affordability details
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            {userDoc?.defaultAffordability ? (
              <AffordabilityDetails affordability={affordability.currentData} />
            ) : (
              <EmptyState
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
                title="No Affordability Details"
                description="This user has not set default affordability details on their profile."
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default AdminUserDetailsPage;
