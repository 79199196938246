import * as React from "react";
import { useLocation } from "@reach/router";
import ClaimListItem from "../../../../components/lists/ClaimListItem";
import useClaimFormReducer, {
  ClaimFormActionType,
} from "../../../../core/hooks/useClaimFormReducer";
import UploadCard from "../../../../components/shared/cards/UploadCard";
import EmptyState from "../../../../components/shared/EmptyState";
import ClaimDetails from "../../../../components/details/ClaimDetails";
import { FolderOpenIcon } from "@heroicons/react/24/outline";
import { useClaimById } from "../../../../core/contexts/firebase/claims";
import {
  useAuthUserDoc,
  useFirebaseService,
} from "../../../../core/contexts/firebase";
import LoadingState from "../../../../components/shared/LoadingState";
import Spinner from "../../../../components/shared/Spinner";
import { useClaimStatuses } from "../../../../core/contexts/firebase/statuses/claims";
import ApplicationStatusPanel from "../status-panel";
import { ApplicationStatus } from "../../../../core/enums";
import ApplicationStatusChangeDialog from "../../../../components/shared/dialogs/ApplicationStatusChangeDialog";
import { TypeOptions, toast, Id } from "react-toastify";
import { FirebaseError } from "firebase/app";
import { StatusModel } from "../../../../core/models/status";
import { useAdminMode } from "../../../../core/contexts/ui/admin-mode";
import { buildUserFullName } from "../../../../core/ui/builds";

const UserClaimDetailsPage = () => {
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [isAdminMode] = useAdminMode();
  const location = useLocation();
  const parts = location.pathname.split("/");
  const [claimDoc, getClaimById] = useClaimById();
  const [statuses, sPagination, getNextClaimStatuses] = useClaimStatuses();
  const [selectedStatus, setSelectedStatus] = React.useState<ApplicationStatus>(
    ApplicationStatus.created
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [openTimeline, setOpenTimeline] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  React.useEffect(() => {
    if (!isLoading && userDoc) {
      setIsLoading(true);
      const id = parts[parts.length - 1];
      getClaimById(id, !isAdminMode)
        .then(() => getNextClaimStatuses(id, !isAdminMode, true))
        .then(() => setIsLoading(false));
    }
  }, [userDoc, isAdminMode]);

  // Banking details state
  const [claim, claimDispatch] = useClaimFormReducer();

  // Pre-populate fields if default values from user document exist
  React.useEffect(() => {
    if (claimDoc) {
      claimDispatch({
        type: ClaimFormActionType.INITIALISE,
        initialData: claimDoc,
      });
    }
  }, [claimDoc]);

  const onChangeStatus = async (status: ApplicationStatus) => {
    if (userDoc && firebaseService) {
      makeToast("Updating application's status.", toast.TYPE.INFO, false);
      // Save the user's default information in their user document
      const { uid, email, cell, name, surname } = userDoc;
      const claimUid = claim.currentData.user.uid;
      const id = parts[parts.length - 1];
      try {
        const claimDocUpdate = {
          status,
          updatedAt: "",
        };
        const statusDocUpdate: StatusModel = {
          id: "",
          applicationId: id,
          user: { uid, email, cell, name, surname },
          status,
          createdAt: "",
          updatedAt: "",
        };
        await Promise.all([
          firebaseService.updateClaimDoc(
            claimUid,
            id,
            Object.assign(claimDocUpdate)
          ),
          firebaseService.setClaimStatusDoc(claimUid, id, statusDocUpdate),
        ]);
        await getClaimById(id, !isAdminMode)
          .then(() => getNextClaimStatuses(id, !isAdminMode, true))
          .then(() => setIsLoading(false));
        makeToast("Status updated successfully.", toast.TYPE.SUCCESS);
      } catch (error) {
        console.log(error);
        let message =
          "Something went wrong while updating application's status, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to update defaults. ${error.message}`;
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl">
      {isLoading ? (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading Claim Application"
            description="Loading claim application, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      ) : (
        <React.Fragment>
          <p className="mt-12 mb-8 text-base text-gray-500">
            View{" "}
            {buildUserFullName(!isAdminMode, "your", claim.currentData.user)}{" "}
            claim's status, details, and uploaded documents.
          </p>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            {claimDoc && (
              <ClaimListItem
                onViewTimeline={() => setOpenTimeline(true)}
                onChangeStatus={
                  isAdminMode
                    ? (status) => {
                        setSelectedStatus(status);
                        setOpenDialog(true);
                      }
                    : undefined
                }
                claim={claimDoc}
              />
            )}
          </p>
          <p className="col-span-2 mt-12 text-2xl font-medium">Claim details</p>
          <div className="mt-6 rounded-md border-2 p-4">
            {claimDoc ? (
              <ClaimDetails claim={claim.currentData} />
            ) : (
              <EmptyState
                title="No Claim Details Found"
                description="Oops! Seems like we could not find any claim details linked to this claim record. Please contact support for help."
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
              />
            )}
          </div>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Uploaded documents
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            <div className="grid grid-cols-1 gap-y-4">
              <UploadCard
                label="Claim form"
                downloadUrl={claimDoc?.download.claimFormUrl}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <ApplicationStatusPanel
        statuses={statuses}
        isOpen={openTimeline}
        onClose={setOpenTimeline}
      />
      <ApplicationStatusChangeDialog
        status={selectedStatus}
        isOpen={openDialog}
        onClose={setOpenDialog}
        onChange={onChangeStatus}
      />
    </div>
  );
};

export default UserClaimDetailsPage;
