import * as React from "react";
import Breadcrumb from "./Breadcrumb";

export interface DashboardHeroProps {
  title?: string;
  description: string;
  pages?: { name: string; to: string }[];
}

const DashboardHero = ({ title, description, pages }: DashboardHeroProps) => {
  return (
    <div className="relative">
      <div className="relative mx-auto max-w-7xl py-8 sm:py-12">
        {pages && pages.length > 0 && (
          <div className="mb-8">
            <Breadcrumb homeTo="/dashboard" pages={pages} />
          </div>
        )}
        {title && (
          <h1 className="text-xl font-medium tracking-tight sm:text-2xl lg:text-3xl">
            {title}
          </h1>
        )}
        <p className="mt-2 max-w-3xl text-lg text-gray-600 lg:text-lg">
          {description}
        </p>
      </div>
    </div>
  );
};

export default DashboardHero;
