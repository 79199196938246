import React from "react";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum PersonalLoanSubmissionActionType {
  RESET_ALL_PERCENTS,
  CHANGE_FIELD,
}

export interface PersonalLoanSubmissionAction {
  type: PersonalLoanSubmissionActionType;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface PersonalLoanSubmissionState {
  isSubmitting: boolean;
  isSubmitted: boolean;
  percent: {
    bankStatement: number;
    payslip: number;
    idCopy: number;
  };
  download: {
    bankStatementUrl: string;
    payslipUrl: string;
    idCopyUrl: string;
  };
}

const initialState: PersonalLoanSubmissionState = {
  isSubmitting: false,
  isSubmitted: false,
  percent: {
    bankStatement: 0,
    payslip: 0,
    idCopy: 0,
  },
  download: {
    bankStatementUrl: "",
    payslipUrl: "",
    idCopyUrl: "",
  },
};

const reducer = (
  state = initialState,
  action: PersonalLoanSubmissionAction
) => {
  const { type, payload } = action;
  switch (type) {
    case PersonalLoanSubmissionActionType.RESET_ALL_PERCENTS:
      return {
        ...state,
        percent: {
          bankStatement: 0,
          payslip: 0,
          idCopy: 0,
        },
      };
    case PersonalLoanSubmissionActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField({ ...state }, fieldKey, fieldValue);
        return {
          ...state,
          currentData,
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

const usePersonalLoanSubmissionReducer = (): [
  PersonalLoanSubmissionState,
  React.Dispatch<PersonalLoanSubmissionAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default usePersonalLoanSubmissionReducer;
