import { navigate } from "gatsby";
import React from "react";
import { useLocation } from "@reach/router";
import Wall from "../../../client/dashboard/admin/wall";
import { useAdminMode } from "../../../core/contexts/ui/admin-mode";
import ElevatedButton from "../buttons/ElevatedButton";
import LinkButton from "../buttons/LinkButton";

const AdminWall = () => {
  const location = useLocation();
  const [isAdminMode, setIsAdminMode] = useAdminMode();
  return (
    <Wall
      code="400"
      message="Bad Request."
      description="Sorry, you do not have administrator mode enabled to access the content of this page. Please try again with administrator mode enabled."
    >
      <div className="mx-auto mt-12 grid max-w-3xl grid-cols-1 gap-4 md:grid-cols-2">
        <LinkButton to="/dashboard" isFullWidth isOutline>
          My Home
        </LinkButton>
        <ElevatedButton
          onClick={() => {
            setIsAdminMode(true);
            let search = "";
            if (location.search) {
              search = `${location.search}`;
            }
            navigate(
              !isAdminMode
                ? `/dashboard/admin/applications${search}`
                : `/dashboard${search}`
            );
          }}
          isFullWidth
        >
          Switch to Admin
        </ElevatedButton>
      </div>
    </Wall>
  );
};

export default AdminWall;
