import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import React from "react";
import { BaseUser } from "../../../core/models/user";
import LinkButton from "./LinkButton";

export interface UserContactButtonGroupProps {
  baseUser?: BaseUser;
}

const UserContactButtonGroup: React.FC<UserContactButtonGroupProps> = ({
  baseUser,
}) => {
  if (baseUser) {
    const { email, cell } = baseUser;
    if (email && cell) {
      return (
        <div className="flex gap-x-4">
          <LinkButton to={`mailto:${email}`} isExternal isOutline>
            <div className="flex items-center gap-x-2">
              <EnvelopeIcon className="h-4 w-4 text-indigo-800" />
              <span>Email Applicant</span>
            </div>
          </LinkButton>
          <LinkButton to={`tel:${cell}`} isExternal isOutline>
            <div className="flex items-center gap-x-2">
              <PhoneIcon className="h-4 w-4 text-indigo-800" />
              <span>Call Applicant</span>
            </div>
          </LinkButton>
        </div>
      );
    }
  }
  return null;
};

export default UserContactButtonGroup;
