import React from "react";

interface ContextState {
  isAdminMode: boolean;
  setIsAdminMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: ContextState = {
  isAdminMode: false,
  setIsAdminMode: () => null,
};

export const AdminModeContext = React.createContext<ContextState>(initialState);

type AdminModeContextProviderProps = {
  children: React.ReactNode;
};

export const AdminModeContextProvider = ({
  children,
}: AdminModeContextProviderProps) => {
  const [isAdminMode, setIsAdmin] = React.useState(initialState.isAdminMode);

  return (
    <AdminModeContext.Provider
      value={{ isAdminMode, setIsAdminMode: setIsAdmin }}
    >
      {children}
    </AdminModeContext.Provider>
  );
};

export const useAdminMode = (
  isAdmin?: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const context = React.useContext(AdminModeContext);
  if (context === undefined) {
    throw new Error(
      "useAdminModeIsOpen must be used within a AdminModeProvider"
    );
  }

  if (isAdmin != undefined || isAdmin != null) {
    context.setIsAdminMode(isAdmin);
  }
  return [context.isAdminMode, context.setIsAdminMode];
};
