import React from "react";
import { Router } from "@reach/router";
import DashboardShell from "../../components/shell/dashboard/DashboardShell";
import DashboardIndexPage from "../../client/dashboard";
import { HeadFC } from "gatsby";
import { CustomHead } from "../../components/shell/CustomHead";
import UserHistoryPage from "../../client/dashboard/applications";
import BusinessLoanApplicationPage from "../../client/dashboard/new/business-loan";
import PersonalLoanApplicationPage from "../../client/dashboard/new/personal-loan";
import ClaimApplicationPage from "../../client/dashboard/new/claim";
import InsuranceApplicationPage from "../../client/dashboard/new/insurance";
import UserProfilePage from "../../client/dashboard/profile";
import ProtectedRoute from "../../components/routes/PrivateRoute";
import EditUserProfilePage from "../../client/dashboard/profile/edit";
import EditAddressPage from "../../client/dashboard/profile/edit-address";
import EditAffordabiltyPage from "../../client/dashboard/profile/edit-affordability";
import EditEmployerPage from "../../client/dashboard/profile/edit-employer";
import EditBankingPage from "../../client/dashboard/profile/edit-banking";
import DashboardPrivacyPage from "../../client/dashboard/privacy";
import DashboardTermsPage from "../../client/dashboard/terms";
import UserLoanDetailsPage from "../../client/dashboard/applications/loans/details";
import UserInsuranceDetailsPage from "../../client/dashboard/applications/insurances/details";
import UserClaimDetailsPage from "../../client/dashboard/applications/claims/details";
import UserBusinessLoanDetailsPage from "../../client/dashboard/applications/business-loans/details";
import AdminIndexPage from "../../client/dashboard/admin";
import { PersonalLoansContextProvider } from "../../core/contexts/firebase/personal-loans";
import { BusinessLoansContextProvider } from "../../core/contexts/firebase/business-loans";
import { InsurancesContextProvider } from "../../core/contexts/firebase/insurances";
import { ClaimsContextProvider } from "../../core/contexts/firebase/claims";
import AllUsersPage from "../../client/dashboard/admin/users";
import { UsersContextProvider } from "../../core/contexts/firebase/users";
import AdminUserDetailsPage from "../../client/dashboard/admin/users/details";
import { CommandPaletteContextProvider } from "../../core/contexts/ui/command-palette";
import AllApplicationsPage from "../../client/dashboard/admin/applications";
import SearchIndexPage from "../../client/dashboard/search";
import { SearchContextProvider } from "../../core/contexts/search";
import { ClaimStatusesContextProvider } from "../../core/contexts/firebase/statuses/claims";
import { AdminModeContextProvider } from "../../core/contexts/ui/admin-mode";
import { BusinessLoanStatusesContextProvider } from "../../core/contexts/firebase/statuses/business-loans";
import { PersonalLoanStatusesContextProvider } from "../../core/contexts/firebase/statuses/personal-loans";
import { InsuranceStatusesContextProvider } from "../../core/contexts/firebase/statuses/insurances";
import MFAPage from "../../client/dashboard/mfa";
import { SettingsContextProvider } from "../../core/contexts/firebase/settings";
import AdminSettingsPage from "../../client/dashboard/admin/settings/settings";
import { ApplicationType } from "../../core/enums";

const Dashboard = () => {
  return (
    <AdminModeContextProvider>
      <SettingsContextProvider>
        <SearchContextProvider>
          <CommandPaletteContextProvider>
            <DashboardShell>
              <PersonalLoansContextProvider>
                <BusinessLoansContextProvider>
                  <InsurancesContextProvider>
                    <ClaimsContextProvider>
                      <UsersContextProvider>
                        <Router basepath="/dashboard">
                          <ProtectedRoute
                            path="/admin"
                            adminOnly
                            outlet={<AdminIndexPage />}
                          />
                          <ProtectedRoute
                            path="/admin/settings"
                            paidOnly
                            adminOnly
                            outlet={<AdminSettingsPage />}
                          />
                          <ProtectedRoute
                            path="/admin/search"
                            paidOnly
                            adminOnly
                            outlet={<SearchIndexPage />}
                          />
                          <ProtectedRoute
                            path="/admin/applications"
                            adminOnly
                            outlet={<AllApplicationsPage />}
                          />
                          <ProtectedRoute
                            path="/admin/applicants"
                            adminOnly
                            outlet={<AllUsersPage />}
                          />
                          <ProtectedRoute
                            path="/admin/applicants/:userId"
                            adminOnly
                            outlet={<AdminUserDetailsPage />}
                          />
                          <ProtectedRoute
                            path="/privacy"
                            outlet={<DashboardPrivacyPage />}
                          />
                          <ProtectedRoute
                            path="/terms"
                            outlet={<DashboardTermsPage />}
                          />
                          <ProtectedRoute
                            path="/applications"
                            outlet={<UserHistoryPage />}
                          />
                          <ProtectedRoute
                            path="/applications/personal-loans/:loanId"
                            outlet={
                              <PersonalLoanStatusesContextProvider>
                                <UserLoanDetailsPage />
                              </PersonalLoanStatusesContextProvider>
                            }
                          />
                          <ProtectedRoute
                            path="/applications/business-loans/:loanId"
                            outlet={
                              <BusinessLoanStatusesContextProvider>
                                <UserBusinessLoanDetailsPage />
                              </BusinessLoanStatusesContextProvider>
                            }
                          />
                          <ProtectedRoute
                            path="/applications/claims/:claimId"
                            outlet={
                              <ClaimStatusesContextProvider>
                                <UserClaimDetailsPage />
                              </ClaimStatusesContextProvider>
                            }
                          />
                          <ProtectedRoute
                            path="/applications/insurances/:insuranceId"
                            outlet={
                              <InsuranceStatusesContextProvider>
                                <UserInsuranceDetailsPage />
                              </InsuranceStatusesContextProvider>
                            }
                          />
                          <ProtectedRoute
                            path="/new/business-loan"
                            verifiedOnly
                            paidOnly
                            applicationType={ApplicationType.businessLoans}
                            outlet={<BusinessLoanApplicationPage />}
                          />
                          <ProtectedRoute
                            path="/new/personal-loan"
                            verifiedOnly
                            paidOnly
                            applicationType={ApplicationType.personalLoans}
                            outlet={<PersonalLoanApplicationPage />}
                          />
                          <ProtectedRoute
                            path="/new/claim"
                            verifiedOnly
                            paidOnly
                            applicationType={ApplicationType.claims}
                            outlet={<ClaimApplicationPage />}
                          />
                          <ProtectedRoute
                            path="/new/insurance"
                            verifiedOnly
                            paidOnly
                            applicationType={ApplicationType.insurances}
                            outlet={<InsuranceApplicationPage />}
                          />
                          <ProtectedRoute
                            path="/profile/address/edit"
                            outlet={<EditAddressPage />}
                          />
                          <ProtectedRoute
                            path="/profile/employer/edit"
                            outlet={<EditEmployerPage />}
                          />
                          <ProtectedRoute
                            path="/profile/banking/edit"
                            outlet={<EditBankingPage />}
                          />
                          <ProtectedRoute
                            path="/profile/affordability/edit"
                            outlet={<EditAffordabiltyPage />}
                          />
                          <ProtectedRoute
                            path="/profile/edit"
                            outlet={<EditUserProfilePage />}
                          />
                          <ProtectedRoute
                            path="/profile"
                            outlet={<UserProfilePage />}
                          />
                          <ProtectedRoute path="/mfa" outlet={<MFAPage />} />
                          <ProtectedRoute
                            path="/"
                            outlet={<DashboardIndexPage />}
                          />
                        </Router>
                      </UsersContextProvider>
                    </ClaimsContextProvider>
                  </InsurancesContextProvider>
                </BusinessLoansContextProvider>
              </PersonalLoansContextProvider>
            </DashboardShell>
          </CommandPaletteContextProvider>
        </SearchContextProvider>
      </SettingsContextProvider>
    </AdminModeContextProvider>
  );
};

export default Dashboard;

export const Head: HeadFC = () => (
  <CustomHead
    title="Dashboard"
    description="Discover a new and easy way of managing your finances. "
  />
);
