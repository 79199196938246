import * as React from "react";
import { toast } from "react-toastify";
import { BusinessLoanModel } from "../../core/models/business-loan";
import EmailField from "../fields/EmailField";
import NameField from "../fields/NameField";
import PhoneNumberField from "../fields/PhoneNumberField";

export interface BusinessLoanDetailsFormProps {
  label: string;
  businessLoan: BusinessLoanModel;
  phoneNumberErrorMessage: string;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onPhoneNumberErrorMessage: (errorMessage: string) => void;
  onFieldChanged: (fieldKey: string, fieldValue: any) => void;
}

const BusinessLoanDetailsForm = (props: BusinessLoanDetailsFormProps) => {
  const { businessLoan } = props;

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isFormValid = event.currentTarget.checkValidity();
    if (props.phoneNumberErrorMessage) {
      isFormValid = false;
      toast.error(props.phoneNumberErrorMessage, {
        toastId: props.phoneNumberErrorMessage,
      });
    }
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="space-y-8 divide-y divide-gray-200">
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 lg:col-span-1">
          <NameField
            label="Company name"
            name={businessLoan.companyName}
            placeholder="Company Name (Pty) Ltd"
            onChange={(v) => props.onFieldChanged("companyName", v)}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <NameField
            label="Company registration number"
            name={businessLoan.companyRegNo}
            placeholder="1999/123456/23"
            onChange={(v) => props.onFieldChanged("companyRegNo", v)}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <EmailField
            email={businessLoan.email}
            onChange={(v) => props.onFieldChanged("email", v)}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <PhoneNumberField
            phoneNumber={businessLoan.cell}
            onChange={(v, e) => {
              props.onFieldChanged("cell", v);
              props.onPhoneNumberErrorMessage(e);
            }}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <NameField
            label="Name of applicant's client"
            name={businessLoan.clientApplicantName}
            onChange={(v) => props.onFieldChanged("clientApplicantName", v)}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <NameField
            label="Location of project"
            name={businessLoan.projectLocation}
            onChange={(v) => props.onFieldChanged("projectLocation", v)}
          />
        </div>
      </div>

      <div className="col-span-2 flex gap-8">
        <button
          type={props.type ?? "submit"}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {props.label}
        </button>
      </div>
    </form>
  );
};

export default BusinessLoanDetailsForm;
