import React, { Reducer } from "react";
import { ApplicationStatus, ClaimType } from "../enums";
import { ClaimModel } from "../models/claim";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum ClaimFormActionType {
  INITIALISE,
  CHANGE_FIELD,
}

export interface ClaimFormAction {
  type: ClaimFormActionType;
  initialData?: ClaimModel;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface ClaimFormState {
  currentData: ClaimModel;
  initialData: ClaimModel;
  hasChanged: boolean;
}

const initialData: ClaimModel = {
  id: "",
  type: ClaimType.funeral,
  status: ApplicationStatus.created,
  user: {
    uid: "",
    name: "",
    surname: "",
    email: "",
    cell: "",
  },
  idNumber: "",
  policyOrCardNumber: "",
  download: {
    claimFormUrl: "",
  },
  createdAt: "",
  updatedAt: "",
};

const initialState: ClaimFormState = {
  initialData,
  currentData: initialData,
  hasChanged: false,
};

const reducer = (state = initialState, action: ClaimFormAction) => {
  const { type, payload, initialData } = action;
  switch (type) {
    case ClaimFormActionType.INITIALISE:
      if (initialData) {
        return {
          initialData,
          currentData: initialData,
          hasChanged: false,
        };
      } else {
        throw Error(
          `initial data is invalid for action type: ${type.toString()}`
        );
      }
    case ClaimFormActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField(
          { ...state.currentData },
          fieldKey,
          fieldValue
        );
        return {
          ...state,
          currentData,
          hasChanged: haveFieldsChanged(state.initialData, currentData),
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

// Returns true if the current data fields are not equal to the initial data fields.
const haveFieldsChanged = (
  initialData: ClaimModel,
  currentData: ClaimModel
) => {
  const hasTypeChanged = initialData?.type !== currentData?.type;
  return hasTypeChanged;
};

const useClaimFormReducer = (): [
  ClaimFormState,
  React.Dispatch<ClaimFormAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useClaimFormReducer;
