import * as React from "react";
import useSettingsFormReducer, {
  SettingsFormActionType,
} from "../../../../core/hooks/useSettingsFormReducer";
import {
  useAuthUserDoc,
  useFirebaseService,
} from "../../../../core/contexts/firebase";
import LoadingState from "../../../../components/shared/LoadingState";
import Spinner from "../../../../components/shared/Spinner";
import { TypeOptions, toast, Id } from "react-toastify";
import { useAdminMode } from "../../../../core/contexts/ui/admin-mode";
import { useSettings } from "../../../../core/contexts/firebase/settings";
import ApplicationSettingsForm from "../../../../components/forms/ApplicationSettingsForm";
import { DocumentData, DocumentReference } from "firebase/firestore";
import { SettingModel } from "../../../../core/models/setting";
import { FirebaseError } from "firebase/app";
import { ApplicationType } from "../../../../core/enums";

export interface ApplicationSettingsDetailsTabProps {
  docRef: React.MutableRefObject<DocumentReference<DocumentData> | null>;
}

const ApplicationSettingsDetailsTab: React.FC<
  ApplicationSettingsDetailsTabProps
> = ({ docRef }) => {
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [isAdminMode] = useAdminMode();
  const [settingsDoc, isLoaded, isLoading] = useSettings();

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  // Banking details state
  const [settings, settingsDispatch] = useSettingsFormReducer();

  // Pre-populate fields if default values from user document exist
  React.useEffect(() => {
    if (settingsDoc && !isLoading) {
      settingsDispatch({
        type: SettingsFormActionType.INITIALISE,
        initialData: settingsDoc,
      });
    }
  }, [settingsDoc, isLoading]);

  const onSettingsFieldChanged = (fieldKey: string, fieldValue: any) =>
    settingsDispatch({
      type: SettingsFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });

  const onUpdateSettings = async (isValid: boolean) => {
    if (!isValid) return;

    if (!docRef.current) {
      docRef.current = firebaseService?.settingDocRef("settings") ?? null;
    }
    const ref = docRef.current;
    if (firebaseService && ref) {
      makeToast("Updating application settings.", toast.TYPE.INFO, false);
      // Create the default settings information in a new settings document
      try {
        // Save the settings document
        await firebaseService.updateSettingDoc(ref.id, settings.currentData),
          makeToast(
            "Application settings have been updated successfully.",
            toast.TYPE.SUCCESS
          );
      } catch (error) {
        let message =
          "Something went wrong while creating your default settings, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to update. ${error.message}`;
          }
          makeToast(message, toast.TYPE.ERROR, false);
          toastId.current = null;
        }
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl">
      {isLoading ? (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading Settings"
            description="Loading settings, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      ) : (
        <React.Fragment>
          {isLoaded ? (
            <React.Fragment>
              <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Personal Loans
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      When disabled, the personal loan application pages will be
                      blocked from being accessed by applicants with a disabled
                      message to state the reason.
                    </p>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <ApplicationSettingsForm
                      label="Update"
                      settings={settings.currentData.applications.personalLoans}
                      applicationType={ApplicationType.personalLoans}
                      onButtonClick={onUpdateSettings}
                      onFieldChanged={onSettingsFieldChanged}
                    />
                  </div>
                </div>
              </div>

              <div aria-hidden="true">
                <div className="py-8">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Business Loans
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      When disabled, the business loan application pages will be
                      blocked from being accessed by applicants with a disabled
                      message to state the reason.
                    </p>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <ApplicationSettingsForm
                      label="Update"
                      settings={settings.currentData.applications.businessLoans}
                      applicationType={ApplicationType.businessLoans}
                      onButtonClick={onUpdateSettings}
                      onFieldChanged={onSettingsFieldChanged}
                    />
                  </div>
                </div>
              </div>

              <div aria-hidden="true">
                <div className="py-8">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Claims
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      When disabled, the claim application pages will be blocked
                      from being accessed by applicants with a disabled message
                      to state the reason.
                    </p>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <ApplicationSettingsForm
                      label="Update"
                      settings={settings.currentData.applications.claims}
                      applicationType={ApplicationType.claims}
                      onButtonClick={onUpdateSettings}
                      onFieldChanged={onSettingsFieldChanged}
                    />
                  </div>
                </div>
              </div>

              <div aria-hidden="true">
                <div className="py-8">
                  <div className="border-t border-gray-200" />
                </div>
              </div>

              <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                  <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      Insurances
                    </h3>
                    <p className="mt-1 text-sm text-gray-600">
                      When disabled, the insurance application pages will be
                      blocked from being accessed by applicants with a disabled
                      message to state the reason.
                    </p>
                  </div>
                  <div className="mt-5 md:col-span-2 md:mt-0">
                    <ApplicationSettingsForm
                      label="Update"
                      settings={settings.currentData.applications.insurances}
                      applicationType={ApplicationType.insurances}
                      onButtonClick={onUpdateSettings}
                      onFieldChanged={onSettingsFieldChanged}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <h1>No Settings</h1>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default ApplicationSettingsDetailsTab;
