import React, { Reducer } from "react";
import { UploadModel } from "../models/upload";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum UploadFormActionType {
  INITIALISE,
  CHANGE_FIELD,
}

export interface UploadFormAction {
  type: UploadFormActionType;
  initialData?: UploadModel;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface UploadFormState {
  currentData: UploadModel;
  initialData: UploadModel;
  hasChanged: boolean;
}

const initialData: UploadModel = {};

const initialState: UploadFormState = {
  initialData,
  currentData: initialData,
  hasChanged: false,
};

const reducer = (state = initialState, action: UploadFormAction) => {
  const { type, payload, initialData } = action;
  switch (type) {
    case UploadFormActionType.INITIALISE:
      if (initialData) {
        return {
          initialData,
          currentData: initialData,
          hasChanged: false,
        };
      } else {
        throw Error(
          `initialState is invalid for action type: ${type.toString()}`
        );
      }
    case UploadFormActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField(
          { ...state.currentData },
          fieldKey,
          fieldValue
        );
        return {
          ...state,
          currentData,
          hasChanged: haveFieldsChanged(state.initialData, currentData),
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

// Returns true if the current data fields are not equal to the initial data fields.
const haveFieldsChanged = (
  initialData: UploadModel,
  currentData: UploadModel
) => {
  const hasFileChanged = initialData.file !== currentData.file;
  return hasFileChanged;
};

const useUploadFormReducer = (): [
  UploadFormState,
  React.Dispatch<UploadFormAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useUploadFormReducer;
