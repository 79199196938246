import React, { Reducer } from "react";
import {
  EmploymentStatus,
  EmployerDetailsModel,
} from "../models/employer-details";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum EmployerDetailsFormActionType {
  INITIALISE,
  CHANGE_FIELD,
}

export interface EmployerDetailsFormAction {
  type: EmployerDetailsFormActionType;
  initialData?: EmployerDetailsModel;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface EmployerDetailsFormState {
  currentData: EmployerDetailsModel;
  initialData: EmployerDetailsModel;
  hasChanged: boolean;
}

const initialData: EmployerDetailsModel = {
  name: "",
  cell: "",
  employmentStatus: EmploymentStatus.permanent,
  salaryDate: "1",
  address: {
    line1: "",
    line2: "",
    line3: "",
    postalCode: 0,
    formattedPostalCode: "0000",
  },
};

const initialState: EmployerDetailsFormState = {
  initialData,
  currentData: initialData,
  hasChanged: false,
};

const reducer = (state = initialState, action: EmployerDetailsFormAction) => {
  const { type, payload, initialData } = action;
  switch (type) {
    case EmployerDetailsFormActionType.INITIALISE:
      if (initialData) {
        return {
          initialData,
          currentData: initialData,
          hasChanged: false,
        };
      } else {
        throw Error(
          `initial data is invalid for action type: ${type.toString()}`
        );
      }
    case EmployerDetailsFormActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField(
          { ...state.currentData },
          fieldKey,
          fieldValue
        );
        return {
          ...state,
          currentData,
          hasChanged: haveFieldsChanged(state.initialData, currentData),
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

// Returns true if the current data fields are not equal to the initial data fields.
const haveFieldsChanged = (
  initialData: EmployerDetailsModel,
  currentData: EmployerDetailsModel
) => {
  const initialAddress = initialData?.address;
  const currentAddress = currentData?.address;
  const hasNameChanged = initialData.name !== currentData.name;
  const hasPhoneNumberChanged = initialData?.cell !== currentData.cell;
  const hasSalaryDateChanged =
    initialData.salaryDate !== currentData.salaryDate;
  const hasEmploymentStatusChanged =
    initialData.employmentStatus !== currentData.employmentStatus;
  const hasAddressLine1Changed = initialAddress.line1 !== currentAddress.line1;
  const hasAddressLine2Changed = initialAddress.line2 !== currentAddress.line2;
  const hasAddressLine3Changed = initialAddress.line3 !== currentAddress.line3;
  const hasPostalCodeChanged =
    initialAddress.postalCode !== currentAddress.postalCode;
  return (
    hasNameChanged ||
    hasPhoneNumberChanged ||
    hasSalaryDateChanged ||
    hasEmploymentStatusChanged ||
    hasAddressLine1Changed ||
    hasAddressLine2Changed ||
    hasAddressLine3Changed ||
    hasPostalCodeChanged
  );
};

const useEmployerDetailsFormReducer = (): [
  EmployerDetailsFormState,
  React.Dispatch<EmployerDetailsFormAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useEmployerDetailsFormReducer;
