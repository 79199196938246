import { AddressModel } from "./address";

export enum EmploymentStatus {
  permanent = "permanent",
  employed = "employed",
  contract = "contract",
}

export interface EmployerDetailsModel {
  name: string;
  cell: string;
  salaryDate: string;
  employmentStatus: EmploymentStatus;
  branchCode?: string;
  address: AddressModel;
}
