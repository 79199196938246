import React from "react";
import {
  formatAlgoliaDate,
  formatAlgoliaTime,
  formatDate,
  formatTime,
} from "../../core/utils/format";

export interface FormattedDateTimeProps {
  date: any;
  isSearchResult?: boolean;
}

const FormattedDateTime: React.FC<FormattedDateTimeProps> = (props) => {
  const { date, isSearchResult = false } = props;
  const createdAtDate =
    date && !isSearchResult
      ? new Date((date as any).seconds * 1000)
      : new Date(date);

  return (
    <React.Fragment>
      {createdAtDate ? (
        <span>
          <time dateTime={createdAtDate.toUTCString()}>
            {`${formatDate(createdAtDate)} - ${formatTime(createdAtDate)}`}
          </time>
        </span>
      ) : null}
    </React.Fragment>
  );
};

export default FormattedDateTime;
