import * as React from "react";

export interface LastNameFieldProps {
  line: string;
  lineNumber: 1 | 2 | 3;
  onChange: (lastName: string) => void;
}

const LastNameField = ({ line, lineNumber, onChange }: LastNameFieldProps) => {
  const autoCompleteValue = `address-line${lineNumber}`;
  let placeholder: string;
  switch (lineNumber) {
    case 1:
      placeholder = "21 Miracle Road";
      break;
    case 2:
      placeholder = "Georgetown";
      break;
    case 3:
      placeholder = "Victorian Valley Estate";
      break;

    default:
      placeholder = "";
  }
  return (
    <div className="space-y-1">
      <label
        htmlFor={autoCompleteValue}
        className="block text-sm font-medium text-gray-700"
      >
        Address Line {`${lineNumber}${lineNumber === 3 ? " (Optional)" : ""}`}
      </label>
      <input
        placeholder={placeholder}
        id={autoCompleteValue}
        name={autoCompleteValue}
        type="text"
        autoComplete={autoCompleteValue}
        value={line}
        onChange={(e) => onChange(e.target.value)}
        required={lineNumber !== 3}
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      />
    </div>
  );
};

export default LastNameField;
