import { XCircleIcon } from "@heroicons/react/24/outline";
import { FirebaseError } from "firebase/app";
import { navigate } from "gatsby";
import * as React from "react";
import { Id, toast, TypeOptions } from "react-toastify";
import CreateAffordabilityForm from "../../../components/forms/CreateAffordabilityForm";
import {
  useAuthUser,
  useAuthUserDoc,
  useFirebaseService,
} from "../../../core/contexts/firebase";
import useAffordabilityFormReducer, {
  AffordabilityFormActionType,
} from "../../../core/hooks/useAffordabilityFormReducer";

const EditAffordabilityPage = () => {
  const user = useAuthUser();
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();

  // Banking details state
  const [affordability, affordabilityDispatch] = useAffordabilityFormReducer();
  const onAffordabilityFieldChanged = (fieldKey: string, fieldValue: any) =>
    affordabilityDispatch({
      type: AffordabilityFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });

  // Pre-populate banking details fields if default values from user document exist
  React.useEffect(() => {
    const defaultAffordability = userDoc?.defaultAffordability;
    if (defaultAffordability) {
      affordabilityDispatch({
        type: AffordabilityFormActionType.INITIALISE,
        initialData: defaultAffordability,
      });
    }
  }, [userDoc]);

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  const onSaveAffordability = async (isValid: boolean) => {
    const hasChanged = affordability.hasChanged;
    if (isValid && !hasChanged) {
      makeToast(
        "Please make at least one change before saving.",
        toast.TYPE.INFO
      );
    }
    if (isValid && hasChanged && user && firebaseService) {
      makeToast("Saving profile changes.", toast.TYPE.INFO, false);
      // Save the user's profile information in their user document
      const { uid } = user;
      try {
        console.log(affordability.currentData);
        await firebaseService.updateUserDoc(
          uid,
          Object.assign({
            defaultAffordability: affordability.currentData,
            updatedAt: "",
          })
        );
        makeToast(
          "Affordability changes saved successfully.",
          toast.TYPE.SUCCESS
        );
        navigate(-1);
      } catch (error) {
        let message =
          "Something went wrong while authenticating your account, please try again later";
        console.log(JSON.stringify(error));
        if (error instanceof FirebaseError) {
          console.log(JSON.stringify(error));
          console.log(error.message);
          console.log(JSON.stringify(error.customData));
          if (error.code === "invalid-argument") {
            message = `Unable to save. ${error.message}`;
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="mx-auto mt-16 px-4 sm:px-6 lg:max-w-6xl lg:px-8">
        <div className="flex justify-between gap-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900 lg:text-2xl">
            Edit Affordability
          </h3>
          <button
            type="button"
            onClick={() => {
              if (affordability.hasChanged) {
                // TODO: Ask user if they are sure because there are unsaved changes
                navigate(-1);
              } else {
                navigate(-1);
              }
            }}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
          >
            <XCircleIcon className="-ml-1 mr-2 h-5 w-5 text-gray-600" />
            Cancel
          </button>
        </div>
        <p className="mt-2 mb-8 text-sm text-gray-500">
          This information will be used to uniquely identify your account so be
          sure to pay extra attention to detail before you save.
        </p>
        <CreateAffordabilityForm
          label="Save"
          affordability={affordability.currentData}
          onFieldChanged={onAffordabilityFieldChanged}
          onButtonClick={onSaveAffordability}
        />
      </div>
    </React.Fragment>
  );
};

export default EditAffordabilityPage;
