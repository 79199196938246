import {
  collection,
  collectionGroup,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import * as React from "react";
import { useAuthIsConnected, useAuthUser, useFirebaseService } from ".";
import { LoanModel } from "../../models/loan";
import { PaginationMetadata } from "../../models/pagination-metadata";

type ContextState = {
  personalLoans: LoanModel[];
  allPersonalLoans: LoanModel[];
  detailPersonalLoan?: LoanModel;
  paginationMetadata: PaginationMetadata;
  paginationMetadataForAll: PaginationMetadata;
  getNextPersonalLoans: (userOnly: boolean) => Promise<void>;
  getNewPersonalLoan: () => Promise<void>;
  getPersonalLoanById: (id: string, userOnly: boolean) => Promise<void>;
};

const initialState: ContextState = {
  personalLoans: [],
  allPersonalLoans: [],
  paginationMetadata: {
    hitsPerPage: 20,
    hasNext: true,
  },
  paginationMetadataForAll: {
    hitsPerPage: 20,
    hasNext: true,
  },
  getNextPersonalLoans: () => Promise.resolve(),
  getNewPersonalLoan: () => Promise.resolve(),
  getPersonalLoanById: (id: string, userOnly: boolean) => Promise.resolve(),
};

export const PersonalLoansContext =
  React.createContext<ContextState>(initialState);

type PersonalLoansContextProviderProps = {
  children: React.ReactNode;
};

const PersonalLoansContextProvider = ({
  children,
}: PersonalLoansContextProviderProps) => {
  const user = useAuthUser();
  const isConnected = useAuthIsConnected();
  const firebase = useFirebaseService();
  const [lastVisible, setLastVisible] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [lastVisibleForAll, setLastVisibleForAll] =
    React.useState<QueryDocumentSnapshot<DocumentData>>();
  const [personalLoans, setPersonalLoans] = React.useState<LoanModel[]>(
    initialState.personalLoans
  );
  const [allPersonalLoans, setAllPersonalLoans] = React.useState<LoanModel[]>(
    initialState.allPersonalLoans
  );
  const [paginationMetadata, setPaginationMetadata] =
    React.useState<PaginationMetadata>(initialState.paginationMetadata);
  const [paginationMetadataForAll, setPaginationMetadataForAll] =
    React.useState<PaginationMetadata>(initialState.paginationMetadata);
  const [detailPersonalLoan, setDetailPersonalLoan] = React.useState<
    LoanModel | undefined
  >(initialState.detailPersonalLoan);

  // This effect updates the other cached lists of insurances with the latest
  // of the detail insurance document.
  React.useEffect(() => {
    if (detailPersonalLoan != undefined) {
      const userIns = personalLoans.find(
        (insurance) => insurance.id === detailPersonalLoan.id
      );
      const allIns = allPersonalLoans.find(
        (insurance) => insurance.id === detailPersonalLoan.id
      );
      if (userIns) {
        const i = personalLoans.indexOf(userIns);
        const update = Array.from(personalLoans);
        update[i] = detailPersonalLoan;
        setPersonalLoans(update);
        setLastVisible(undefined);
      }
      if (allIns) {
        const i = allPersonalLoans.indexOf(allIns);
        const update = Array.from(allPersonalLoans);
        update[i] = detailPersonalLoan;
        setAllPersonalLoans(update);
        setLastVisibleForAll(undefined);
      }
    }
  }, [detailPersonalLoan]);

  const getNextPersonalLoans = async (userOnly: boolean): Promise<void> => {
    const { hitsPerPage: pageLimit, hasNext } = userOnly
      ? paginationMetadata
      : paginationMetadataForAll;
    if (!hasNext) return;

    if (isConnected && user && firebase) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      const wrapWithQuery = (ref: Query<DocumentData>) =>
        query(ref, orderBy("createdAt", "desc"), limit(pageLimit));
      if (userOnly) {
        ref = collection(firebase.db, "users", user.uid, "personal_loans");
        ref = wrapWithQuery(ref);
        if (lastVisible) ref = query(ref, startAfter(lastVisible));
      } else {
        ref = collectionGroup(firebase.db, "personal_loans");
        ref = wrapWithQuery(ref);
        if (lastVisibleForAll) ref = query(ref, startAfter(lastVisibleForAll));
      }
      const data = await getDocs(ref);

      const nextBatch = data.docs.map((doc) => doc.data() as LoanModel);
      const nextBatchLast = nextBatch[nextBatch.length - 1];
      if (userOnly) {
        const last = personalLoans[personalLoans.length - 1];
        if (nextBatchLast?.id !== last?.id) {
          setPersonalLoans([...personalLoans, ...nextBatch]);
          setLastVisible(data.docs[data.docs.length - 1]);
          setPaginationMetadata({
            ...paginationMetadata,
            hasNext: data.size === pageLimit,
          });
        }
      } else {
        const last = allPersonalLoans[allPersonalLoans.length - 1];
        if (nextBatchLast?.id !== last?.id) {
          setAllPersonalLoans([...allPersonalLoans, ...nextBatch]);
          setLastVisibleForAll(data.docs[data.docs.length - 1]);
          setPaginationMetadataForAll({
            ...paginationMetadataForAll,
            hasNext: data.size === pageLimit,
          });
        }
      }
    } else {
      return Promise.reject("firebase service is undefined");
    }
  };

  const getPersonalLoanById = async (
    id: string,
    userOnly: boolean
  ): Promise<void> => {
    const c = personalLoans.find((loan) => loan.id === id);
    if (c) {
      setDetailPersonalLoan(c);
    } else if (isConnected && firebase && user) {
      let ref: Query<DocumentData>;
      userOnly = userOnly != undefined && userOnly;
      if (userOnly) {
        const personalLoanDoc = await firebase.businessLoanDoc(user.uid, id);
        setDetailPersonalLoan(personalLoanDoc.data() as LoanModel);
      } else {
        ref = collectionGroup(firebase.db, "personal_loans");
        ref = query(ref, where("id", "==", id));
        const { docs } = await getDocs(ref);
        if (docs.length === 1) {
          setDetailPersonalLoan(docs[0].data() as LoanModel);
        }
      }
    }
  };

  const getNewPersonalLoan = async () => {
    if (isConnected && firebase && user) {
      const ref = collection(firebase.db, "users", user.uid, "personal_loans");
      const q = query(ref, orderBy("createdAt", "desc"), limit(1));
      const data = await getDocs(q);
      if (data.size === 1) {
        const oldPersonalLoan = personalLoans[0];
        const newPersonalLoan = data.docs[0].data() as LoanModel;
        if (newPersonalLoan.id !== oldPersonalLoan?.id) {
          setPersonalLoans([newPersonalLoan, ...personalLoans]);
        }
      }
    }
  };

  return (
    <PersonalLoansContext.Provider
      value={{
        personalLoans,
        allPersonalLoans,
        detailPersonalLoan,
        paginationMetadata,
        paginationMetadataForAll,
        getNextPersonalLoans,
        getNewPersonalLoan,
        getPersonalLoanById,
      }}
    >
      {children}
    </PersonalLoansContext.Provider>
  );
};

const usePersonalLoans = (): [
  LoanModel[],
  PaginationMetadata,
  (userOnly: boolean) => Promise<void>,
  () => Promise<void>
] => {
  const context = React.useContext(PersonalLoansContext);
  if (context === undefined) {
    throw new Error(
      "usePersonalLoans must be used within a PersonalLoansProvider"
    );
  }
  return [
    context.personalLoans,
    context.paginationMetadata,
    context.getNextPersonalLoans,
    context.getNewPersonalLoan,
  ];
};

const useAllPersonalLoans = (): [
  LoanModel[],
  PaginationMetadata,
  (userOnly: boolean) => Promise<void>
] => {
  const context = React.useContext(PersonalLoansContext);
  if (context === undefined) {
    throw new Error(
      "useAllPersonalLoans must be used within a PersonalLoansProvider"
    );
  }
  return [
    context.allPersonalLoans,
    context.paginationMetadataForAll,
    context.getNextPersonalLoans,
  ];
};

const usePersonalLoanById = (): [
  LoanModel | undefined,
  (id: string, userOnly: boolean) => Promise<void>
] => {
  const context = React.useContext(PersonalLoansContext);
  if (context === undefined) {
    throw new Error(
      "usePersonalLoanById must be used within a PersonalLoansProvider"
    );
  }
  return [context.detailPersonalLoan, context.getPersonalLoanById];
};

export {
  PersonalLoansContextProvider,
  usePersonalLoans,
  useAllPersonalLoans,
  usePersonalLoanById,
};
