import * as React from "react";
import { useLocation } from "@reach/router";
import AffordabilityDetails from "../../../../components/details/AffordabilityDetails";
import useAffordabilityFormReducer, {
  AffordabilityFormActionType,
} from "../../../../core/hooks/useAffordabilityFormReducer";
import useEmployerDetailsFormReducer, {
  EmployerDetailsFormActionType,
} from "../../../../core/hooks/useEmployerDetailsFormReducer";
import EmployerDetails from "../../../../components/details/EmployerDetails";
import useBankingDetailsFormReducer, {
  BankingDetailsFormActionType,
} from "../../../../core/hooks/useBankingDetailsFormReducer";
import BankingDetails from "../../../../components/details/BankingDetails";
import UploadCard from "../../../../components/shared/cards/UploadCard";
import EmptyState from "../../../../components/shared/EmptyState";
import { FolderOpenIcon } from "@heroicons/react/24/outline";
import LoanListItem from "../../../../components/lists/LoanListItem";
import { usePersonalLoanById } from "../../../../core/contexts/firebase/personal-loans";
import AddressDetails from "../../../../components/details/AddressDetails";
import useAddressFormReducer, {
  AddressFormActionType,
} from "../../../../core/hooks/useAddressFormReducer";
import {
  useAuthUserDoc,
  useFirebaseService,
} from "../../../../core/contexts/firebase";
import LoadingState from "../../../../components/shared/LoadingState";
import Spinner from "../../../../components/shared/Spinner";
import { Id, toast, TypeOptions } from "react-toastify";
import { usePersonalLoanStatuses } from "../../../../core/contexts/firebase/statuses/personal-loans";
import { ApplicationStatus } from "../../../../core/enums";
import { FirebaseError } from "firebase/app";
import { StatusModel } from "../../../../core/models/status";
import ApplicationStatusPanel from "../status-panel";
import ApplicationStatusChangeDialog from "../../../../components/shared/dialogs/ApplicationStatusChangeDialog";
import { useAdminMode } from "../../../../core/contexts/ui/admin-mode";
import { buildUserFullName } from "../../../../core/ui/builds";

const UserLoanDetailsPage = () => {
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [isAdminMode] = useAdminMode();
  const location = useLocation();
  const parts = location.pathname.split("/");
  const [personalLoanDoc, getPersonalLoanById] = usePersonalLoanById();
  const [isLoading, setIsLoading] = React.useState(false);
  const [statuses, sPagination, getNextPersonalLoanStatuses] =
    usePersonalLoanStatuses();
  const [selectedStatus, setSelectedStatus] = React.useState<ApplicationStatus>(
    ApplicationStatus.created
  );
  const [openTimeline, setOpenTimeline] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  React.useEffect(() => {
    if (!isLoading && userDoc) {
      setIsLoading(true);
      const id = parts[parts.length - 1];
      getPersonalLoanById(id, !isAdminMode)
        .then(() => getNextPersonalLoanStatuses(id, !isAdminMode, true))
        .then(() => setIsLoading(false));
    }
  }, [userDoc, isAdminMode]);

  // Banking details state
  const [affordability, affordabilityDispatch] = useAffordabilityFormReducer();
  const [address, addressDispatch] = useAddressFormReducer();
  const [employerDetails, employerDetailsDispatch] =
    useEmployerDetailsFormReducer();
  const [bankingDetails, bankingDetailsDispatch] =
    useBankingDetailsFormReducer();

  // Pre-populate fields if default values from user document exist
  React.useEffect(() => {
    if (personalLoanDoc) {
      const { address, affordability, employerDetails, bankingDetails } =
        personalLoanDoc;
      if (address) {
        addressDispatch({
          type: AddressFormActionType.INITIALISE,
          initialData: address,
        });
      }
      if (affordability) {
        affordabilityDispatch({
          type: AffordabilityFormActionType.INITIALISE,
          initialData: affordability,
        });
      }
      if (employerDetails) {
        employerDetailsDispatch({
          type: EmployerDetailsFormActionType.INITIALISE,
          initialData: employerDetails,
        });
      }
      if (bankingDetails) {
        bankingDetailsDispatch({
          type: BankingDetailsFormActionType.INITIALISE,
          initialData: bankingDetails,
        });
      }
    }
  }, [personalLoanDoc]);

  const onChangeStatus = async (status: ApplicationStatus) => {
    if (userDoc && firebaseService && personalLoanDoc) {
      makeToast("Updating application's status.", toast.TYPE.INFO, false);
      // Save the user's default information in their user document
      const { uid, email, cell, name, surname } = userDoc;
      const personalLoanUid = personalLoanDoc.user.uid;
      const id = parts[parts.length - 1];
      try {
        const claimDocUpdate = {
          status,
          updatedAt: "",
        };
        const statusDocUpdate: StatusModel = {
          id: "",
          applicationId: id,
          user: { uid, email, cell, name, surname },
          status,
          createdAt: "",
          updatedAt: "",
        };
        await Promise.all([
          firebaseService.updateLoanDoc(
            personalLoanUid,
            id,
            Object.assign(claimDocUpdate)
          ),
          firebaseService.setPersonalLoanStatusDoc(
            personalLoanUid,
            id,
            statusDocUpdate
          ),
        ]);
        await getPersonalLoanById(id, !isAdminMode)
          .then(() => getNextPersonalLoanStatuses(id, !isAdminMode, true))
          .then(() => setIsLoading(false));
        makeToast("Status updated successfully.", toast.TYPE.SUCCESS);
      } catch (error) {
        console.log(error);
        let message =
          "Something went wrong while updating application's status, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to update defaults. ${error.message}`;
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl">
      {isLoading ? (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading Personal Loan Application"
            description="Loading personal loan application, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      ) : (
        <React.Fragment>
          <p className="mt-12 mb-8 text-base text-gray-500">
            View{" "}
            {buildUserFullName(!isAdminMode, "your", personalLoanDoc?.user)}{" "}
            loan's status, amount, address, employer details, affordability
            details, banking details and uploaded documents.
          </p>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            {personalLoanDoc && (
              <LoanListItem
                loan={personalLoanDoc}
                onViewTimeline={() => setOpenTimeline(true)}
                onChangeStatus={
                  isAdminMode
                    ? (status) => {
                        setSelectedStatus(status);
                        setOpenDialog(true);
                      }
                    : undefined
                }
              />
            )}
          </p>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Address details
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            {personalLoanDoc?.address ? (
              <AddressDetails address={address.currentData} />
            ) : (
              <EmptyState
                title="No Address Details Found"
                description="Oops! Seems like we could not find any address details linked to this loan record. Please contact support for help."
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
              />
            )}
          </div>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Employer details
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            {personalLoanDoc?.employerDetails ? (
              <EmployerDetails employerDetails={employerDetails.currentData} />
            ) : (
              <EmptyState
                title="No Employer Details Found"
                description="Oops! Seems like we could not find any employer details linked to this loan record. Please contact support for help."
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
              />
            )}
          </div>
          <p className="col-span-2 mt-12 text-2xl font-medium">Affordability</p>
          <div className="mt-6 rounded-md border-2 p-4">
            {personalLoanDoc?.affordability ? (
              <AffordabilityDetails affordability={affordability.currentData} />
            ) : (
              <EmptyState
                title="No Affordability Details Found"
                description="Oops! Seems like we could not find any affordability details linked to this loan record. Please contact support for help."
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
              />
            )}
          </div>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Banking details
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            {personalLoanDoc?.bankingDetails ? (
              <BankingDetails bankingDetails={bankingDetails.currentData} />
            ) : (
              <EmptyState
                title="No Banking Details Found"
                description="Oops! Seems like we could not find any banking details linked to this loan record. Please contact support for help."
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
              />
            )}
          </div>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Uploaded documents
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <UploadCard
                label="Bank statement"
                downloadUrl={personalLoanDoc?.download.bankStatementUrl}
              />
              <UploadCard
                label="Payslip"
                downloadUrl={personalLoanDoc?.download.payslipUrl}
              />
              <UploadCard
                label="ID copy"
                downloadUrl={personalLoanDoc?.download.idCopyUrl}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <ApplicationStatusPanel
        statuses={statuses}
        isOpen={openTimeline}
        onClose={setOpenTimeline}
      />
      <ApplicationStatusChangeDialog
        status={selectedStatus}
        isOpen={openDialog}
        onClose={setOpenDialog}
        onChange={onChangeStatus}
      />
    </div>
  );
};

export default UserLoanDetailsPage;
