import * as React from "react";
import { AddressModel } from "../../core/models/address";
import AddressLineField from "../fields/AddressLineField";
import PostalCodeField from "../fields/PostalCode";

export interface CreateAddressFormProps {
  label: string;
  address: AddressModel;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onFieldChanged: (fieldKey: string, fieldValue: any) => void;
}

const CreateAddressForm = (props: CreateAddressFormProps) => {
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isFormValid = event.currentTarget.checkValidity();
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="grid grid-cols-2 gap-6">
      <div className="col-span-2">
        <AddressLineField
          lineNumber={1}
          line={props.address.line1}
          onChange={(v) => props.onFieldChanged("line1", v)}
        />
      </div>
      <div className="col-span-2">
        <AddressLineField
          lineNumber={2}
          line={props.address.line2}
          onChange={(v) => props.onFieldChanged("line2", v)}
        />
      </div>
      <div className="col-span-2">
        <AddressLineField
          lineNumber={3}
          line={props.address.line3 ?? ""}
          onChange={(v) => props.onFieldChanged("line3", v)}
        />
      </div>
      <div className="col-span-2">
        <PostalCodeField
          postalCode={props.address.postalCode}
          onChange={(v) => props.onFieldChanged("postalCode", v)}
        />
      </div>
      <div className="col-span-2 flex gap-8">
        <button
          type={props.type ?? "submit"}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {props.label}
        </button>
      </div>
    </form>
  );
};

export default CreateAddressForm;
