import React, { Fragment, useState, ReactNode } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  Bars3CenterLeftIcon,
  BuildingOffice2Icon,
  ClockIcon,
  Cog6ToothIcon,
  HomeIcon,
  MagnifyingGlassCircleIcon,
  MagnifyingGlassIcon,
  ShieldCheckIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { toast } from "react-toastify";
import {
  useAuthIsAuthenticated,
  useAuthIsAuthenticating,
  useAuthIsConnected,
  useAuthIsConnecting,
  useAuthUserDoc,
  useAuthUserIsAdmin,
  useFirebaseService,
} from "../../../core/contexts/firebase";
import UserAvatar from "../../shared/UserAvatar";
import Loader from "../../shared/Loader";
import CommandPalette from "../../shared/CommandPalette";
import { useCommandPaletteIsOpen } from "../../../core/contexts/ui/command-palette";
import { useAdminMode } from "../../../core/contexts/ui/admin-mode";
import ElevatedButton from "../../shared/buttons/ElevatedButton";
import { buttonClassNames } from "../../../core/ui/classNames";
import { Cog8ToothIcon } from "@heroicons/react/24/solid";

const navigation = [
  { name: "My Home", to: "/dashboard", icon: HomeIcon },
  {
    name: "My Applications",
    to: "/dashboard/applications",
    icon: ClockIcon,
  },
  { name: "My Profile", to: "/dashboard/profile", icon: UserIcon },
  // { name: "My Settings", to: "/dashboard/settings", icon: Cog8ToothIcon },
];
const secondaryNavigation = [
  { name: "Privacy", to: "/dashboard/privacy", icon: ShieldCheckIcon },
  { name: "Terms", to: "/dashboard/terms", icon: UserCircleIcon },
];
const adminNavigation = [
  {
    name: "All Applications",
    to: "/dashboard/admin/applications",
    icon: ArchiveBoxIcon,
  },
  {
    name: "All Applicants",
    to: "/dashboard/admin/applicants",
    icon: UserGroupIcon,
  },
  {
    name: "Advanced Search",
    to: "/dashboard/admin/search",
    icon: MagnifyingGlassIcon,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export interface DashboardShellProps {
  children: ReactNode;
}

const DashboardShell = ({ children }: DashboardShellProps) => {
  const isConnecting = useAuthIsConnecting();
  const isAuthenticating = useAuthIsAuthenticating();
  const isConnected = useAuthIsConnected();
  const isAuthenticated = useAuthIsAuthenticated();
  const isAdmin = useAuthUserIsAdmin();
  const [isAdminMode, setIsAdminMode] = useAdminMode();
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [_, setIsOpen] = useCommandPaletteIsOpen();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSignOut = async () => {
    try {
      await firebaseService?.doSignOut();
      toast.success("Account was signed out of successfully");
      navigate("/auth/signin");
    } catch (error) {
      toast.error("Something went wrong, please try again later");
    }
  };

  React.useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate(
        `/auth/signin?redirectUrl=${location.pathname}&redirectSearch=${location.search}`
      );
    }
  }, [isConnected, isConnecting, isAuthenticating, isAuthenticated, userDoc]);

  return (
    <>
      <div id="recaptcha-container-id"></div>
      <CommandPalette />
      <div className="min-h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-slate-200 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Link
                      to="/dashboard"
                      className="focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <StaticImage
                        src="../../../images/logo.png"
                        quality={100}
                        class="-translate-x-3"
                        height={80}
                        alt="Nora Finance logo with text"
                      />
                    </Link>
                  </div>
                  <nav
                    className="mt-12 flex h-full flex-shrink-0 flex-col justify-between divide-y divide-indigo-800 overflow-y-auto"
                    aria-label="Sidebar"
                  >
                    <div className="divide-y divide-indigo-800">
                      {isConnected && isAdmin && isAdminMode && (
                        <div>
                          <p className="mb-2 ml-3 px-2 text-lg font-medium text-indigo-800">
                            Administration
                          </p>
                          <div className="space-y-1 px-2">
                            {adminNavigation.map((item) => (
                              <Link
                                key={item.name}
                                to={item.to}
                                activeClassName={classNames(
                                  "bg-indigo-800 text-white group flex items-center rounded-md px-2 py-2 text-base font-medium leading-6"
                                )}
                                className={classNames(
                                  "group flex items-center rounded-md px-2 py-2 text-base font-medium leading-6 hover:bg-indigo-200"
                                )}
                              >
                                <item.icon
                                  className="mr-4 h-6 w-6 text-indigo-400"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      )}
                      {!isAdminMode && (
                        <div
                          className={`${
                            isAdminMode ? "mt-6 pt-3" : ""
                          } space-y-1 px-2`}
                        >
                          <p className="mb-2 px-2 text-lg font-medium text-indigo-800">
                            General
                          </p>
                          {navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.to}
                              activeClassName={classNames(
                                "bg-indigo-800 text-white group flex items-center rounded-md px-2 py-2 text-base font-medium leading-6"
                              )}
                              className={classNames(
                                "group flex items-center rounded-md px-2 py-2 text-base font-medium leading-6 hover:bg-indigo-200"
                              )}
                            >
                              <item.icon
                                className="mr-4 h-6 w-6 flex-shrink-0 text-indigo-400"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      )}
                      <div className="mt-6 pt-3">
                        <p className="mb-2 ml-3 px-2 text-lg font-medium text-indigo-800">
                          Legal
                        </p>
                        <div className="space-y-1 px-2">
                          {secondaryNavigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.to}
                              activeClassName={classNames(
                                "bg-indigo-800 text-white group flex items-center rounded-md px-2 py-2 text-base font-medium leading-6"
                              )}
                              className={classNames(
                                "group flex items-center rounded-md px-2 py-2 text-base font-medium leading-6 hover:bg-indigo-200"
                              )}
                            >
                              <item.icon
                                className="mr-4 h-6 w-6 text-indigo-400"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="relative bottom-32 overflow-hidden">
                      <img
                        className="absolute inset-0 top-0 left-0 right-0 h-full w-full object-cover shadow-sm"
                        src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                        alt=""
                      />
                      <div
                        className="absolute inset-0 bg-indigo-800 mix-blend-multiply"
                        aria-hidden="true"
                      ></div>
                      <p className="relative py-6 px-4 md:px-6 lg:px-8">
                        <p className="text-xl font-bold leading-[40px] text-indigo-100">
                          The Dashboard
                        </p>
                        <p className="mt-4 text-base font-light text-indigo-200">
                          Discover a new and easy way of managing your finances.
                        </p>
                      </p>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="z-20 hidden shadow-xl lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col bg-slate-200 pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <Link
                to="/dashboard"
                className="focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <StaticImage
                  src="../../../images/logo.png"
                  quality={100}
                  class="-translate-x-3"
                  height={80}
                  alt="Nora Finance logo with text"
                />
              </Link>
            </div>
            <nav
              className="mt-12 flex flex-1 flex-col divide-y divide-indigo-800 overflow-y-auto"
              aria-label="Sidebar"
            >
              {isConnected && isAdmin && isAdminMode && (
                <div>
                  <p className="mb-2 ml-3 px-2 text-lg font-medium text-indigo-800">
                    Administration
                  </p>
                  <div className="space-y-1 px-2">
                    {adminNavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        activeClassName={classNames(
                          "bg-indigo-800 text-white group flex items-center rounded-md px-2 py-2 text-sn font-medium leading-6"
                        )}
                        className={classNames(
                          "group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 hover:bg-indigo-200"
                        )}
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 text-indigo-400"
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              {!isAdminMode && (
                <div
                  className={`${isAdminMode ? "mt-6 pt-3" : ""} space-y-1 px-2`}
                >
                  <p className="mb-2 px-2 text-lg font-medium text-indigo-800">
                    General
                  </p>
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      activeClassName={classNames(
                        "bg-indigo-800 text-white group flex items-center rounded-md px-2 py-2 text-sn font-medium leading-6"
                      )}
                      className={classNames(
                        "group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 hover:bg-indigo-200"
                      )}
                    >
                      <item.icon
                        className="mr-4 h-6 w-6 flex-shrink-0 text-indigo-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </div>
              )}
              <div className="mt-6 pt-3">
                <p className="mb-2 ml-3 px-2 text-lg font-medium text-indigo-800">
                  Legal
                </p>
                <div className="space-y-1 px-2">
                  {secondaryNavigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      activeClassName={classNames(
                        "bg-indigo-800 text-white group flex items-center rounded-md px-2 py-2 text-sn font-medium leading-6"
                      )}
                      className={classNames(
                        "group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 hover:bg-indigo-200"
                      )}
                    >
                      <item.icon
                        className="mr-4 h-6 w-6 text-indigo-400"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </nav>
            <div className="relative overflow-hidden">
              <img
                className="absolute inset-0 top-0 left-0 right-0 h-full w-full object-cover shadow-sm"
                src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                alt=""
              />
              <div
                className="absolute inset-0 bg-indigo-800 mix-blend-multiply"
                aria-hidden="true"
              ></div>
              <p className="relative py-6 px-4 md:px-6 lg:px-8">
                <p className="text-xl font-bold leading-[40px] text-indigo-100">
                  The Dashboard
                </p>
                <p className="mt-4 text-base font-light text-indigo-200">
                  Discover a new and easy way of managing your finances.
                </p>
              </p>
            </div>
          </div>
        </div>

        <div className="relative flex flex-1 flex-col lg:pl-64">
          <div className="sticky top-0 z-20 flex h-16 flex-shrink-0 border-b border-indigo-500 bg-slate-200 shadow-xl lg:border-none">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            {/* Search bar */}
            <div className="flex flex-1 items-center justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
              <div></div>
              <div className="ml-4 flex items-center gap-x-2 md:ml-6">
                {/* Profile dropdown */}
                <Link
                  to="/"
                  activeClassName="after:!bg-indigo-500"
                  className="text-md relative inline-flex items-center px-2 py-2 font-medium leading-4 text-gray-500 after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[4px] after:overflow-hidden after:rounded-sm after:bg-transparent after:content-[''] hover:text-gray-900 hover:after:animate-pulse hover:after:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Leave Dashboard
                </Link>
                {isAdmin && isAdminMode && (
                  <button
                    type="button"
                    onClick={() => setIsOpen(true)}
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-gray-500 bg-slate-200 text-black shadow-sm hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <MagnifyingGlassIcon
                      className="h-4 w-4"
                      aria-hidden="true"
                    />
                  </button>
                )}
                <Menu as="div" className="relative ml-3">
                  <div className="rounded-full bg-gray-800">
                    {isAuthenticated && isConnected && userDoc ? (
                      <div className="relative">
                        {isAdminMode && (
                          <div className="absolute -inset-[4px] animate-pulse rounded-full bg-red-400"></div>
                        )}
                        <Menu.Button className="relative flex text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                          <span className="rounded-full bg-indigo-800">
                            <UserAvatar size="xsmall" />
                          </span>
                        </Menu.Button>
                      </div>
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-2 px-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/dashboard/profile"
                            className={classNames(
                              active ? "after:!bg-indigo-500" : "",
                              "text-md relative block items-center py-4 font-medium leading-4 text-gray-500 after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:overflow-hidden after:rounded-sm after:bg-transparent after:content-[''] hover:text-gray-900 hover:after:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            )}
                          >
                            Profile
                          </Link>
                        )}
                      </Menu.Item>
                      {isAdminMode && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/dashboard/admin/settings"
                              className={classNames(
                                active ? "after:!bg-indigo-500" : "",
                                "text-md relative block items-center py-4 font-medium leading-4 text-gray-500 after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:overflow-hidden after:rounded-sm after:bg-transparent after:content-[''] hover:text-gray-900 hover:after:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              )}
                            >
                              Settings
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={onSignOut}
                            className={classNames(
                              active ? "after:!bg-indigo-500" : "",
                              "text-md relative block w-full items-center py-4 text-start font-medium leading-4 text-gray-500 after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[2px] after:overflow-hidden after:rounded-sm after:bg-transparent after:content-[''] hover:text-gray-900 hover:after:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            )}
                          >
                            Sign Out
                          </button>
                        )}
                      </Menu.Item>
                      {isAdmin && (
                        <React.Fragment>
                          <div className="pt-2 pb-4">
                            <div className="h-[1px] w-full bg-indigo-200"></div>
                          </div>
                          <Menu.Item as="div" className="mt-2 mb-4">
                            {({ active }) => {
                              return (
                                <ElevatedButton
                                  isOutline
                                  isFullWidth
                                  onClick={() => {
                                    setIsAdminMode(!isAdminMode);
                                    navigate(
                                      !isAdminMode
                                        ? "/dashboard/admin/applications"
                                        : "/dashboard"
                                    );
                                  }}
                                >
                                  {isAdminMode
                                    ? "Switch to Applicant"
                                    : "Switch to Admin"}
                                </ElevatedButton>
                              );
                            }}
                          </Menu.Item>
                        </React.Fragment>
                      )}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <div className="relative pb-24">{children}</div>
        </div>
      </div>
    </>
  );
};

export default DashboardShell;
