/**
 * Returns an updated object with the new value at the field key.
 *
 * It is also capable of updating nested fields.
 */
export const updateField = (
  object: any,
  fieldKey: string,
  fieldValue: string
) => {
  const pathArray = fieldKey.split(".");
  const lastNode = pathArray[pathArray.length - 1];
  let node = object;
  pathArray.forEach(
    (path, index) => (node = index === pathArray.length - 1 ? node : node[path])
  );
  node[lastNode] = fieldValue;
  return object;
};
