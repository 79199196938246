import { FolderOpenIcon } from "@heroicons/react/24/outline";
import * as React from "react";
import LoadMoreListItem from "../../../../components/lists/LoadMoreListItem";
import UserListItem from "../../../../components/lists/UserListItem";
import DashboardHero from "../../../../components/shared/DashboardHero";
import EmptyState from "../../../../components/shared/EmptyState";
import LoadingState from "../../../../components/shared/LoadingState";
import Spinner from "../../../../components/shared/Spinner";
import { useUsers } from "../../../../core/contexts/firebase/users";

const AllUsersPage = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [users, uPagination, getNextUsers] = useUsers();

  const usersExist = users.length > 0;

  const fetchNext = () => {
    if (!isLoading) {
      setIsLoading(true);
      getNextUsers().then(() => setIsLoading(false));
    }
  };

  React.useEffect(() => {
    if (!usersExist) {
      fetchNext();
    }
  }, []);

  return (
    <React.Fragment>
      <article>
        {/* All users header */}
        <div>
          <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
            <DashboardHero
              description="Keep up with your applicants through a simple view of all of them, including their contact details."
              pages={[]}
            />
          </div>
          <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
            <div className="grid grid-cols-1 gap-4">
              {usersExist && (
                <React.Fragment>
                  {users.map((user) => {
                    return (
                      <div
                        key={user.uid}
                        className="rounded-md border-2 transition-colors hover:bg-indigo-100"
                      >
                        <UserListItem
                          to={`/dashboard/admin/applicants/${user.uid}`}
                          user={user}
                        />
                      </div>
                    );
                  })}
                  {!isLoading && (
                    <div className="mt-4">
                      <LoadMoreListItem
                        hasNext={uPagination.hasNext}
                        onLoadMoreClick={fetchNext}
                        loadMoreLabel="Load More Users"
                        noMoreLabel="There are no more users to show"
                      />
                    </div>
                  )}
                </React.Fragment>
              )}

              {!usersExist && !isLoading && (
                <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
                  <EmptyState
                    title="No Users"
                    description="Once users are created, you will see them show up here."
                    icon={(props) => (
                      <FolderOpenIcon
                        className="mx-auto my-8 h-12 w-12 text-gray-400"
                        {...props}
                      />
                    )}
                    actions={null}
                  />
                </div>
              )}

              {isLoading && (
                <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
                  <LoadingState
                    title="Loading Users"
                    description="Loading users, please wait."
                  >
                    <Spinner textColor="text-indigo-800" />
                  </LoadingState>
                </div>
              )}
            </div>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
};

export default AllUsersPage;
