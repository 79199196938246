import { SignInMethod } from "firebase/auth";
import * as React from "react";
import { useFirebaseService } from "../../core/contexts/firebase";
import EmailField from "../fields/EmailField";
import PasswordField from "../fields/PasswordField";
import ElevatedButton from "../shared/buttons/ElevatedButton";

export interface ReauthenticationFormProps {
  label: string;
  email: string;
  password: string;
  type?: "submit" | "button";
  onReauthenticateWithGoogle: () => void;
  onButtonClick: (isValid: boolean) => void;
  onEmailChanged: (email: string) => void;
  onPasswordChanged: (password: string) => void;
}

const ReauthenticationForm = (props: ReauthenticationFormProps) => {
  const firebaseService = useFirebaseService();
  const [passwordMethodEnabled, setPasswordMethodEnabled] =
    React.useState(false);
  const [googleMethodEnabled, setGoogleMethodEnabled] = React.useState(false);
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormValid = event.currentTarget.checkValidity();
    props.onButtonClick(isFormValid);
  };

  React.useEffect(() => {
    if (firebaseService) {
      firebaseService.doVerifyIsNewUser(props.email).then((providers) => {
        setPasswordMethodEnabled(
          providers.includes(SignInMethod.EMAIL_PASSWORD)
        );
        setGoogleMethodEnabled(providers.includes(SignInMethod.GOOGLE));
      });
    }
  }, [firebaseService]);

  return (
    <div className="grid grid-cols-1 gap-6 divide-y-2">
      <div></div>
      {googleMethodEnabled && (
        <div className="pt-6">
          <ElevatedButton
            onClick={props.onReauthenticateWithGoogle}
            isOutline
            isFullWidth
          >
            <svg
              className="h-5 w-5"
              aria-hidden="true"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 48 48"
            >
              <defs>
                <path
                  id="a"
                  d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
                />
              </defs>
              <clipPath id="b">
                <use xlinkHref="#a" overflow="visible" />
              </clipPath>
              <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
              <path
                clipPath="url(#b)"
                fill="#EA4335"
                d="M0 11l17 13 7-6.1L48 14V0H0z"
              />
              <path
                clipPath="url(#b)"
                fill="#34A853"
                d="M0 37l30-23 7.9 1L48 0v48H0z"
              />
              <path
                clipPath="url(#b)"
                fill="#4285F4"
                d="M48 48L17 24l-4-3 35-10z"
              />
            </svg>
            <span className="ml-4 font-medium">
              Re-authenticate with Google
            </span>
          </ElevatedButton>
        </div>
      )}
      {passwordMethodEnabled && (
        <form onSubmit={submitForm} className="space-y-6 pt-6">
          <EmailField email={props.email} onChange={props.onEmailChanged} />
          <PasswordField
            password={props.password}
            onChange={props.onPasswordChanged}
          />
          <button
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {props.label}
          </button>
        </form>
      )}
    </div>
  );
};

export default ReauthenticationForm;
