import React from "react";
import { EmployerDetailsModel } from "../../core/models/employer-details";

export interface EmployerDetailsProps {
  employerDetails: EmployerDetailsModel;
}

const EmployerDetails: React.FC<EmployerDetailsProps> = ({
  employerDetails,
}) => {
  return (
    <div className="space-y-8 divide-y divide-gray-200">
      <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <p className="col-span-1 text-xl text-gray-500 sm:col-span-2">
          General information
        </p>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Name</dt>
          <dd className="mt-1 text-sm text-gray-900">{employerDetails.name}</dd>
        </div>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
          <dd className="mt-1 text-sm text-gray-900 hover:text-indigo-600">
            <a
              href={`tel:${employerDetails.cell}`}
              target="_blank"
              rel="noreferrer noopener"
              className="inline-flex items-center font-medium leading-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              {employerDetails.cell}
            </a>
          </dd>
        </div>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Salary Date</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {employerDetails.salaryDate}
          </dd>
        </div>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Employment Status
          </dt>
          <dd className="mt-1 text-sm text-gray-900">
            {`${employerDetails.employmentStatus[0].toUpperCase()}${employerDetails.employmentStatus.slice(
              1
            )}`}
          </dd>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-x-4 gap-y-8 pt-8 sm:grid-cols-2">
        <p className="col-span-1 text-xl text-gray-500 sm:col-span-2">
          Physical address
        </p>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Line 1</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {employerDetails.address.line1}
          </dd>
        </div>
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Line 2</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {employerDetails.address.line2}
          </dd>
        </div>
        {employerDetails.address.line3 && (
          <div className="col-span-1">
            <dt className="text-sm font-medium text-gray-500">Line 3</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {employerDetails.address.line3}
            </dd>
          </div>
        )}
        <div className="col-span-1 lg:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Postal Code</dt>
          <dd className="mt-1 text-sm text-gray-900">
            {employerDetails.address.postalCode}
          </dd>
        </div>
      </div>
    </div>
  );
};

export default EmployerDetails;
