import { Dialog, Tab, Transition } from "@headlessui/react";
import { ArrowPathIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { FirebaseError } from "firebase/app";
import { DocumentData, DocumentReference } from "firebase/firestore";
import { navigate } from "gatsby";
import * as React from "react";
import { useLocation } from "@reach/router";
import { Id, toast, TypeOptions } from "react-toastify";
import AddressDetails from "../../../components/details/AddressDetails";
import AffordabilityDetails from "../../../components/details/AffordabilityDetails";
import BankingDetails from "../../../components/details/BankingDetails";
import EmployerDetails from "../../../components/details/EmployerDetails";
import LoanDetails from "../../../components/details/LoanDetails";
import CheckField from "../../../components/fields/CheckField";
import CreateAddressForm from "../../../components/forms/CreateAddressForm";
import CreateAffordabilityForm from "../../../components/forms/CreateAffordabilityForm";
import CreateBankingDetailsForm from "../../../components/forms/CreateBankingDetailsForm";
import CreateEmployerForm from "../../../components/forms/CreateEmployerForm";
import CreateLoanForm from "../../../components/forms/CreateLoanForm";
import UploadCard from "../../../components/shared/cards/UploadCard";
import {
  useAuthUser,
  useAuthUserDoc,
  useFirebaseService,
} from "../../../core/contexts/firebase";
import { usePersonalLoans } from "../../../core/contexts/firebase/personal-loans";
import { ApplicationStatus, UploadErrorCode } from "../../../core/enums";
import useAddressFormReducer, {
  AddressFormActionType,
} from "../../../core/hooks/useAddressFormReducer";
import useAffordabilityFormReducer, {
  AffordabilityFormActionType,
} from "../../../core/hooks/useAffordabilityFormReducer";
import useBankingDetailsFormReducer, {
  BankingDetailsFormActionType,
} from "../../../core/hooks/useBankingDetailsFormReducer";
import useEmployerDetailsFormReducer, {
  EmployerDetailsFormActionType,
} from "../../../core/hooks/useEmployerDetailsFormReducer";
import usePersonalLoanSubmissionReducer, {
  PersonalLoanSubmissionActionType,
} from "../../../core/hooks/usePersonalLoanSubmissionReducer";
import useUploadFormReducer, {
  UploadFormActionType,
} from "../../../core/hooks/useUploadReducer";
import { LoanModel } from "../../../core/models/loan";
import { StatusModel } from "../../../core/models/status";
import { formatPostalCode } from "../../../core/utils/format";
import { handleUpload, UploadError } from "../../../core/utils/uploader";
import { useInitialAmountParam } from "../../../core/hooks/useAmountParam";

const steps = [
  { id: 1, name: "Loan amount" },
  { id: 2, name: "Address" },
  { id: 3, name: "Employer details" },
  { id: 4, name: "Affordability details" },
  { id: 5, name: "Banking details" },
  { id: 6, name: "Upload documents" },
  { id: 7, name: "Review" },
];

const PersonalLoanApplicationPage = () => {
  const initialAmount = useInitialAmountParam();
  const user = useAuthUser();
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [i1, i2, i3, getNewPersonalLoan] = usePersonalLoans();
  const [amount, setAmount] = React.useState(initialAmount ?? "500");
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [reviewed, setReviewed] = React.useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] =
    React.useState("");
  const [openChangeUpdater, setOpenChangeUpdater] = React.useState(false);

  // Initialise state management for forms
  const [submitter, submitterDispatch] = usePersonalLoanSubmissionReducer();
  const [bankStatement, bankStatementDispatch] = useUploadFormReducer();
  const [payslip, payslipDispatch] = useUploadFormReducer();
  const [ID, IDDispatch] = useUploadFormReducer();
  const [address, addressDispatch] = useAddressFormReducer();
  const [bankingDetails, bankingDetailsDispatch] =
    useBankingDetailsFormReducer();
  const [employerDetails, employerDetailsDispatch] =
    useEmployerDetailsFormReducer();
  const [affordability, affordabilityDispatch] = useAffordabilityFormReducer();
  const [updateDefaultAddress, setUpdateDefaultAddress] = React.useState(true);
  const [updateDefaultBankingDetails, setUpdateDefaultBankingDetails] =
    React.useState(true);
  const [updateDefaultEmployerDetails, setUpdateDefaultEmployerDetails] =
    React.useState(true);
  const [updateDefaultAffordability, setUpdateDefaultAffordability] =
    React.useState(true);

  // Derived change state
  const hasAddressChanged = address.hasChanged;
  const hasBankingDetailsChanged = bankingDetails.hasChanged;
  const hasEmployerDetailsChanged = employerDetails.hasChanged;
  const hasAffordabilityChanged = affordability.hasChanged;
  const hasFormChanged =
    hasAddressChanged ||
    hasBankingDetailsChanged ||
    hasEmployerDetailsChanged ||
    hasAffordabilityChanged;

  // we need to keep a reference of the document reference to prevent the creation of
  // multiple documents. This is needed because the onSaveBusinessLoan function
  // can be called multiple times and the document reference is created in that function.
  const docRef = React.useRef<DocumentReference<DocumentData> | null>(null);

  React.useEffect(() => {
    if (user && firebaseService && docRef.current == null) {
      docRef.current = firebaseService.loanDocRef(user.uid);
    }
  }, [user, firebaseService, docRef.current]);

  // we need to reset the percantages in various cases such as when a user enters or leaves the page
  // and also when an error occurs during file uploads.
  const resetPercents = () =>
    submitterDispatch({
      type: PersonalLoanSubmissionActionType.RESET_ALL_PERCENTS,
    });
  React.useEffect(() => {
    resetPercents();
    return resetPercents;
  }, []);

  // Pre-populate form fields if the data already exists
  React.useEffect(() => {
    const defaultAddress = userDoc?.defaultAddress;
    if (defaultAddress) {
      addressDispatch({
        type: AddressFormActionType.INITIALISE,
        initialData: defaultAddress,
      });
    }
    const defaultEmployerDetails = userDoc?.defaultEmployerDetails;
    if (defaultEmployerDetails) {
      employerDetailsDispatch({
        type: EmployerDetailsFormActionType.INITIALISE,
        initialData: defaultEmployerDetails,
      });
    }
    const defaultBankDetails = userDoc?.defaultBankDetails;
    if (defaultBankDetails) {
      bankingDetailsDispatch({
        type: BankingDetailsFormActionType.INITIALISE,
        initialData: defaultBankDetails,
      });
    }
    const defaultAffordability = userDoc?.defaultAffordability;
    if (defaultAffordability) {
      affordabilityDispatch({
        type: AffordabilityFormActionType.INITIALISE,
        initialData: defaultAffordability,
      });
    }
  }, [userDoc]);

  // Handle field changes through state management mechanism
  const onSubmitterFieldChanged = (fieldKey: string, fieldValue: any) =>
    submitterDispatch({
      type: PersonalLoanSubmissionActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onAddressFieldChanged = (fieldKey: string, fieldValue: any) =>
    addressDispatch({
      type: AddressFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onBankingDetailsFieldChanged = (fieldKey: string, fieldValue: any) =>
    bankingDetailsDispatch({
      type: BankingDetailsFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onEmployerDetailsFieldChanged = (fieldKey: string, fieldValue: any) =>
    employerDetailsDispatch({
      type: EmployerDetailsFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onAffordabilityFieldChanged = (fieldKey: string, fieldValue: any) =>
    affordabilityDispatch({
      type: AffordabilityFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onBankStatementFieldChanged = (fieldKey: string, fieldValue: any) =>
    bankStatementDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onPayslipFieldChanged = (fieldKey: string, fieldValue: any) =>
    payslipDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });
  const onIDFieldChanged = (fieldKey: string, fieldValue: any) =>
    IDDispatch({
      type: UploadFormActionType.CHANGE_FIELD,
      payload: { fieldKey, fieldValue },
    });

  // function helper for bank statement upload
  const handleUploadBS = (loanId: string, uid: string) => {
    const { file } = bankStatement.currentData;
    const path = `/files/users/${uid}/personal_loans/${loanId}/bank_statement`;
    return handleUpload(
      path,
      "Bank Statement",
      file!,
      firebaseService!,
      (percent) => onSubmitterFieldChanged("percent.bankStatement", percent),
      (url) => onSubmitterFieldChanged("download.bankStatementUrl", url)
    );
  };

  // function helper for payslip upload
  const handleUploadPS = (loanId: string, uid: string) => {
    const { file } = payslip.currentData;
    const path = `/files/users/${uid}/personal_loans/${loanId}/payslip`;
    return handleUpload(
      path,
      "Payslip",
      file!,
      firebaseService!,
      (percent) => onSubmitterFieldChanged("percent.payslip", percent),
      (url) => onSubmitterFieldChanged("download.payslipUrl", url)
    );
  };

  // function helper for ID card or book upload
  const handleUploadID = (loanId: string, uid: string) => {
    const { file } = ID.currentData;
    const path = `/files/users/${uid}/personal_loans/${loanId}/id_copy`;
    return handleUpload(
      path,
      "ID Copy",
      file!,
      firebaseService!,
      (percent) => onSubmitterFieldChanged("percent.idCopy", percent),
      (url) => onSubmitterFieldChanged("download.idCopyUrl", url)
    );
  };

  const handleUploadError = (error: UploadError) => {
    let message =
      "Something went wrong while uploading your claim document, please try again later";
    if (error.code === UploadErrorCode.failed_to_upload) {
      message = `Unable to upload "${error.name}". Please try using a different file. If the problem still persists, please contact support.`;
    } else if (error.code === UploadErrorCode.failed_to_get_download_url) {
      message = `Unable to get download url for  "${error.name}". Please try again.`;
    }
    makeToast(message, toast.TYPE.ERROR, false);
    toastId.current = null;
  };

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  const onSaveDefaults = async () => {
    if (user && firebaseService) {
      makeToast("Updating selected defaults.", toast.TYPE.INFO, false);
      onSubmitterFieldChanged("isSubmitting", true);
      // Save the user's default information in their user document
      const { uid } = user;
      try {
        let update = {};
        if (hasAddressChanged && updateDefaultAddress) {
          update = {
            ...update,
            defaultAddress: {
              ...address.currentData,
              formattedPostalCode: formatPostalCode(
                address.currentData.postalCode
              ),
            },
          };
        }
        if (hasBankingDetailsChanged && updateDefaultBankingDetails) {
          update = {
            ...update,
            defaultBankDetails: bankingDetails.currentData,
          };
        }
        if (hasEmployerDetailsChanged && updateDefaultEmployerDetails) {
          update = {
            ...update,
            defaultEmployerDetails: {
              ...employerDetails.currentData,
              formattedPostalCode: formatPostalCode(
                employerDetails.currentData.address.postalCode
              ),
            },
          };
        }
        if (hasAffordabilityChanged && updateDefaultAffordability) {
          update = {
            ...update,
            defaultAffordability: affordability.currentData,
          };
        }
        await firebaseService.updateUserDoc(
          uid,
          Object.assign({
            ...update,
            updatedAt: "",
          })
        );
        makeToast("Defaults updated successfully.", toast.TYPE.SUCCESS);
      } catch (error) {
        let message =
          "Something went wrong while updating your defaults, please try again later";
        if (error instanceof UploadError) {
          handleUploadError(error);
        } else if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to update defaults. ${error.message}`;
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const onSavePersonalLoan = async () => {
    const ref = docRef.current;
    if (userDoc && firebaseService && ref) {
      makeToast("Uploading documents.", toast.TYPE.INFO, false);
      onSubmitterFieldChanged("isSubmitting", true);
      // Save the user's loan information in a new loan document
      const { uid } = userDoc;
      try {
        // Upload the files
        const [setDownloadUrlBS] = handleUploadBS(ref.id, uid);
        const [setDownloadUrlPS] = handleUploadPS(ref.id, uid);
        const [setDownloadUrlID] = handleUploadID(ref.id, uid);
        const downloadUrls = await Promise.all([
          setDownloadUrlBS,
          setDownloadUrlPS,
          setDownloadUrlID,
        ]);

        makeToast("Submitting loan application.", toast.TYPE.INFO, false);
        // Save the loan document
        const user = {
          uid: userDoc.uid,
          name: userDoc.name,
          surname: userDoc.surname,
          email: userDoc.email,
          cell: userDoc.cell,
        };
        const loanModel: LoanModel = {
          id: ref.id,
          amount,
          status: ApplicationStatus.created,
          download: {
            bankStatementUrl: downloadUrls[0],
            payslipUrl: downloadUrls[1],
            idCopyUrl: downloadUrls[2],
          },
          user,
          address: address.currentData,
          employerDetails: employerDetails.currentData,
          bankingDetails: bankingDetails.currentData,
          affordability: affordability.currentData,
          createdAt: "",
          updatedAt: "",
        };
        const status: StatusModel = {
          status: ApplicationStatus.created,
          user,
          id: "",
          applicationId: ref.id,
          createdAt: "",
          updatedAt: "",
        };
        await Promise.all([
          firebaseService.setLoanDoc(uid, loanModel, ref),
          firebaseService.setPersonalLoanStatusDoc(uid, ref.id, status),
        ]);
        getNewPersonalLoan();
        makeToast(
          "Your personal loan application has been submitted successfully.",
          toast.TYPE.SUCCESS
        );
        onSubmitterFieldChanged("isSubmitting", false);
        onSubmitterFieldChanged("isSubmitted", true);
        navigate("/dashboard/applications?tab=personal");
      } catch (error) {
        onSubmitterFieldChanged("isSubmitting", false);
        resetPercents();
        let message =
          "Something went wrong while submitting your loan application, please try again later";
        if (error instanceof UploadError) {
          handleUploadError(error);
        } else if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to submit. ${error.message}`;
          }
          makeToast(message, toast.TYPE.ERROR, false);
          toastId.current = null;
        }
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  const getStepperContainerClassNames = (stepIndex: number) => {
    let classNames =
      "flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pl-0 md:pt-4 md:pb-0";
    if (stepIndex > selectedIndex) {
      // Incomplete
      classNames = `${classNames} border-gray-200 group`;
    } else if (stepIndex === selectedIndex) {
      // Current
      classNames = `${classNames} border-indigo-600 hover:border-gray-300`;
    } else if (stepIndex < selectedIndex) {
      // Complete
      classNames = `${classNames} border-indigo-600 group hover:border-indigo-800`;
    }
    return classNames;
  };

  const getStepperIdClassNames = (stepIndex: number) => {
    let classNames = "text-sm font-medium";
    if (stepIndex > selectedIndex) {
      // Incomplete
      classNames = `${classNames} text-gray-500 group-hover:text-gray-700`;
    } else if (stepIndex === selectedIndex) {
      // Current
      classNames = `${classNames} text-indigo-600`;
    } else if (stepIndex < selectedIndex) {
      // Complete
      classNames = `${classNames} text-indigo-600 group hover:text-indigo-800`;
    }
    return classNames;
  };

  const goToPreviousTab = () => setSelectedIndex(selectedIndex - 1);
  const goToNextTab = () => setSelectedIndex(selectedIndex + 1);

  return (
    <React.Fragment>
      <div className="mx-auto mt-16 px-4 sm:px-6 lg:max-w-6xl lg:px-8">
        <div className="flex items-center justify-between gap-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900 lg:text-2xl">
            New Personal Loan
          </h3>
          <div className="flex gap-4">
            <div
              className={`text-sm transition-opacity duration-300 ${
                selectedIndex != 0 ? "opacity-1" : "opacity-0"
              }`}
            >
              <button
                onClick={goToPreviousTab}
                disabled={selectedIndex === 0}
                className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
              >
                &larr;&nbsp;Previous Step
              </button>
            </div>
            <button
              type="button"
              onClick={() => {
                if (hasFormChanged) {
                  // TODO: Ask user if they are sure because there are unsaved changes
                  navigate(-1);
                } else {
                  navigate(-1);
                }
              }}
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
            >
              <XCircleIcon className="-ml-1 mr-2 h-5 w-5 text-gray-600" />
              Cancel
            </button>
          </div>
        </div>
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="mt-12 space-y-4 rounded-md bg-white px-4 py-4 shadow-md md:flex md:space-y-0 md:space-x-8 md:px-6 lg:px-8">
            {steps.map((step) => (
              <Tab
                key={step.id}
                className={getStepperContainerClassNames(step.id - 1)}
              >
                <span className={getStepperIdClassNames(step.id - 1)}>
                  Step {step.id}
                </span>
                <span className="text-start text-sm font-medium">
                  {step.name}
                </span>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel tabIndex={0}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                We offer personal loans from R 500 to R 5,000. Please enter the
                most appropriate loan amount for you.
              </p>
              <CreateLoanForm
                label="Next"
                amount={amount}
                onAmountChanged={(amount) => amount && setAmount(amount)}
                onButtonClick={(isValid) =>
                  isValid
                    ? goToNextTab()
                    : toast.warn(
                        "The loan amount is invalid, please make sure its between R 500.00 and R 5,000.00 and try again."
                      )
                }
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={1}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please enter your most recent address's details.
              </p>
              <CreateAddressForm
                label="Next"
                address={address.currentData}
                onFieldChanged={onAddressFieldChanged}
                onButtonClick={(isValid) => isValid && goToNextTab()}
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={2}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please enter your most recent employer's details.
              </p>
              <CreateEmployerForm
                label="Next"
                employerDetails={employerDetails.currentData}
                phoneNumberErrorMessage={phoneNumberErrorMessage}
                onFieldChanged={onEmployerDetailsFieldChanged}
                onPhoneNumberErrorMessage={setPhoneNumberErrorMessage}
                onButtonClick={(isValid) => isValid && goToNextTab()}
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={3}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please enter your most recent affordability details.
              </p>
              <CreateAffordabilityForm
                label="Next"
                affordability={affordability.currentData}
                onFieldChanged={onAffordabilityFieldChanged}
                onButtonClick={(isValid) => isValid && goToNextTab()}
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={4}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please enter your most recent banking details.
              </p>
              <CreateBankingDetailsForm
                label="Next"
                bankingDetails={bankingDetails.currentData}
                onFieldChanged={onBankingDetailsFieldChanged}
                onButtonClick={(isValid) => isValid && goToNextTab()}
              />
            </Tab.Panel>
            <Tab.Panel tabIndex={5}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Please upload a copy of your bank statement, payslip, and ID
                book or card.
              </p>
              <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                <div className="col-span-1">
                  <UploadCard
                    label="1. Upload Bank Statement"
                    description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                    file={bankStatement.currentData.file}
                    onChange={(f) => onBankStatementFieldChanged("file", f)}
                  />
                </div>
                <div className="col-span-1">
                  <UploadCard
                    label="2. Upload Payslip"
                    description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                    file={payslip.currentData.file}
                    onChange={(f) => onPayslipFieldChanged("file", f)}
                  />
                </div>
                <div className="col-span-1">
                  <UploadCard
                    label="3. Upload ID"
                    description="You can only upload a PDF, JPG, or PNG file of up to 5MB"
                    file={ID.currentData.file}
                    onChange={(f) => onIDFieldChanged("file", f)}
                  />
                </div>
                <button
                  type={"button"}
                  onClick={() => {
                    const isBankStatementValid =
                      bankStatement.currentData.file != null;
                    const isPayslipValid = payslip.currentData.file != null;
                    const isIDValid = ID.currentData.file != null;

                    if (isBankStatementValid && isPayslipValid && isIDValid) {
                      goToNextTab();
                    } else {
                      toast.warn(
                        "Please upload each document before you continue."
                      );
                    }
                  }}
                  className="col-span-1 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:col-span-2 lg:col-span-3"
                >
                  Next
                </button>
              </div>
            </Tab.Panel>
            <Tab.Panel tabIndex={6}>
              <p className="mt-12 mb-8 text-base text-gray-500">
                Review your loan amount, address, employer details,
                affordability details, banking details and uploaded documents
                before submitting your personal loan application.
              </p>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Loan details
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <LoanDetails amount={amount} />
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Address details
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <AddressDetails address={address.currentData} />
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Employer details
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <EmployerDetails
                  employerDetails={employerDetails.currentData}
                />
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Affordability
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <AffordabilityDetails
                  affordability={affordability.currentData}
                />
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Banking details
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <BankingDetails bankingDetails={bankingDetails.currentData} />
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Upload documents
              </p>
              <div className="mt-6 rounded-md border-2 p-4 shadow-md">
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                  <UploadCard
                    label="Bank statement"
                    description={`${submitter.percent.bankStatement}% Uploaded`}
                    file={bankStatement.currentData.file!}
                  />
                  <UploadCard
                    label="Payslip"
                    description={`${submitter.percent.payslip}% Uploaded`}
                    file={payslip.currentData.file!}
                  />
                  <UploadCard
                    label="ID"
                    description={`${submitter.percent.idCopy}% Uploaded`}
                    file={ID.currentData.file!}
                  />
                </div>
              </div>
              <p className="col-span-2 mt-20 text-2xl font-medium">
                Review & Submit
              </p>
              <p className="col-span-2 mt-4">
                <CheckField
                  id="personal-loan-application-user-reviewed"
                  checked={reviewed}
                  description={
                    'By clicking "Submit Application" you agree that the address, employer details, affordability details, banking details and uploaded documents are correct and current.'
                  }
                  onChange={setReviewed}
                />
              </p>
              <button
                type={"button"}
                disabled={submitter.isSubmitting}
                onClick={() => {
                  if (reviewed) {
                    // Submit document
                    if (hasFormChanged) {
                      setOpenChangeUpdater(true);
                    } else {
                      onSavePersonalLoan();
                    }
                  } else {
                    toast.warn(
                      "Please ensure that you have reviewed the application and checked the relevant checkbox"
                    );
                  }
                }}
                className="col-span-1 mt-12 flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 md:col-span-2 lg:col-span-3"
              >
                Submit Application
              </button>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <Transition.Root show={openChangeUpdater} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={setOpenChangeUpdater}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <ArrowPathIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Change Defaults
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          We have discovered one or more changes to your
                          defaults. Defaults are used to provide auto-population
                          of form fields for faster applications in the future.
                        </p>
                      </div>
                      <div className="mt-4 rounded-md border-2 p-4">
                        {hasAddressChanged && (
                          <CheckField
                            id="personal-loan-form-has-address-changed"
                            checked={updateDefaultAddress}
                            description="Update default address"
                            onChange={setUpdateDefaultAddress}
                          />
                        )}
                        {hasBankingDetailsChanged && (
                          <CheckField
                            id="personal-loan-form-has-banking-details-changed"
                            checked={updateDefaultBankingDetails}
                            description="Update default banking details"
                            onChange={setUpdateDefaultBankingDetails}
                          />
                        )}
                        {hasEmployerDetailsChanged && (
                          <CheckField
                            id="personal-loan-form-has-employer-details-changed"
                            checked={updateDefaultEmployerDetails}
                            description="Update default employer details"
                            onChange={setUpdateDefaultEmployerDetails}
                          />
                        )}
                        {hasAffordabilityChanged && (
                          <CheckField
                            id="personal-loan-form-has-affordability-changed"
                            checked={updateDefaultAffordability}
                            description="Update default affordability details"
                            onChange={setUpdateDefaultAffordability}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 grid grid-cols-1 gap-4 sm:mt-6 md:grid-cols-2">
                    <button
                      type="button"
                      className="inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                      onClick={async () => {
                        setOpenChangeUpdater(false);
                        await onSavePersonalLoan();
                      }}
                    >
                      Skip & Submit
                    </button>
                    <button
                      type="button"
                      className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={async () => {
                        setOpenChangeUpdater(false);
                        await onSaveDefaults();
                        await onSavePersonalLoan();
                      }}
                    >
                      Update & Submit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
};

export default PersonalLoanApplicationPage;
