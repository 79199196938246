import * as React from "react";
import { ClaimModel } from "../../core/models/claim";
import ClaimTypeField from "../fields/ClaimTypeField";
import IdNumberField from "../fields/IdNumberField";
import NameField from "../fields/NameField";

export interface ClaimFormProps {
  label: string;
  claim: ClaimModel;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onFieldChanged: (fieldKey: string, fieldValue: any) => void;
}

const ClaimForm = (props: ClaimFormProps) => {
  const { claim } = props;

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isFormValid = event.currentTarget.checkValidity();
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="grid grid-cols-2 gap-6">
      <div className="col-span-2">
        <ClaimTypeField
          claimType={claim.type}
          onChange={(v) => props.onFieldChanged("type", v)}
        />
      </div>
      <div className="col-span-2">
        <IdNumberField
          label="ID of policy holder"
          idNumber={claim.idNumber}
          onChange={(v) => props.onFieldChanged("idNumber", v)}
        />
      </div>
      <div className="col-span-2">
        <NameField
          label="Policy or card number"
          name={claim.policyOrCardNumber ?? ""}
          required={false}
          onChange={(v) => props.onFieldChanged("policyOrCardNumber", v)}
        />
      </div>
      <div className="col-span-2 flex gap-8">
        <button
          type={props.type ?? "submit"}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {props.label}
        </button>
      </div>
    </form>
  );
};

export default ClaimForm;
