import React from "react";
import { UserModel } from "../../core/models/user";

export interface ProfileDetailsProps {
  user: UserModel;
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({ user }) => {
  const sex = user.sex
    ? `${user.sex[0].toUpperCase()}${user.sex.slice(1)}`
    : null;
  const relationshipStatus = user.relationshipStatus
    ? `${user.relationshipStatus[0].toUpperCase()}${user.relationshipStatus.slice(
        1
      )}`
    : null;

  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">First Name</dt>
        <dd className="mt-1 text-sm text-gray-900">{user.name}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Last Name</dt>
        <dd className="mt-1 text-sm text-gray-900">{user.surname}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
        <dd className="mt-1 text-sm text-gray-900 hover:text-indigo-600">
          <a
            href={`tel:${user.cell}`}
            target="_blank"
            rel="noreferrer noopener"
            className="inline-flex items-center text-sm font-medium leading-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {user.cell}
          </a>
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Email Address</dt>
        <dd className="mt-1 text-sm text-gray-900 hover:text-indigo-600">
          <a
            href={`mailto:${user.email}`}
            target="_blank"
            rel="noreferrer noopener"
            className="inline-flex items-center text-sm font-medium leading-4 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {user.email}
          </a>
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">ID Number</dt>
        <dd className="mt-1 text-sm text-gray-900">{user.idNumber}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Language</dt>
        <dd className="mt-1 text-sm text-gray-900">{user.language}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Sex</dt>
        <dd className="mt-1 text-sm text-gray-900">{sex}</dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          Relationship Status
        </dt>
        <dd className="mt-1 text-sm text-gray-900">{relationshipStatus}</dd>
      </div>
    </dl>
  );
};

export default ProfileDetails;
