import React from "react";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum BusinessLoanSubmissionActionType {
  RESET_ALL_PERCENTS,
  CHANGE_FIELD,
}

export interface BusinessLoanSubmissionAction {
  type: BusinessLoanSubmissionActionType;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface BusinessLoanSubmissionState {
  isSubmitting: boolean;
  isSubmitted: boolean;
  percent: {
    companyRegDoc: number;
    appointmentLetterOrSla: number;
    appointmentLetterOrPurchaseOrder: number;
    billOfQuantities: number;
    projectImplementationPlan: number;
    purchaseOrder: number;
    supplierQuoteOrInvoice: number;
    invoice: number;
    paymentCertificate: number;
  };
  download: {
    companyRegDocUrl?: string;
    appointmentLetterOrSlaUrl?: string;
    appointmentLetterOrPurchaseOrderUrl?: string;
    billOfQuantitiesUrl?: string;
    projectImplementationPlanUrl?: string;
    purchaseOrderUrl?: string;
    supplierQuoteOrInvoiceUrl?: string;
    invoiceUrl?: string;
    paymentCertificateUrl?: string;
  };
}

const initialState: BusinessLoanSubmissionState = {
  isSubmitting: false,
  isSubmitted: false,
  percent: {
    companyRegDoc: 0,
    appointmentLetterOrSla: 0,
    appointmentLetterOrPurchaseOrder: 0,
    billOfQuantities: 0,
    projectImplementationPlan: 0,
    purchaseOrder: 0,
    supplierQuoteOrInvoice: 0,
    invoice: 0,
    paymentCertificate: 0,
  },
  download: {
    companyRegDocUrl: "",
    appointmentLetterOrSlaUrl: "",
    appointmentLetterOrPurchaseOrderUrl: "",
    billOfQuantitiesUrl: "",
    projectImplementationPlanUrl: "",
    purchaseOrderUrl: "",
    supplierQuoteOrInvoiceUrl: "",
    invoiceUrl: "",
    paymentCertificateUrl: "",
  },
};

const reducer = (
  state = initialState,
  action: BusinessLoanSubmissionAction
) => {
  const { type, payload } = action;
  switch (type) {
    case BusinessLoanSubmissionActionType.RESET_ALL_PERCENTS:
      return {
        ...state,
        percent: {
          companyRegDoc: 0,
          appointmentLetterOrSla: 0,
          appointmentLetterOrPurchaseOrder: 0,
          billOfQuantities: 0,
          projectImplementationPlan: 0,
          purchaseOrder: 0,
          supplierQuoteOrInvoice: 0,
          invoice: 0,
          paymentCertificate: 0,
        },
      };
    case BusinessLoanSubmissionActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField({ ...state }, fieldKey, fieldValue);
        return {
          ...state,
          currentData,
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

const useBusinessLoanSubmissionReducer = (): [
  BusinessLoanSubmissionState,
  React.Dispatch<BusinessLoanSubmissionAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useBusinessLoanSubmissionReducer;
