import React, { Reducer } from "react";
import {
  BankAccountType,
  BankingDetailsModel,
} from "../models/banking-details";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum BankingDetailsFormActionType {
  INITIALISE,
  CHANGE_FIELD,
}

export interface BankingDetailsFormAction {
  type: BankingDetailsFormActionType;
  initialData?: BankingDetailsModel;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface BankingDetailsFormState {
  currentData: BankingDetailsModel;
  initialData: BankingDetailsModel;
  hasChanged: boolean;
}

const initialData: BankingDetailsModel = {
  name: "",
  accountNumber: "",
  accountType: BankAccountType.cheque_account,
  branchCode: "",
};

const initialState: BankingDetailsFormState = {
  initialData,
  currentData: initialData,
  hasChanged: false,
};

const reducer = (state = initialState, action: BankingDetailsFormAction) => {
  const { type, payload, initialData } = action;
  switch (type) {
    case BankingDetailsFormActionType.INITIALISE:
      if (initialData) {
        return {
          initialData,
          currentData: initialData,
          hasChanged: false,
        };
      } else {
        throw Error(
          `initialState is invalid for action type: ${type.toString()}`
        );
      }
    case BankingDetailsFormActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField(
          { ...state.currentData },
          fieldKey,
          fieldValue
        );
        return {
          ...state,
          currentData,
          hasChanged: haveFieldsChanged(state.initialData, currentData),
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

// Returns true if the current data fields are not equal to the initial data fields.
const haveFieldsChanged = (
  initialData: BankingDetailsModel,
  currentData: BankingDetailsModel
) => {
  const hasNameChanged = initialData.name !== currentData.name;
  const hasAccountNumberChanged =
    initialData.accountNumber !== currentData.accountNumber;
  const hasAccountTypeChanged =
    initialData.accountType !== currentData.accountType;
  const hasBranchCodeChanged =
    initialData.branchCode !== currentData.branchCode;
  return (
    hasNameChanged ||
    hasAccountNumberChanged ||
    hasAccountTypeChanged ||
    hasBranchCodeChanged
  );
};

const useBankingDetailsFormReducer = (): [
  BankingDetailsFormState,
  React.Dispatch<BankingDetailsFormAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useBankingDetailsFormReducer;
