import React from "react";
import { BankingDetailsModel } from "../../core/models/banking-details";

export interface BankingDetailsProps {
  bankingDetails: BankingDetailsModel;
}

const BankingDetails: React.FC<BankingDetailsProps> = ({ bankingDetails }) => {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Name</dt>
        <dd className="mt-1 text-sm text-gray-900">{bankingDetails.name}</dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Account Type</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {bankingDetails.accountType === "cheque_account"
            ? "Cheque"
            : "Saving"}
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Account Number</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {bankingDetails.accountNumber}
        </dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Branch Code</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {bankingDetails.branchCode}
        </dd>
      </div>
    </dl>
  );
};

export default BankingDetails;
