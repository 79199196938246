import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Spinner from "./Spinner";

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-50 flex h-screen w-full items-center justify-center overflow-hidden bg-slate-200">
      <div className="flex flex-col items-center gap-y-8">
        <StaticImage
          src="../../images/logo.png"
          quality={100}
          height={120}
          alt="Nora Finance logo with text"
        />
        <p className="text-lg font-bold">The Dashboard</p>
        <Spinner textColor="text-indigo-800" />
      </div>
    </div>
  );
};

export default Loader;
