import * as React from "react";
import {
  buttonClassNames,
  buttonExtraClassNames,
} from "../../../core/ui/classNames";

export interface ElevatedButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  isOutline?: boolean;
  isFullWidth?: boolean;
  disabled?: boolean;
}

const ElevatedButton = ({
  onClick,
  children,
  isOutline = false,
  isFullWidth = false,
  disabled = false,
}: ElevatedButtonProps) => {
  const extraClassNames = buttonExtraClassNames(isOutline);
  const classNames = `${extraClassNames} ${buttonClassNames} ${
    isFullWidth ? "w-full justify-center" : ""
  }`;

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={classNames}
    >
      {children}
    </button>
  );
};

export default ElevatedButton;
