import { Link } from "gatsby";
import React from "react";
import {
  useAuthUserDoc,
  useAuthUserIsAdmin,
} from "../../core/contexts/firebase";
import { useAdminMode } from "../../core/contexts/ui/admin-mode";
import { BaseUser } from "../../core/models/user";

export interface ApplicationUserProps {
  user?: BaseUser;
}

const ApplicationUser: React.FC<ApplicationUserProps> = ({ user }) => {
  const u = useAuthUserDoc();
  const [isAdminMode] = useAdminMode();

  if (u && user) {
    if (u.uid === user.uid && !isAdminMode) {
      return <span>You</span>;
    } else {
      return (
        <Link
          className="text-indigo-600 hover:text-indigo-800"
          to={`/dashboard/admin/applicants/${user.uid}`}
        >{`${user.name} ${user.surname}`}</Link>
      );
    }
  } else {
    return <span>Unknown User</span>;
  }
};

export default ApplicationUser;
