import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";
import ApplicationStatusBadge from "../../../components/shared/ApplicationStatusBadge";
import FormattedDateTime from "../../../components/shared/FormattedDateTime";
import { StatusModel } from "../../../core/models/status";
import { formatApplicationStatus } from "../../../core/utils/format";

export interface ApplicationStatusPanelProps {
  statuses: StatusModel[];
  isOpen: boolean;
  onClose: (value: boolean) => void;
}

const ApplicationStatusPanel: React.FC<ApplicationStatusPanelProps> = (
  props
) => {
  const { isOpen, onClose, statuses } = props;
  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={React.Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className=" pointer-events-auto mt-[64px] w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Timeline
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => onClose(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200"></div>
                    <ul
                      role="list"
                      className="flex-1 divide-y divide-gray-200 overflow-y-auto"
                    >
                      {statuses.map(({ id, status, user, createdAt }) => {
                        const fullName = `${user.name} ${user.surname}`;
                        return (
                          <li key={id}>
                            <div className="group relative flex items-start py-6 px-5">
                              <div className="-m-1 block flex-1 p-1">
                                <div
                                  className="absolute inset-0 group-hover:bg-gray-50"
                                  aria-hidden="true"
                                />
                                <div className="relative flex min-w-0 flex-1 items-center">
                                  <div className="w-full">
                                    <div className="overflow-hidden rounded-full shadow-md">
                                      <ApplicationStatusBadge status={status}>
                                        <div className="justify-center px-4 py-1">
                                          Status:{" "}
                                          {formatApplicationStatus(status)}
                                        </div>
                                      </ApplicationStatusBadge>
                                    </div>
                                    <p className="mt-2 px-4 text-sm font-medium text-gray-400">
                                      <FormattedDateTime date={createdAt} />
                                    </p>
                                    <p className="px-4 text-base text-gray-500">
                                      Application status updated by{" "}
                                      <span className="font-bold">
                                        {fullName}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* <Menu
                                as="div"
                                className="relative ml-2 inline-block flex-shrink-0 text-left"
                              >
                                <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                  <span className="sr-only">
                                    Open options menu
                                  </span>
                                  <span className="flex h-full w-full items-center justify-center rounded-full">
                                    <EllipsisVerticalIcon
                                      className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Menu.Button>
                                <Transition
                                  as={React.Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute top-0 right-9 z-10 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            View profile
                                          </a>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            Send message
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu> */}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ApplicationStatusPanel;
