import React, { Reducer } from "react";
import { ApplicationStatus, InsuranceCover } from "../enums";
import { InsuranceModel } from "../models/insurance";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum InsuranceFormActionType {
  INITIALISE,
  CHANGE_FIELD,
}

export interface InsuranceFormAction {
  type: InsuranceFormActionType;
  initialData?: InsuranceModel;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface InsuranceFormState {
  currentData: InsuranceModel;
  initialData: InsuranceModel;
  hasChanged: boolean;
}

const initialData: InsuranceModel = {
  id: "",
  cover: InsuranceCover.funeral,
  status: ApplicationStatus.created,
  user: {
    uid: "",
    name: "",
    surname: "",
  },
  createdAt: "",
  updatedAt: "",
};

const initialState: InsuranceFormState = {
  initialData,
  currentData: initialData,
  hasChanged: false,
};

const reducer = (state = initialState, action: InsuranceFormAction) => {
  const { type, payload, initialData } = action;
  switch (type) {
    case InsuranceFormActionType.INITIALISE:
      if (initialData) {
        return {
          initialData,
          currentData: initialData,
          hasChanged: false,
        };
      } else {
        throw Error(
          `initial data is invalid for action type: ${type.toString()}`
        );
      }
    case InsuranceFormActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField(
          { ...state.currentData },
          fieldKey,
          fieldValue
        );
        return {
          ...state,
          currentData,
          hasChanged: haveFieldsChanged(state.initialData, currentData),
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

// Returns true if the current data fields are not equal to the initial data fields.
const haveFieldsChanged = (
  initialData: InsuranceModel,
  currentData: InsuranceModel
) => {
  const hasCoverChanged = initialData?.cover !== currentData?.cover;
  return hasCoverChanged;
};

const useInsuranceFormReducer = (): [
  InsuranceFormState,
  React.Dispatch<InsuranceFormAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useInsuranceFormReducer;
