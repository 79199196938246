import { Transition, Dialog } from "@headlessui/react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import React from "react";
import { ApplicationStatus } from "../../../core/enums";
import { formatApplicationStatus } from "../../../core/utils/format";
import ApplicationStatusBadge from "../ApplicationStatusBadge";
import ElevatedButton from "../buttons/ElevatedButton";

export const getDescriptionForApplicationStatus = (
  status: ApplicationStatus
): string => {
  return "The application will be updated to reflect a change in it's status. By updating, you agree that the status change is correct.";
};

export interface ApplicationStatusChangeDialogProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  onChange: (status: ApplicationStatus) => void;
  status: ApplicationStatus;
}

const ApplicationStatusChangeDialog: React.FC<
  ApplicationStatusChangeDialogProps
> = ({ isOpen, onClose, onChange, status }) => {
  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <ArrowPathIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 pb-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Update Application Status
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        You are about to change the status of an application.
                        Please ensure that the status change is correct before
                        you continue. If this is a mistake, please cancel this
                        operation.
                      </p>
                    </div>
                    <div className="mt-4 rounded-md border border-gray-200 p-4">
                      <div className="overflow-hidden rounded-full shadow-md">
                        <ApplicationStatusBadge status={status}>
                          <div className="flex w-full items-center justify-center px-4 py-2">
                            <p>Status: {formatApplicationStatus(status)}</p>
                          </div>
                        </ApplicationStatusBadge>
                      </div>
                      <p className="mt-6 text-sm text-red-500">
                        {getDescriptionForApplicationStatus(status)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 grid grid-cols-1 gap-4 sm:mt-6 md:grid-cols-2">
                  <ElevatedButton
                    isOutline
                    isFullWidth
                    onClick={() => onClose(false)}
                  >
                    Cancel
                  </ElevatedButton>
                  <ElevatedButton
                    isFullWidth
                    onClick={async () => {
                      onClose(false);
                      onChange(status);
                    }}
                  >
                    Update
                  </ElevatedButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ApplicationStatusChangeDialog;
