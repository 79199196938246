import * as React from "react";
import { AffordabilityModel } from "../../core/models/affordability";
import CurrencyField from "../fields/CurrencyField";

const minimumValue = 0;
const stepValue = 10;

export interface CreateAffordabilityFormProps {
  label: string;
  affordability: AffordabilityModel;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onFieldChanged: (fieldKey: string, fieldValue: any) => void;
}

const CreateAffordabilityForm = (props: CreateAffordabilityFormProps) => {
  const { affordability } = props;

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isFormValid = event.currentTarget.checkValidity();
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="space-y-8 divide-y divide-gray-200">
      <div className="mt-2 grid grid-cols-2 gap-6">
        <p className="col-span-2 text-xl font-medium">Monthly Incomes</p>
        <div className="col-span-1">
          <CurrencyField
            label="Primary Income"
            id="create-affordability-primary-income"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.primaryIncome}
            onChange={(v) => props.onFieldChanged("primaryIncome", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Other Income"
            id="create-affordability-other-income"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.otherIncome}
            onChange={(v) => props.onFieldChanged("otherIncome", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Bonus Income"
            id="create-affordability-form-bonus-income"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.bonusIncome}
            onChange={(v) => props.onFieldChanged("bonusIncome", v)}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 pt-6">
        <p className="col-span-2 text-xl font-medium">Monthly Expenses</p>
        <div className="col-span-1">
          <CurrencyField
            label="Accomodation"
            id="create-affordability-form-accomodation"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.accomodation}
            onChange={(v) => props.onFieldChanged("accomodation", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Education"
            id="create-affordability-form-education"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.education}
            onChange={(v) => props.onFieldChanged("education", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Food"
            id="create-affordability-form-food"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.food}
            onChange={(v) => props.onFieldChanged("food", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Transport"
            id="create-affordability-transport"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.transport}
            onChange={(v) => props.onFieldChanged("transport", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Insurance"
            id="create-affordability-form-insurance"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.insurance}
            onChange={(v) => props.onFieldChanged("insurance", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Maintenance"
            id="create-affordability-form-maintenance"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.maintenance}
            onChange={(v) => props.onFieldChanged("maintenance", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Medical"
            id="create-affordability-form-medical"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.medical}
            onChange={(v) => props.onFieldChanged("medical", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Unemployment Insurance Fund (UIF)"
            id="create-affordability-form-uif"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.uif}
            onChange={(v) => props.onFieldChanged("uif", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Loan Repayment"
            id="create-affordability-form-loan-repayment"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.loanRepayment}
            onChange={(v) => props.onFieldChanged("loanRepayment", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Credit Card Repayment"
            id="create-affordability-form-credit-card-repayment"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.creditCardRepayment}
            onChange={(v) => props.onFieldChanged("creditCardRepayment", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Income Tax"
            id="create-affordability-form-income-tax"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.incomeTax}
            onChange={(v) => props.onFieldChanged("incomeTax", v)}
          />
        </div>
        <div className="col-span-1">
          <CurrencyField
            label="Water and Electricity"
            id="create-affordability-water-and-electricity"
            min={minimumValue}
            step={stepValue}
            prefix="R "
            value={affordability.waterAndElectricity}
            onChange={(v) => props.onFieldChanged("waterAndElectricity", v)}
          />
        </div>
      </div>
      <div className="col-span-2 flex gap-8">
        <button
          type={props.type ?? "submit"}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {props.label}
        </button>
      </div>
    </form>
  );
};

export default CreateAffordabilityForm;
