import React from "react";
import { ApplicationStatus } from "../../core/enums";
import { buildStatusLabel } from "../../core/ui/builds";
import ElevatedButton from "./buttons/ElevatedButton";

export interface ApplicationStatusActionsProps {
  availableStatuses: ApplicationStatus[];
  onChangeStatus: (status: ApplicationStatus) => void;
}

const ApplicationStatusActions: React.FC<ApplicationStatusActionsProps> = ({
  availableStatuses,
  onChangeStatus,
}) => {
  return (
    <React.Fragment>
      {availableStatuses.length > 0 && onChangeStatus && (
        <div className="mt-8 rounded-md border border-gray-200 bg-purple-800 p-4">
          <p className="mb-2 text-lg font-medium text-white">
            Application Status
          </p>
          <p className="mb-4 max-w-3xl text-base text-white/75">
            Update the current application's status by tapping one of the
            available application statuses. The history of all status updates
            can always be viewed on the application's timeline
          </p>
          <div className="flex flex-wrap gap-4">
            {availableStatuses.map((status) => {
              return (
                <ElevatedButton
                  onClick={() => onChangeStatus(status)}
                  isOutline
                >
                  {buildStatusLabel(status)}
                </ElevatedButton>
              );
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ApplicationStatusActions;
