import { XCircleIcon } from "@heroicons/react/24/outline";
import { FirebaseError } from "firebase/app";
import { Link, navigate } from "gatsby";
import * as React from "react";
import { Id, toast, TypeOptions } from "react-toastify";
import {
  defaultRelationshipStatus,
  relationshipStatuses,
} from "../../../components/fields/RelationshipStatusField";
import { defaultSex, sexes } from "../../../components/fields/SexField";
import CreateProfileForm from "../../../components/forms/CreateProfileForm";
import { defaultLanguage, languages } from "../../../core/constants";
import {
  useAuthUser,
  useAuthUserDoc,
  useFirebaseService,
} from "../../../core/contexts/firebase";

const EditUserProfilePage = () => {
  const user = useAuthUser();
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [idNumber, setIdNumber] = React.useState("");
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] =
    React.useState("");
  const [selectedLanguage, setSelectedLanguage] =
    React.useState(defaultLanguage);
  const [selectedSex, setSelectedSex] = React.useState(defaultSex);
  const [selectedRelationshipStatus, setSelectedRelationshipStatus] =
    React.useState(defaultRelationshipStatus);

  React.useEffect(() => {
    const userDocLanguage = languages.find(
      (item) => item.value === userDoc?.languageCode
    );
    const userDocSex = sexes.find((item) => item.value === userDoc?.sex);
    const userDocRelationshipStatus = relationshipStatuses.find(
      (item) => item.value === userDoc?.relationshipStatus
    );
    setFirstName(userDoc?.name ?? "");
    setLastName(userDoc?.surname ?? "");
    setPhoneNumber(userDoc?.cell ?? "");
    setIdNumber(userDoc?.idNumber ?? "");
    setSelectedLanguage(userDocLanguage ?? defaultLanguage);
    setSelectedSex(userDocSex ?? defaultSex);
    setSelectedRelationshipStatus(
      userDocRelationshipStatus ?? defaultRelationshipStatus
    );
  }, [userDoc]);

  const hasFormChanged = () => {
    const hasFirstNameChanged = userDoc?.name !== firstName;
    const hasLastNameChanged = userDoc?.surname !== lastName;
    const hasPhoneNumberChanged = userDoc?.cell !== phoneNumber;
    const hasIdNumberChanged = userDoc?.idNumber !== idNumber;
    const hasLanguageChanged = userDoc?.languageCode !== selectedLanguage.value;
    const hasSexChanged = userDoc?.sex !== selectedSex.value;
    const hasRelationshipStatusChanged =
      userDoc?.relationshipStatus !== selectedRelationshipStatus.value;
    return (
      hasFirstNameChanged ||
      hasLastNameChanged ||
      hasPhoneNumberChanged ||
      hasIdNumberChanged ||
      hasLanguageChanged ||
      hasSexChanged ||
      hasRelationshipStatusChanged
    );
  };

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  const onSaveProfile = async (isValid: boolean) => {
    const hasChanged = hasFormChanged();
    if (isValid && !hasChanged) {
      makeToast(
        "Please make at least one change before saving.",
        toast.TYPE.INFO
      );
    }
    if (isValid && hasChanged && user && firebaseService) {
      makeToast("Saving profile changes.", toast.TYPE.INFO, false);
      // Save the user's profile information in their user document
      const { uid, email, photoURL } = user;
      try {
        await firebaseService.updateUserDoc(
          uid,
          Object.assign({
            email,
            name: firstName,
            surname: lastName,
            cell: phoneNumber,
            relationshipStatus: selectedRelationshipStatus.value,
            sex: selectedSex.value,
            idNumber,
            language: selectedLanguage.name,
            languageCode: selectedLanguage.value,
            updatedAt: "",
          })
        );
        makeToast("Profile changes saved successfully.", toast.TYPE.SUCCESS);
        navigate(-1);
      } catch (error) {
        let message =
          "Something went wrong while authenticating your account, please try again later";
        console.log(JSON.stringify(error));
        if (error instanceof FirebaseError) {
          console.log(JSON.stringify(error));
          console.log(error.message);
          console.log(JSON.stringify(error.customData));
          if (error.code === "invalid-argument") {
            message = `Unable to sign up. ${error.message}`;
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="mx-auto mt-16 max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between gap-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900 lg:text-2xl">
            Edit Profile
          </h3>
          <button
            type="button"
            onClick={() => {
              if (hasFormChanged()) {
                // TODO: Ask user if they are sure because there are unsaved changes
                navigate(-1);
              } else {
                navigate(-1);
              }
            }}
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
          >
            <XCircleIcon className="-ml-1 mr-2 h-5 w-5 text-gray-600" />
            Cancel
          </button>
        </div>
        <p className="mt-2 mb-8 text-sm text-gray-500">
          This information will be used to uniquely identify your account so be
          sure to pay extra attention to detail before you save.
        </p>
        <CreateProfileForm
          label="Save"
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          phoneNumberErrorMessage={phoneNumberErrorMessage}
          idNumber={idNumber}
          selectedLanguage={selectedLanguage}
          selectedSex={selectedSex}
          selectedRelationshipStatus={selectedRelationshipStatus}
          onSelectedLanguageChanged={setSelectedLanguage}
          onSelectedSexChanged={setSelectedSex}
          onSelectedRelationshipStatusChanged={setSelectedRelationshipStatus}
          onFirstNameChanged={setFirstName}
          onLastNameChanged={setLastName}
          onPhoneNumberChanged={(value, errorMessage) => {
            setPhoneNumber(value);
            setPhoneNumberErrorMessage(errorMessage);
          }}
          onIdNumberChanged={setIdNumber}
          onButtonClick={onSaveProfile}
        />
      </div>
    </React.Fragment>
  );
};

export default EditUserProfilePage;
