import { FolderOpenIcon } from "@heroicons/react/24/solid";
import React from "react";
import { LoanModel } from "../../core/models/loan";
import EmptyState from "../shared/EmptyState";
import LoadingState from "../shared/LoadingState";
import Spinner from "../shared/Spinner";
import LoanListItem from "./LoanListItem";
import LoadMoreListItem from "./LoadMoreListItem";

export interface PersonalLoanListProps {
  personalLoans: LoanModel[];
  isLoading: boolean;
  hasNext: boolean;
  onLoadMoreClick: () => void;
  emptyStateActions?: React.ReactNode;
  nonEmptyStateActions?: React.ReactNode;
  isAdmin?: boolean;
}

const PersonalLoanList: React.FC<PersonalLoanListProps> = (props) => {
  const { personalLoans, isLoading, hasNext, onLoadMoreClick } = props;
  const personalLoanExist = personalLoans.length > 0;

  return (
    <div className="grid grid-cols-1 gap-4">
      {personalLoanExist && (
        <React.Fragment>
          {props.nonEmptyStateActions && (
            <div className="mb-4">{props.nonEmptyStateActions}</div>
          )}
          {personalLoans.map((personalLoan) => {
            return (
              <div className="rounded-md border-2 transition-colors hover:bg-indigo-100">
                <LoanListItem
                  to={`/dashboard/applications/personal-loans/${personalLoan.id}`}
                  loan={personalLoan}
                />
              </div>
            );
          })}
          {!isLoading && (
            <div className="mt-4">
              <LoadMoreListItem
                hasNext={hasNext}
                onLoadMoreClick={onLoadMoreClick}
                loadMoreLabel="Load Personal Loan Applications"
                noMoreLabel="There are no more personal loan applications to load"
              />
            </div>
          )}
        </React.Fragment>
      )}

      {!personalLoanExist && !isLoading && (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <EmptyState
            title="No Personal Loan Applications"
            description="Once created, you will see personal loan applications show up here."
            icon={(props) => (
              <FolderOpenIcon
                className="mx-auto my-8 h-12 w-12 text-gray-400"
                {...props}
              />
            )}
            actions={props.emptyStateActions}
          />
        </div>
      )}

      {isLoading && (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading Personal Loan Applications"
            description="Loading personal loan applications, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      )}
    </div>
  );
};

export default PersonalLoanList;
