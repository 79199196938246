import * as React from "react";
import { BankingDetailsModel } from "../../core/models/banking-details";
import AccountTypeField from "../fields/AccountTypeField";
import NameField from "../fields/NameField";

export interface CreateBankingDetailsFormProps {
  label: string;
  bankingDetails: BankingDetailsModel;
  type?: "submit" | "button";
  onButtonClick: (isValid: boolean) => void;
  onFieldChanged: (fieldKey: string, fieldValue: any) => void;
}

const CreateBankingDetailsForm = (props: CreateBankingDetailsFormProps) => {
  const { bankingDetails } = props;

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let isFormValid = event.currentTarget.checkValidity();
    props.onButtonClick(isFormValid);
  };

  return (
    <form onSubmit={submitForm} className="space-y-8 divide-y divide-gray-200">
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2 lg:col-span-1">
          <NameField
            name={bankingDetails.name}
            placeholder="Personal"
            onChange={(v) => props.onFieldChanged("name", v)}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <AccountTypeField
            accountType={bankingDetails.accountType}
            onChange={(v) => props.onFieldChanged("accountType", v)}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <NameField
            name={bankingDetails.accountNumber}
            label="Account Number"
            onChange={(v) => props.onFieldChanged("accountNumber", v)}
          />
        </div>
        <div className="col-span-2 lg:col-span-1">
          <NameField
            name={bankingDetails.branchCode}
            label="Branch Code"
            onChange={(v) => props.onFieldChanged("branchCode", v)}
          />
        </div>
      </div>

      <div className="col-span-2 flex gap-8">
        <button
          type={props.type ?? "submit"}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {props.label}
        </button>
      </div>
    </form>
  );
};

export default CreateBankingDetailsForm;
