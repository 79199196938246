import * as React from "react";
import { useLocation } from "@reach/router";
import BusinessLoanDetails from "../../../../components/details/BusinessLoanDetails";
import UploadCard from "../../../../components/shared/cards/UploadCard";
import EmptyState from "../../../../components/shared/EmptyState";
import { FolderOpenIcon } from "@heroicons/react/24/outline";
import useBusinessLoanFormReducer, {
  BusinessLoanFormActionType,
} from "../../../../core/hooks/useBusinessLoanFormReducer";
import BusinessLoanListItem from "../../../../components/lists/BusinessLoanListItem";
import { ApplicationStatus, BusinessLoanType } from "../../../../core/enums";
import { useBusinessLoanById } from "../../../../core/contexts/firebase/business-loans";
import {
  useAuthUserDoc,
  useFirebaseService,
} from "../../../../core/contexts/firebase";
import LoadingState from "../../../../components/shared/LoadingState";
import Spinner from "../../../../components/shared/Spinner";
import { Id, toast, TypeOptions } from "react-toastify";
import { useBusinessLoanStatuses } from "../../../../core/contexts/firebase/statuses/business-loans";
import { FirebaseError } from "firebase/app";
import { StatusModel } from "../../../../core/models/status";
import ApplicationStatusPanel from "../status-panel";
import ApplicationStatusChangeDialog from "../../../../components/shared/dialogs/ApplicationStatusChangeDialog";
import { useAdminMode } from "../../../../core/contexts/ui/admin-mode";
import { buildUserFullName } from "../../../../core/ui/builds";

const UserBusinessLoanDetailsPage = () => {
  const userDoc = useAuthUserDoc();
  const firebaseService = useFirebaseService();
  const [isAdminMode] = useAdminMode();
  const location = useLocation();
  const parts = location.pathname.split("/");
  const [businessLoanDoc, getBusinessLoanById] = useBusinessLoanById();
  const [isLoading, setIsLoading] = React.useState(false);
  const [statuses, sPagination, getNextBusinessLoanStatuses] =
    useBusinessLoanStatuses();
  const [selectedStatus, setSelectedStatus] = React.useState<ApplicationStatus>(
    ApplicationStatus.created
  );
  const [openTimeline, setOpenTimeline] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  // we need to keep a reference of the toastId to be able to update it
  const toastId = React.useRef<Id | null>(null);

  React.useEffect(() => {
    if (!isLoading && userDoc) {
      setIsLoading(true);
      const id = parts[parts.length - 1];
      getBusinessLoanById(id, !isAdminMode)
        .then(() => getNextBusinessLoanStatuses(id, !isAdminMode, true))
        .then(() => setIsLoading(false));
    }
  }, [userDoc, isAdminMode]);

  // Banking details state
  const [businessLoan, businessLoanDispatch] = useBusinessLoanFormReducer();

  // Derived change state
  const isProjectFinance =
    businessLoan.currentData.type === BusinessLoanType.project_finance;
  const isPurchaseOrder =
    businessLoan.currentData.type === BusinessLoanType.purchase_order;
  const isInvoiceDiscounting =
    businessLoan.currentData.type === BusinessLoanType.invoice_discounting;

  // Pre-populate fields if default values from user document exist
  React.useEffect(() => {
    if (businessLoanDoc) {
      businessLoanDispatch({
        type: BusinessLoanFormActionType.INITIALISE,
        initialData: businessLoanDoc,
      });
    }
  }, [businessLoanDoc]);

  const onChangeStatus = async (status: ApplicationStatus) => {
    if (userDoc && firebaseService) {
      makeToast("Updating application's status.", toast.TYPE.INFO, false);
      // Save the user's default information in their user document
      const { uid, email, cell, name, surname } = userDoc;
      const businessLoanUid = businessLoan.currentData.user.uid;
      const id = parts[parts.length - 1];
      try {
        const claimDocUpdate = {
          status,
          updatedAt: "",
        };
        const statusDocUpdate: StatusModel = {
          id: "",
          applicationId: id,
          user: { uid, email, cell, name, surname },
          status,
          createdAt: "",
          updatedAt: "",
        };
        await Promise.all([
          firebaseService.updateBusinessLoanDoc(
            businessLoanUid,
            id,
            Object.assign(claimDocUpdate)
          ),
          firebaseService.setBusinessLoanStatusDoc(
            businessLoanUid,
            id,
            statusDocUpdate
          ),
        ]);
        await getBusinessLoanById(id, !isAdminMode)
          .then(() => getNextBusinessLoanStatuses(id, !isAdminMode, true))
          .then(() => setIsLoading(false));
        makeToast("Status updated successfully.", toast.TYPE.SUCCESS);
      } catch (error) {
        console.log(error);
        let message =
          "Something went wrong while updating application's status, please try again later";
        if (error instanceof FirebaseError) {
          if (error.code === "invalid-argument") {
            message = `Unable to update defaults. ${error.message}`;
          }
        }
        makeToast(message, toast.TYPE.ERROR, false);
        toastId.current = null;
      }
    }
  };

  const makeToast = (
    message: string,
    type?: TypeOptions,
    autoClose?: number | false
  ) => {
    // check if we already displayed a toast
    if (toastId.current === null) {
      toastId.current = toast(message, {
        type,
        autoClose,
      });
    } else {
      toast.update(toastId.current, {
        render: message,
        type,
        autoClose,
      });
    }
  };

  return (
    <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl">
      {isLoading ? (
        <div className="mt-6 rounded-lg border-2 border-dashed border-gray-300 p-4 md:p-6 lg:p-8">
          <LoadingState
            title="Loading Business Loan Application"
            description="Loading business loan application, please wait."
          >
            <Spinner textColor="text-indigo-800" />
          </LoadingState>
        </div>
      ) : (
        <React.Fragment>
          <p className="mt-12 mb-8 text-base text-gray-500">
            View{" "}
            {buildUserFullName(
              !isAdminMode,
              "your",
              businessLoan.currentData.user
            )}{" "}
            loan's status, amount, employer details, and uploaded documents.
          </p>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            {businessLoanDoc && (
              <BusinessLoanListItem
                businessLoan={businessLoanDoc}
                onViewTimeline={() => setOpenTimeline(true)}
                onChangeStatus={
                  isAdminMode
                    ? (status) => {
                        setSelectedStatus(status);
                        setOpenDialog(true);
                      }
                    : undefined
                }
              />
            )}
          </p>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Employer details
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            {businessLoanDoc ? (
              <BusinessLoanDetails businessLoan={businessLoan.currentData} />
            ) : (
              <EmptyState
                title="No Loan Details Found"
                description="Oops! Seems like we could not find any loan details linked to this loan record. Please contact support for help."
                icon={(props) => (
                  <FolderOpenIcon
                    className="mx-auto my-8 h-12 w-12 text-gray-400"
                    {...props}
                  />
                )}
                actions={null}
              />
            )}
          </div>
          <p className="col-span-2 mt-12 text-2xl font-medium">
            Uploaded documents
          </p>
          <div className="mt-6 rounded-md border-2 p-4">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              <UploadCard
                label="Company Registration Document"
                downloadUrl={businessLoanDoc?.download.companyRegDocUrl}
              />
              {isProjectFinance && (
                <UploadCard
                  label="Appointment Letter or SLA"
                  downloadUrl={
                    businessLoanDoc?.download.appointmentLetterOrSlaUrl
                  }
                />
              )}
              {isProjectFinance && (
                <UploadCard
                  label="Bill of Quantities"
                  downloadUrl={businessLoanDoc?.download.billOfQuantitiesUrl}
                />
              )}
              {isProjectFinance && (
                <UploadCard
                  label="Project Implementation Plan"
                  downloadUrl={
                    businessLoanDoc?.download.projectImplementationPlanUrl
                  }
                />
              )}
              {isPurchaseOrder && (
                <UploadCard
                  label="Purchase Order"
                  downloadUrl={businessLoanDoc?.download.purchaseOrderUrl}
                />
              )}
              {isPurchaseOrder && (
                <UploadCard
                  label="Supplier Quote or Invoice"
                  downloadUrl={
                    businessLoanDoc?.download.supplierQuoteOrInvoiceUrl
                  }
                />
              )}
              {isInvoiceDiscounting && (
                <UploadCard
                  label="Submitted Invoice"
                  downloadUrl={businessLoanDoc?.download.invoiceUrl}
                />
              )}
              {isInvoiceDiscounting && (
                <UploadCard
                  label="Appointment Letter or Purchase Order"
                  downloadUrl={
                    businessLoanDoc?.download
                      .appointmentLetterOrPurchaseOrderUrl
                  }
                />
              )}
              {isInvoiceDiscounting && (
                <UploadCard
                  label="Payment Certificate"
                  downloadUrl={businessLoanDoc?.download.paymentCertificateUrl}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      )}
      <ApplicationStatusPanel
        statuses={statuses}
        isOpen={openTimeline}
        onClose={setOpenTimeline}
      />
      <ApplicationStatusChangeDialog
        status={selectedStatus}
        isOpen={openDialog}
        onClose={setOpenDialog}
        onChange={onChangeStatus}
      />
    </div>
  );
};

export default UserBusinessLoanDetailsPage;
