import React, { Reducer } from "react";
import { ApplicationStatus, BusinessLoanType } from "../enums";
import { BusinessLoanModel } from "../models/business-loan";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum BusinessLoanFormActionType {
  INITIALISE,
  CHANGE_FIELD,
}

export interface BusinessLoanFormAction {
  type: BusinessLoanFormActionType;
  initialData?: BusinessLoanModel;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface BusinessLoanFormState {
  currentData: BusinessLoanModel;
  initialData: BusinessLoanModel;
  hasChanged: boolean;
}

const initialData: BusinessLoanModel = {
  id: "",
  amount: "50000.00",
  user: {
    uid: "",
    name: "",
    surname: "",
  },
  type: BusinessLoanType.project_finance,
  status: ApplicationStatus.created,
  companyName: "",
  companyRegNo: "",
  cell: "",
  email: "",
  clientApplicantName: "",
  projectLocation: "",
  download: {
    companyRegDocUrl: "",
    appointmentLetterOrSlaUrl: "",
    appointmentLetterOrPurchaseOrderUrl: "",
    billOfQuantitiesUrl: "",
    projectImplementationPlanUrl: "",
    purchaseOrderUrl: "",
    supplierQuoteOrInvoiceUrl: "",
    invoiceUrl: "",
    paymentCertificateUrl: "",
  },
  createdAt: "",
  updatedAt: "",
};

const initialState: BusinessLoanFormState = {
  initialData,
  currentData: initialData,
  hasChanged: false,
};

const reducer = (state = initialState, action: BusinessLoanFormAction) => {
  const { type, payload, initialData } = action;
  switch (type) {
    case BusinessLoanFormActionType.INITIALISE:
      if (initialData) {
        return {
          initialData,
          currentData: initialData,
          hasChanged: false,
        };
      } else {
        throw Error(
          `initial data is invalid for action type: ${type.toString()}`
        );
      }
    case BusinessLoanFormActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField(
          { ...state.currentData },
          fieldKey,
          fieldValue
        );
        return {
          ...state,
          currentData,
          hasChanged: haveFieldsChanged(state.initialData, currentData),
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

// Returns true if the current data fields are not equal to the initial data fields.
const haveFieldsChanged = (
  initialData: BusinessLoanModel,
  currentData: BusinessLoanModel
) => {
  const hasTypeChanged = initialData?.type !== currentData?.type;
  return hasTypeChanged;
};

const useBusinessLoanFormReducer = (): [
  BusinessLoanFormState,
  React.Dispatch<BusinessLoanFormAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useBusinessLoanFormReducer;
