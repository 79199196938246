import React from "react";
import { AddressModel } from "../../core/models/address";

export interface AddressDetailsProps {
  address: AddressModel;
}

const AddressDetails: React.FC<AddressDetailsProps> = ({ address }) => {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <p className="col-span-1 text-xl font-medium sm:col-span-2">
        Physical address
      </p>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Line 1</dt>
        <dd className="mt-1 text-sm text-gray-900">{address.line1}</dd>
      </div>
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Line 2</dt>
        <dd className="mt-1 text-sm text-gray-900">{address.line2}</dd>
      </div>
      {address.line3 && (
        <div className="col-span-1">
          <dt className="text-sm font-medium text-gray-500">Line 3</dt>
          <dd className="mt-1 text-sm text-gray-900">{address.line3}</dd>
        </div>
      )}
      <div className="col-span-1 lg:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Postal Code</dt>
        <dd className="mt-1 text-sm text-gray-900">{address.postalCode}</dd>
      </div>
    </dl>
  );
};

export default AddressDetails;
