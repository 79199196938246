import React from "react";
import { navigate, useLocation } from "@reach/router";
import {
  useAuthIsAuthenticating,
  useAuthIsConnecting,
  useAuthUser,
  useAuthUserDoc,
  useAuthUserIsAdmin,
  useFirebaseService,
} from "../../core/contexts/firebase";
import Wall from "../../client/dashboard/admin/wall";
import { useAdminMode } from "../../core/contexts/ui/admin-mode";
import LinkButton from "../shared/buttons/LinkButton";
import AdminWall from "../shared/walls/AdminWall";
import { MultiFactorInfo } from "firebase/auth";
import { useRedirectUrl } from "../../core/hooks/useRedirectUrl";
import { ApplicationType } from "../../core/enums";
import { useSettings } from "../../core/contexts/firebase/settings";
import { SettingModel } from "../../core/models/setting";
import appConfig from "../../core/utils/config";

export type ProtectedRouteProps = {
  path: string;
  outlet: JSX.Element;
  adminOnly?: boolean;
  verifiedOnly?: boolean;
  paidOnly?: boolean;
  applicationType?: ApplicationType;
};

export default function ProtectedRoute({
  outlet,
  adminOnly = false,
  verifiedOnly = false,
  paidOnly = false,
  applicationType,
}: ProtectedRouteProps) {
  const location = useLocation();
  const user = useAuthUser();
  const userDoc = useAuthUserDoc();
  const [redirectUrl, redirectSearch] = useRedirectUrl();
  const firebaseService = useFirebaseService();
  const isAuthenticating = useAuthIsAuthenticating();
  const isConnecting = useAuthIsConnecting();
  const [settings, isLoaded, isLoading] = useSettings();
  const isAdmin = useAuthUserIsAdmin();
  const [isAdminMode, setIsAdminMode] = useAdminMode();
  const [enrolledFactors, setEnrolledFactors] = React.useState<
    MultiFactorInfo[] | null
  >(null);
  const { enableTwoFactorAuth } = appConfig;
  const mfaEnabled = enrolledFactors && enrolledFactors.length > 0;

  React.useEffect(() => {
    if (
      userDoc &&
      firebaseService &&
      enableTwoFactorAuth &&
      enrolledFactors == null
    ) {
      firebaseService
        .doGetMultiFactorEnrolledFactors()
        .then((enrolledFactors) => setEnrolledFactors([...enrolledFactors]));
    }
  }, [userDoc, firebaseService, enrolledFactors]);

  const goToSignIn = () => {
    if (typeof window !== "undefined") {
      window.location.replace("/auth/signin");
    } else {
      navigate("../auth/signin", { replace: true });
    }
  };

  const isApplicationTypeDisabled = (settings: SettingModel): boolean => {
    const { pathname } = location;
    const { personalLoans, businessLoans, claims, insurances } =
      settings.applications;
    if (pathname.includes("personal-loan")) {
      return (
        applicationType == ApplicationType.personalLoans &&
        personalLoans.disabled
      );
    } else if (pathname.includes("business-loan")) {
      return (
        applicationType == ApplicationType.businessLoans &&
        businessLoans.disabled
      );
    } else if (pathname.includes("claim")) {
      return applicationType == ApplicationType.claims && claims.disabled;
    } else if (pathname.includes("insurance")) {
      return (
        applicationType == ApplicationType.insurances && insurances.disabled
      );
    } else {
      return applicationType != null;
    }
  };

  if (
    isAuthenticating ||
    isConnecting ||
    (enableTwoFactorAuth && enrolledFactors == null) ||
    isLoading
  )
    return null;

  if (userDoc && user) {
    if (paidOnly && appConfig.paymentRequired) {
      return (
        <Wall
          code="402"
          message="System Offline."
          description="If the problem persists, please contact site owner."
        >
          <LinkButton to="/dashboard" isOutline>
            My Home
          </LinkButton>
        </Wall>
      );
    } else if (isApplicationTypeDisabled(settings)) {
      return (
        <Wall
          code="403"
          message="Currently Not Available."
          description={settings.applications[applicationType!].disabledMessage}
        >
          <LinkButton to="/dashboard" isOutline>
            My Home
          </LinkButton>
        </Wall>
      );
    } else if (adminOnly) {
      // For admin only pages return an admin wall if the user is not an admin.
      if (isAdmin && isAdminMode) return outlet;
      if (!isAdminMode) return <AdminWall />;

      return (
        <Wall
          code="403"
          message="Forbidden."
          description="Sorry, you do not have the necessary privileges to access the content of this page. Please try again with your administrator account or contact an administrator."
        >
          <LinkButton to="/dashboard" isOutline>
            My Home
          </LinkButton>
        </Wall>
      );
    } else if (verifiedOnly) {
      // For verified only pages return a verification wall if the user is not an admin.
      if (!user.emailVerified) {
        let url = `/auth/verify?type=init_email&email=${user.email}`;
        if (redirectUrl) {
          url = `${url}&redirectUrl=${redirectUrl}`;
          if (redirectSearch) {
            // remove the initial question mark from the search string
            url = `${url}&redirectSearch${redirectSearch}`;
          }
        }

        return (
          <Wall
            code="401"
            message="Unauthorised."
            description="Sorry, you are not authorised to access the content of this page. Please verify your account first and then try again."
          >
            <div className="mx-auto mt-12 grid max-w-3xl grid-cols-1 gap-4 md:grid-cols-2">
              <LinkButton to="/dashboard" isFullWidth isOutline>
                My Home
              </LinkButton>
              <LinkButton to={url} isFullWidth>
                Verify Email
              </LinkButton>
            </div>
          </Wall>
        );
      } else if (enableTwoFactorAuth && !mfaEnabled) {
        let url = `/dashboard/mfa?type=phone_number&phoneNumber=${userDoc.cell}`;
        if (redirectUrl) {
          url = `${url}&redirectUrl=${redirectUrl}`;
          if (redirectSearch) {
            // remove the initial question mark from the search string
            url = `${url}&redirectSearch${redirectSearch}`;
          }
        }

        return (
          <Wall
            code="401"
            message="Unauthorised."
            description="Sorry, you are not authorised to access the content of this page. Please enable multi-factor authentiction (MFA) on your account first and then try again."
          >
            <div className="mx-auto mt-12 grid max-w-3xl grid-cols-1 gap-4 md:grid-cols-2">
              <LinkButton to="/dashboard" isFullWidth isOutline>
                My Home
              </LinkButton>
              <LinkButton to={url} isFullWidth>
                Enable MFA
              </LinkButton>
            </div>
          </Wall>
        );
      } else {
        return outlet;
      }
    } else {
      return outlet;
    }
  } else {
    goToSignIn();
    return null;
  }
}
