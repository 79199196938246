import React, { Reducer } from "react";
import { updateField } from "../utils/fields";

// The type of actions that can be dispatched on the reducer.
export enum InsuranceSubmissionActionType {
  CHANGE_FIELD,
}

export interface InsuranceSubmissionAction {
  type: InsuranceSubmissionActionType;
  payload?: {
    fieldKey: string;
    fieldValue: any;
  };
}

export interface InsuranceSubmissionState {
  isSubmitting: boolean;
  isSubmitted: boolean;
}

const initialState: InsuranceSubmissionState = {
  isSubmitting: false,
  isSubmitted: false,
};

const reducer = (state = initialState, action: InsuranceSubmissionAction) => {
  const { type, payload } = action;
  switch (type) {
    case InsuranceSubmissionActionType.CHANGE_FIELD:
      if (payload) {
        const { fieldKey, fieldValue } = payload;
        const currentData = updateField({ ...state }, fieldKey, fieldValue);
        return {
          ...state,
          currentData,
        };
      } else {
        throw Error(`payload is invalid for action type: ${type.toString()}`);
      }
    default:
      return state;
  }
};

const useInsuranceSubmissionReducer = (): [
  InsuranceSubmissionState,
  React.Dispatch<InsuranceSubmissionAction>
] => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return [state, dispatch];
};

export default useInsuranceSubmissionReducer;
