import React from "react";

export interface LoadingStateProps {
  title: string;
  description: string;
  children?: React.ReactNode;
}

const LoadingState: React.FC<LoadingStateProps> = (props) => {
  const { title, description, children } = props;

  return (
    <div className="text-center">
      <h3 className="mt-2 text-lg font-medium text-gray-900">{title}</h3>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
      {children && <div className="mt-6">{children}</div>}
    </div>
  );
};

export default LoadingState;
