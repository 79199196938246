import React from "react";
import { formatAmount } from "../../core/utils/format";

export interface LoanDetailsProps {
  amount: string;
}

const LoanDetails: React.FC<LoanDetailsProps> = ({ amount }) => {
  return (
    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
      <div className="col-span-1">
        <dt className="text-sm font-medium text-gray-500">Loan amount</dt>
        <dd className="mt-1 text-sm text-gray-900">{formatAmount(amount)}</dd>
      </div>
    </dl>
  );
};

export default LoanDetails;
