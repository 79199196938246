import * as React from "react";
import type { HeadFC } from "gatsby";
import { CustomHead } from "../../../components/shell/CustomHead";

const pageDescription =
  "Sign in to access all our features meant to make your financing experience that much better.";

const AdminIndexPage = () => {
  return (
    <main>
      <h1>
        Admin Dashboard Page
        <br />
      </h1>
    </main>
  );
};

export default AdminIndexPage;

export const Head: HeadFC = () => <CustomHead title="Dashboard" />;
